import React from "react";
import Image from "../Global/Image";
import BenefitCard from "./BenefitCard";
import FadeComponent from "../Global/FadeComponent";

function Benefits({ ...props }) {
  const { data, isPhone } = props;
  return (
    <FadeComponent isPhone={isPhone}>
      <div className="sf-row sf-row-xs--center">
        <div className="benefits sf-container">
          <div className="benefits__wheel">
            <Image
              desktopSrc="./benefits-360_wheel.png"
              mobileSrc="./benefits-360_wheel-mobile.png"
              alt=""
            />
          </div>
          <div className="benefits__fly benefits__fly--first">
            <Image
              desktopSrc="./Butterfly1.png"
              mobileSrc="./Butterfly1.png"
              alt=""
            />
          </div>
          <div className="sf-row">
            {data.map((benefit, i) => (
              <BenefitCard
                key={benefit[0]}
                title={benefit[0]}
                desc={benefit[1]}
                num={i}
                len={data.length}
              />
            ))}
          </div>
        </div>
        <div className="benefits__bg sf-col-xs-12 sf-col-md-8">
          <Image
            desktopSrc="./rec-benefit-bg.png"
            mobileSrc="./rec-benefit-bg.png"
            alt=""
          />
        </div>
      </div>
    </FadeComponent>
  );
}

export default Benefits;
