import React, { useState, useRef, useEffect } from "react";
import { AnswerContext } from "../../context/answer-context";
import { CLASSES, TIMING } from "../../utils/constants";
import NextButton from "./NextButton";
import BackButton from "./BackButton";
import Image from "./Image";
import { useOnScreen } from "use-hooks";

function Tracker({ ...props }) {
  const {
    strings,
    incrementStepValue,
    decrementStepValue,
    onScreenThreshold = 50,
    params,
  } = props;

  const [isActive, setIsActive] = useState(false);
  const ref = useRef();
  const onScreen = useOnScreen(ref, `-${onScreenThreshold}%`);

  useEffect(() => {
    setIsActive(onScreen);
  }, [onScreen]);

  const isNextActive = (state) => {
    const { stepNum, roleType, industryType, businessType, priorityChoice } =
      state;
    switch (stepNum) {
      case 1:
        return industryType ? true : false;
      case 2:
        return roleType ? true : false;
      case 3:
        return businessType ? true : false;
      case 4:
        return priorityChoice ? true : false;
      default:
        return false;
    }
  };

  const getCurValue = (state) => {
    const { stepNum, roleType, industryType, businessType, priorityChoice } =
      state;
    switch (stepNum) {
      case 1:
        return industryType;
      case 2:
        return roleType;
      case 3:
        return businessType;
      case 4:
        return priorityChoice;
      default:
        return false;
    }
  };

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div
          className={`tracker ${state.stepNum > 0 ? "--fixed" : ""}`}
          ref={ref}
        >
          <div className="tracker__inner">
            <Image
              desktopSrc="./curve.png"
              mobileSrc="./curve.png"
              classes="tracker__curve"
            />
            <div className="tracker__wrap sf-row sf-row-xs--middle sf-row-xs--between">
              <BackButton
                isActive={isNextActive(state)}
                curValue={getCurValue(state)}
                step={state.stepNum}
                back={decrementStepValue}
              />
              <div className="tracker__content sf-col-xs--hide sf-col-lg--show-block">
                <span className="tracker__support-copy -active">
                  {strings.solutions}
                </span>
                <span
                  className={`sf-row sf-row-xs--middle sf-row-xs--center tracker__user-input ${
                    state.industryType || isActive ? CLASSES.ACTIVE : ""
                  }`}
                  style={{
                    animationDelay: `${
                      TIMING.TIMING_250 + TIMING.TIMING_50 * 2
                    }ms`,
                  }}
                >
                  <span className="tracker__user-input-copy">
                    {(state.industryType && state.industryType.title) ||
                      (params && params.industryParam.title) ||
                      strings.defaultTypes.industry}
                  </span>
                </span>
                <span>
                  {state.industryType && strings.defaultTypes.industryActive}
                </span>
                {state.stepNum >= 2 && state.skippedQuestion !== 2 ? (
                  <>
                    <span
                      className={`tracker__support-copy ${
                        state.roleType || isActive ? CLASSES.ACTIVE : ""
                      }`}
                    >
                      {strings.industry}
                    </span>
                    <span
                      style={{
                        animationDelay: `${
                          TIMING.TIMING_250 + TIMING.TIMING_50
                        }ms`,
                      }}
                      className={`sf-row sf-row-xs--middle sf-row-xs--center tracker__user-input ${
                        state.roleType || isActive ? CLASSES.ACTIVE : ""
                      }`}
                    >
                      <span className="tracker__user-input-copy">
                        {(state.roleType && state.roleType.title) ||
                          (params && params.roleParam.title) ||
                          strings.defaultTypes.role}
                      </span>
                    </span>
                  </>
                ) : null}
                <span>{state.roleType && strings.defaultTypes.roleActive}</span>
                {state.stepNum >= 3 && state.skippedQuestion !== 3 ? (
                  <>
                    <span
                      className={`tracker__support-copy ${
                        state.businessType || isActive ? CLASSES.ACTIVE : ""
                      }`}
                    >
                      {strings.role}
                    </span>
                    <span
                      className={`sf-row sf-row-xs--middle sf-row-xs--center tracker__user-input ${
                        state.businessType || isActive ? CLASSES.ACTIVE : ""
                      }`}
                      style={{
                        animationDelay: `${
                          TIMING.TIMING_250 + TIMING.TIMING_50 * 3
                        }ms`,
                      }}
                    >
                      <span className="tracker__user-input-copy">
                        {(state.businessType &&
                          state.businessType.titlePlural) ||
                          (params && params.businessParam.titlePlural) ||
                          strings.defaultTypes.businessType}
                      </span>
                    </span>
                  </>
                ) : null}
                {state.stepNum >= 4 ? (
                  <>
                    <span
                      className={`tracker__support-copy ${
                        state.priorityChoice || isActive ? CLASSES.ACTIVE : ""
                      }`}
                    >
                      {strings.priority}
                    </span>
                    <span
                      className={`sf-row sf-row-xs--middle sf-row-xs--center tracker__user-input ${
                        state.priorityChoice || isActive ? CLASSES.ACTIVE : ""
                      }`}
                      style={{
                        animationDelay: `${
                          TIMING.TIMING_250 + TIMING.TIMING_50 * 4
                        }ms`,
                      }}
                    >
                      <span className="tracker__user-input-copy">
                        {(state.priorityChoice && state.priorityChoice.title) ||
                          (params && params.recommended.priority) ||
                          strings.defaultTypes.priority}
                      </span>
                    </span>
                  </>
                ) : null}
              </div>
              <NextButton
                next={incrementStepValue}
                isActive={isNextActive(state)}
                curValue={getCurValue(state)}
              />
            </div>
          </div>
        </div>
      )}
    </AnswerContext.Consumer>
  );
}

export default Tracker;
