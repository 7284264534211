import React, { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { URL_PARAMETERS } from "../../utils/constants";
import { GlobalContext } from "../../context/answer-context";
const RedirectDeprecatedRoute = () => {
  const { searchParams, searchParamsToString } = useContext(GlobalContext);
  const { rol, ind, cat, biz, prio, ref } = useParams();
  const isValid = URL_PARAMETERS.REF.values.includes(ref);
  searchParams.append(URL_PARAMETERS.REF.key, ref);
  return isValid ? (
    <Navigate
      to={`/result/${rol}/${ind}/${cat}/${biz}/${prio}/${searchParamsToString()}`}
      replace={true}
    />
  ) : (
    <Navigate to="/404" replace={true} />
  );
};

export default RedirectDeprecatedRoute;
