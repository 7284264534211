import { randomizeNumber } from "./functions";
const { PI, sin, cos } = Math;
const RANDOM_THETA = randomizeNumber(PI, PI * 2);

/** Single Particle class to be used within Particle System **/
class Particle {
  constructor(position, velocity, acceleration, canvasW, canvasH, itemSize) {
    this.position = position;
    this.velocity = velocity;
    this.acceleration = acceleration;
    this.theta = RANDOM_THETA;
    this.canvasWidth = canvasW;
    this.canvasHeight = canvasH;
    this.age = 0;
    this.isDead = false;
    this.size = itemSize;
    const maxAge = randomizeNumber(1, 3.5);
    this.maxAge = maxAge();
  }

  /**
   * @desc Set particles to dead if their age > maxAge
   */
  kill() {
    this.isDead = true;
  }

  /**
   * @desc Run every frame.  Update all attributes of particle
   * @param {number} delta Difference in time between frames
   */
  tick(delta) {
    if (this.age > this.maxAge) {
      this.kill();
    }

    this.age = this.age + delta;
    this.position.x = this.position.x + this.velocity.x * delta;
    this.position.y = this.position.y - this.velocity.y * delta;
    /* eslint-disable */
    this.velocity.x + sin(this.theta) * 0.4;
    this.velocity.y + cos(this.theta) * 0.8;
    /* eslint-enable */
  }
}

export default Particle;
