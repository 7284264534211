import React from "react";

const PrivacyStatement = () => {
  return (
    <div className="agreement__box">
      <div class="headingComponent parbase section">
        <h3
          id="salesforce-privacy-statement-effective-as-of-september-20-2021"
          class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
        >
          <span class="            header-text                                    ">
            Salesforce Privacy Statement, effective as of September 20, 2021
          </span>
        </h3>
      </div>
      <div class="columnContainer parbase section">
        <div
          class="     columns-wrapper  container-fluid   column-container-component"
          data-target-init-function="columnContainer.targetInit"
        >
          <div class="bg-default  bg-opacity">&nbsp;</div>
          <div class="row columns-wrapper            ">
            <div class="col  text-left col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    At Salesforce, trust is our #1 value. This Salesforce
                    Privacy Statement (&ldquo;Privacy Statement") explains our
                    privacy practices for the activities described herein.&nbsp;
                    Please read this Privacy Statement carefully to learn how we
                    collect, use, share, and otherwise process information
                    relating to individuals (&ldquo;Personal Data&rdquo;), and
                    to learn about your rights and choices regarding your
                    Personal Data.
                  </p>
                  <p>
                    A reference to &ldquo;Salesforce,&rdquo; &ldquo;we,&rdquo;
                    &ldquo;us&rdquo; or the &ldquo;Company&rdquo; is a reference
                    to Salesforce.com, Inc. and its relevant affiliate involved
                    in the collection, use, sharing, or other processing of
                    Personal Data. A list of Salesforce&rsquo;s affiliates can
                    be found in the List of Subsidiaries section of
                    Salesforce&rsquo;s most recent Form 10-K, available under
                    the SEC Filings tab by selecting the &ldquo;Annual
                    Filings&rdquo; filter on the page located&nbsp;here.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">&nbsp;</div>
        </div>
      </div>
      <div class="headingComponent parbase section">
        <h3
          id="info_collected"
          class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
        >
          <span class="            header-text                                    ">
            1. Responsible Salesforce entity
          </span>
        </h3>
      </div>
      <div class="columnContainer parbase section">
        <div
          class="     columns-wrapper  container-fluid   column-container-component"
          data-target-init-function="columnContainer.targetInit"
        >
          <div class="bg-default  bg-opacity">&nbsp;</div>
          <div class="row columns-wrapper            ">
            <div class="col  text-left col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    Salesforce is the controller of your Personal Data as
                    described in this Privacy Statement, unless&nbsp; specified
                    otherwise.
                  </p>
                  <p>
                    This Privacy Statement does not apply to the extent we
                    process Personal Data in the role of a processor or service
                    provider on behalf of our customers, including where we
                    offer to our customers various products and services through
                    which our customers (or their affiliates): (i) create their
                    own websites and applications running on our platforms; (ii)
                    sell or offer their own products and services; (iii) send
                    electronic communications to others; or (iv) otherwise
                    collect, use, share or process Personal Data via our
                    products and services.
                  </p>
                  <p>
                    For detailed privacy information related to a Salesforce
                    customer or a customer affiliate who uses Salesforce
                    products and services as the controller, please contact our
                    customer directly. We are not responsible for the privacy or
                    data security practices of our customers, which may differ
                    from those explained in this Privacy Statement. For more
                    information, please also see Section 10.3 below.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">&nbsp;</div>
        </div>
      </div>
      <div class="headingComponent parbase section">
        <h3
          id="2-processing-activities-covered"
          class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
        >
          <span class="            header-text                                    ">
            2. Processing activities covered
          </span>
        </h3>
      </div>
      <div class="columnContainer parbase section">
        <div
          class="     columns-wrapper  container-fluid   column-container-component"
          data-target-init-function="columnContainer.targetInit"
        >
          <div class="bg-default  bg-opacity">&nbsp;</div>
          <div class="row columns-wrapper            ">
            <div class="col  text-left col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    This Privacy Statement applies to the processing of Personal
                    Data collected by us when you:
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      Visit our websites that display or link to this Privacy
                      Statement;
                    </li>
                    <li>Visit our branded social media pages;</li>
                    <li>Visit our offices;</li>
                    <li>
                      Receive communications from us or otherwise communicate
                      with us, including but not limited to emails, phone calls,
                      texts or faxes;
                    </li>
                    <li>
                      Use our products and services as an authorized user (for
                      example, as an employee of one of our customers who
                      provided you with access to our services) where we act as
                      a controller of your Personal Data;
                    </li>
                    <li>
                      Register for, attend or take part in our events, webinars,
                      programs or contests;
                    </li>
                    <li>
                      Act as or work for a service provider or supplier to
                      Salesforce, to the extent Salesforce acts as a controller
                      with respect to your Personal Data;&nbsp;
                    </li>
                    <li>
                      Participate in community and open source development; or
                    </li>
                    <li>
                      Participate in surveys, research or other similar data
                      collection facilitated by us.
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    We also host AppExchange, which is an online marketplace for
                    on-demand web applications that run on the Salesforce
                    platform and that may be provided by us or by third parties
                    (see&nbsp;https://appexchange.salesforce.com/) as well as
                    Tableau&rsquo;s Extension Gallery
                    (https://extensiongallery.tableau.com) which offers
                    connectors and solutions that provide additional
                    functionality for Tableau, such as dashboard extensions.
                    When applications, connectors, extensions or other solutions
                    are provided by us and they link to this Privacy Statement,
                    this Privacy Statement applies. When these offerings are
                    provided by third parties, the privacy statement of the
                    third party applies and this Privacy Statement does
                    not.&nbsp;
                  </p>
                  <p>
                    Our websites and services may contain links to other
                    websites, applications, platforms and services maintained by
                    third parties. The information practices of these third
                    parties, including the social media platforms that host our
                    branded social media pages, are governed by their privacy
                    statements, which you should review to better understand
                    their privacy practices.
                  </p>
                  <p>
                    Please note Processing of Personal Data is required for
                    receiving certain products or services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">&nbsp;</div>
        </div>
      </div>
      <div class="headingComponent parbase section">
        <h3
          id="use_info"
          class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
        >
          <span class="            header-text                                    ">
            3. What Personal Data do we collect?
          </span>
        </h3>
      </div>
      <div class="columnContainer parbase section">
        <div
          class="     columns-wrapper  container-fluid   column-container-component"
          data-target-init-function="columnContainer.targetInit"
        >
          <div class="bg-default  bg-opacity">&nbsp;</div>
          <div class="row columns-wrapper            ">
            <div class="col  text-left col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>3.1 Personal Data we collect directly from you</span>
                  </p>
                  <p>
                    The Personal Data we collect directly from you includes
                    identifiers, professional or employment-related information,
                    financial account information, commercial information,
                    visual information, and internet activity information, among
                    others. We collect such information in the following
                    situations:
                  </p>
                  <ul>
                    <li>
                      If you express an interest in obtaining additional
                      information about our services; request customer support
                      (including accessing the Help &amp; Training Portal); use
                      our &ldquo;Contact Us&rdquo; or similar features; register
                      to use our websites or to receive communications; sign up
                      for an event, webinar or contest; participate in a program
                      or survey; act as an authorized user for our products and
                      services; or download certain content, we may require that
                      you provide to us your contact information, such as your
                      name, job title, company name, address, phone number,
                      email address or username and password;
                    </li>
                    <li>
                      If you make purchases via our websites or register for an
                      event or webinar, we may require that you provide to us
                      your financial and billing information, such as billing
                      name and address, credit card number or bank account
                      information;
                    </li>
                    <li>
                      If you attend an event, we may, with your further consent,
                      scan your attendee badge, which will provide to us your
                      information, such as name, title, company name, address,
                      country, phone number and email address;
                    </li>
                    <li>
                      If you register with us for a variety of purposes,
                      including joining an online community that we host or
                      participating in a program, we may ask you to provide a
                      username, photo or other biographical information, such as
                      your occupation, location, social media profiles or
                      usernames, company name, areas of expertise and interests;
                    </li>
                    <li>
                      If you interact with our websites or emails, we
                      automatically collect information about your device and
                      your usage of our websites or emails (such as Internet
                      Protocol (IP) addresses or other identifiers), which may
                      qualify as Personal Data (please see Section 4 below)
                      using cookies, web beacons, or similar technologies;
                    </li>
                    <li>
                      If you use and interact with our products and services, we
                      collect information about your device and your usage of
                      our services through log files and other technologies,
                      some of which may qualify as Personal Data (please see
                      Section 4 below);
                    </li>
                    <li>
                      If you communicate with us via a phone call, we may record
                      that call in accordance with applicable laws;
                    </li>
                    <li>
                      If you attend an event or visit our offices, we may
                      collect your image or video;&nbsp;
                    </li>
                    <li>
                      If you voluntarily submit certain information to us, such
                      as filling out a survey, responding to a questionnaire or
                      participating in other forms of research, we may collect
                      the information you have provided as part of that request,
                      which may include special categories of Personal Data, to
                      the extent you voluntarily choose to provide it;&nbsp;
                    </li>
                    <li>
                      If you visit our offices, you may be required to register
                      as a visitor and to provide your name, email address,
                      phone number, company name and time and date of
                      arrival;&nbsp;
                    </li>
                    <li>
                      If you visit our offices or attend an event, due to the
                      COVID-19 pandemic, you may be required to provide
                      information regarding your health status, including your
                      temperature, COVID-19-related symptoms, exposure to
                      COVID-19 positive individuals, vaccination status, testing
                      results, and recent travel history; and
                    </li>
                    <li>
                      If you are a supplier or service provider to Salesforce
                      (or work for a supplier or service provider), you may also
                      be required to provide us with Personal Data such as your
                      contact details and payment and billing information.&nbsp;
                    </li>
                  </ul>
                  <p>
                    If you provide us, our service providers or our affiliates
                    with any Personal Data relating to other individuals, you
                    represent that you have the authority to do so, and where
                    required, have obtained the necessary consent, and
                    acknowledge that it may be used in accordance with this
                    Privacy Statement. If you believe that your Personal Data
                    has been provided to us improperly, or want to exercise your
                    rights relating to your Personal Data, please contact us by
                    using the information in Section 13 below.
                  </p>
                  <p>
                    <span>3.2 Personal Data we collect from other sources</span>
                  </p>
                  <p>
                    We also collect information about you from other sources
                    including third parties from whom we purchase Personal Data
                    and from publicly available information.&nbsp; We may
                    combine this information with Personal Data provided by you.
                    This helps us update, expand, and analyze our records,
                    identify new customers, and create more tailored advertising
                    to provide services that may be of interest to you. The
                    Personal Data we collect from other sources includes
                    identifiers, professional or employment-related information,
                    education information, commercial information, visual
                    information, internet activity information, and inferences
                    about preferences and behaviors. In particular, we collect
                    such Personal Data from the following sources:
                  </p>
                  <ul>
                    <li>
                      Third party providers of business contact information,
                      including mailing addresses, job titles, email addresses,
                      phone numbers, intent data (or user behavior data), IP
                      addresses, social media profiles, LinkedIn URLs and custom
                      profiles, for purposes of targeted advertising, delivering
                      relevant email content, event promotion and profiling,
                      determining eligibility and verifying contact information;
                    </li>
                    <li>
                      Another individual at your organization who may provide us
                      with your personal information, which may include Personal
                      Data and special categories of Personal Data, to the
                      extent you consent to providing it and sharing it, for the
                      purposes of obtaining services and assessing our goals
                      related to encouraging diversity within our vendors;
                      and&nbsp;
                    </li>
                    <li>
                      Platforms such as GitHub to manage code check-ins and pull
                      requests. If you participate in an open source or
                      community development project, we may associate your code
                      repository username with your community account so we can
                      inform you of program changes that are important to your
                      participation or relate to additional security
                      requirements.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">&nbsp;</div>
        </div>
      </div>
      <div class="headingComponent parbase section">
        <h3
          id="nav_info"
          class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
        >
          <span class="            header-text                                    ">
            4. What device and usage data do we process?
          </span>
        </h3>
      </div>
      <div class="columnContainer parbase section">
        <div
          class="     columns-wrapper  container-fluid   column-container-component"
          data-target-init-function="columnContainer.targetInit"
        >
          <div class="bg-default  bg-opacity">&nbsp;</div>
          <div class="row columns-wrapper            ">
            <div class="col  text-left col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="bodyCopyComponent parbase section">
                <div class="margin-20-bottom-lg  text-size-default line-height-default        ">
                  <p>
                    We use common information-gathering tools, such as tools for
                    collecting usage data, cookies, web beacons, pixels, and
                    similar technologies to automatically collect information
                    that may contain Personal Data as you navigate our websites,
                    our services, or interact with emails we have sent to you.
                  </p>
                  <p>
                    <span>4.1 Device and usage data</span>
                  </p>
                  <p>
                    As is true of most websites, we gather certain information
                    automatically when individual users visit our websites. This
                    information may include identifiers, commercial information,
                    and internet activity information such as IP address (or
                    proxy server information), device and application
                    information, identification numbers and features, location,
                    browser type, plug-ins, integrations, Internet service
                    provider, mobile carrier, the pages and files viewed,
                    searches, referring website, app or ad, operating system,
                    system configuration information, advertising and language
                    preferences, date and time stamps associated with your
                    usage, and frequency of visits to the websites. This
                    information is used to analyze overall trends, help us
                    provide and improve our websites, offer a tailored
                    experience for website users, and secure and maintain our
                    websites.
                  </p>
                  <p>
                    In addition, we gather certain information automatically as
                    part of your use of our products and services. This
                    information may include identifiers, commercial information,
                    and internet activity information such as IP address (or
                    proxy server), mobile device number, device and application
                    identification numbers, location, browser type, Internet
                    service provider or mobile carrier, the pages and files
                    viewed, website and webpage interactions including searches
                    and other actions you take, operating system type and
                    version, system configuration information, date and time
                    stamps associated with your usage and details of which of
                    our products and product versions you are using. This
                    information is used to maintain the security of the
                    services, to provide necessary functionality, to improve
                    performance of the services, to assess and improve customer
                    and user experience of the services, to validate that you
                    are a licensed user, to review compliance with applicable
                    usage terms, to identify future opportunities for
                    development of the services, to assess capacity
                    requirements, to identify customer opportunities, and for
                    the security of Salesforce generally (in addition to the
                    security of our products and services). Some of the device
                    and usage data collected by the services, whether alone or
                    in conjunction with other data, could identify you. Please
                    note that this device and usage data is primarily used to
                    identify the uniqueness of each user logging on (as opposed
                    to specific individuals), apart from where it is strictly
                    required to identify an individual for security purposes or
                    as required as part of our provision of the services to our
                    customers. For more information regarding the Tableau device
                    and usage data, please see&nbsp;here&nbsp;and&nbsp;here.
                  </p>
                  <p>
                    <span>
                      4.2 Cookies, web beacons and other tracking technologies
                      on our website and in email communications
                    </span>
                  </p>
                  <p>
                    We use technologies such as web beacons, pixels, tags, and
                    JavaScript, alone or in conjunction with cookies, to gather
                    information about the use of our websites and how people
                    interact with our emails.
                  </p>
                  <p>
                    When you visit our websites, we, or an authorized third
                    party, may place a cookie on your device that collects
                    information, including Personal Data, about your online
                    activities over time and across different sites. Cookies
                    allow us to track use, infer browsing preferences, and
                    improve and customize your browsing experience.
                  </p>
                  <p>
                    We use both session-based and persistent cookies on our
                    websites. Session-based cookies exist only during a single
                    session and disappear from your device when you close your
                    browser or turn off the device. Persistent cookies remain on
                    your device after you close your browser or turn your device
                    off. To change your cookie settings and preferences for one
                    of our websites, click the Cookie Preferences link in the
                    footer of the page. You can also control the use of cookies
                    on your device, but choosing to disable cookies on your
                    device may limit your ability to use some features on our
                    websites and services.
                  </p>
                  <p>
                    We also use web beacons and pixels on our websites and in
                    emails. For example, we may place a pixel in a marketing
                    email that notifies us when you click on a link in the
                    email. We use these technologies to operate and improve our
                    websites and marketing emails. For instructions on how to
                    unsubscribe from our marketing emails, please see Section
                    10.4 below.
                  </p>
                  <p>
                    The following describes how we use different categories of
                    cookies and similar technologies and your options for
                    managing the data collection settings of these technologies:
                  </p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <table border="1" cellspacing="0" cellpadding="3">
                    <tbody>
                      <tr>
                        <th scope="col">
                          <span>Type of Cookies</span>
                        </th>
                        <th scope="col">
                          <span>Description</span>
                        </th>
                        <th scope="col">
                          <span>Managing Settings</span>
                        </th>
                      </tr>
                      <tr>
                        <td valign="top">Required cookies</td>
                        <td valign="top">
                          <p>
                            Required cookies are necessary for basic website
                            functionality. Some examples include: session
                            cookies needed to transmit the website,
                            authentication cookies, and security cookies.
                          </p>
                          <p>
                            If you have chosen to identify yourself to us, we
                            may place on your browser a cookie that allows us to
                            uniquely identify you when you are logged into the
                            websites and to process your online transactions and
                            requests.
                          </p>
                        </td>
                        <td valign="top">
                          <p>
                            Because required cookies are essential to operate
                            the websites, there is no option to opt out of these
                            cookies.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Functional cookies</td>
                        <td valign="top">
                          <p>
                            Functional cookies enhance functions, performance,
                            and services on the website. Some examples include:
                            cookies used to analyze site traffic, cookies used
                            for market research, and cookies used to display
                            advertising that is not directed to a particular
                            individual.&nbsp;
                          </p>
                          <p>
                            Functional cookies may also be used to improve how
                            our websites function and to help us provide you
                            with more relevant communications, including
                            marketing communications. These cookies collect
                            information about how our websites are used,
                            including which pages are viewed most often.
                          </p>
                          <p>
                            We may use our own technology or third-party
                            technology to track and analyze usage information to
                            provide enhanced interactions and more relevant
                            communications, and to track the performance of our
                            advertisements.
                          </p>
                          <p>
                            For example, we use Google Analytics (&ldquo;Google
                            Analytics&rdquo;), a web analytics service provided
                            by Google, Inc., 1600 Amphitheatre Parkway, Mountain
                            View, CA 94043, USA.&nbsp; You can learn about
                            Google&rsquo;s privacy practices by going
                            to&nbsp;www.google.com/policies/privacy/&zwnj;partners/.
                          </p>
                          <p>
                            Google Analytics uses cookies to help us analyze how
                            our websites are used, including the number of
                            visitors, the websites from which visitors have
                            navigated to our websites, and the pages on our
                            websites to which visitors navigate. This
                            information is used by us to improve our
                            websites.&nbsp; We use Google Analytics with
                            restrictions on how Google can process our data
                            enabled.&nbsp; For information on Google&rsquo;s
                            Restricted Data Processing go
                            to&nbsp;https://privacy.google.com/businesses/rdp/.
                          </p>
                          <p>
                            Salesforce may also use HTML5 local storage or Flash
                            cookies for the above-mentioned purposes. These
                            technologies differ from browser cookies in the
                            amount and type of data they store, and how they
                            store it.
                          </p>
                          <p>&nbsp;</p>
                        </td>
                        <td>
                          <p>
                            You can choose to opt out of functional cookies. To
                            change your cookie settings and preferences,
                            including those for functional cookies, click
                            the&nbsp;<em>Cookie Preferences</em>&nbsp;link in
                            the footer of the page.
                          </p>
                          <p>
                            To opt out from data collection by Google Analytics,
                            you can download and install a browser add-on, which
                            is available&nbsp;here.
                          </p>
                          <p>
                            To learn how to control functional cookies via your
                            individual browser settings, click&nbsp;here.
                          </p>
                          <p>
                            To learn how to manage privacy and storage settings
                            for Flash cookies, click&nbsp;here.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top">Advertising cookies</td>
                        <td valign="top">
                          <p>
                            Advertising cookies track activity across websites
                            in order to understand a viewer&rsquo;s interests
                            and to direct marketing to them.
                          </p>
                          <p>
                            We sometimes use cookies delivered by us or by third
                            parties to show you ads for our products that we
                            think may interest you on devices you use and to
                            track the performance of our advertisements. For
                            example, these cookies collect information such as
                            which browser you used when visiting our websites.
                          </p>
                          <p>
                            Salesforce also contracts with third-party
                            advertising networks that collect IP addresses and
                            other information from web beacons on our websites,
                            from emails and on third-party websites. Advertising
                            networks follow your online activities over time and
                            across different websites or other online services
                            by collecting device and usage data through
                            automated means, including through the use of
                            cookies. These technologies may recognize you across
                            the different devices you use. When we work with
                            third party advertising networks, we require them to
                            restrict their data processing to only what is
                            necessary to provide us with the advertising
                            services we request.
                          </p>
                        </td>
                        <td valign="top">
                          <p>
                            You can choose to opt out of targeting and
                            advertising cookies. To change your cookie settings
                            and preferences, including those for targeting and
                            advertising cookies, click the Cookie Preferences
                            link in the footer of the page.
                          </p>
                          <p>
                            See Section 4.3, below, to learn more about these
                            and other advertising networks and your ability to
                            opt out of collection by certain third parties.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="margin-10-top-lg  text-size-default line-height-default        ">
                  <p>
                    <span>
                      4.3 Notices on behavioral advertising and opt-out for
                      website visitors
                    </span>
                  </p>
                  <p>
                    As described above, we or one of our authorized partners may
                    place or read cookies on your device when you visit our
                    websites for the purpose of serving you targeted advertising
                    (also referred to as &ldquo;online behavioral
                    advertising&rdquo; or &ldquo;interest-based
                    advertising&rdquo;). To learn more about targeted
                    advertising and advertising networks, please visit the
                    opt-out pages of the Network Advertising
                    Initiative,&nbsp;here, and the Digital Advertising
                    Alliance,&nbsp;here. To opt-out of targeted advertising that
                    is provided to us and to third parties by Salesforce
                    Audience Studio, click&nbsp;here.
                  </p>
                  <p>
                    To manage the use of targeting and advertising cookies on
                    this website, click the Cookie Preferences link in the
                    footer of the page or consult your individual browser
                    settings for cookies. To learn how to manage privacy and
                    storage settings for Flash cookies, click&nbsp;here. Various
                    browsers may also offer their own management tools for
                    removing HTML5 local storage.&nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <span>
                      4.4 Opt-Out from the setting of cookies on your individual
                      browser
                    </span>
                  </p>
                  <p>
                    In addition to using the user preference center, in many
                    cases you may opt-out from the collection of non-essential
                    device and usage data on your web browser (see Section 4.1
                    above) by managing your cookies at the browser or device
                    level. In addition, if you wish to opt-out of interest-based
                    advertising, click&nbsp;here&nbsp;(or, if located in the
                    European Union or United Kingdom, click&nbsp;here).&nbsp; To
                    opt-out of targeted advertising that is provided to us and
                    to third parties by Salesforce Audience Studio,
                    click&nbsp;here. Please note, however, that by blocking or
                    deleting cookies and similar technologies used on our
                    websites, you may not be able to take full advantage of the
                    websites.
                  </p>
                  <p>
                    While some internet browsers offer a &ldquo;do not
                    track&rdquo; or &ldquo;DNT&rdquo; option that lets you tell
                    websites that you do not want to have your online activities
                    tracked, these features are not yet uniform and there is no
                    common standard adopted by industry groups, technology
                    companies, or regulators. Therefore, we do not currently
                    commit to responding to browsers&rsquo; DNT signals with
                    respect to our websites. Salesforce takes privacy and
                    meaningful choice seriously and will make efforts to
                    continue to monitor developments around DNT browser
                    technology and the implementation of a standard.
                  </p>
                  <p>
                    <span>4.5 Social Media Features</span>
                  </p>
                  <p>
                    Our websites may use social media features, such as the
                    &ldquo;Tweet&rdquo; button and other sharing widgets
                    (&ldquo;Social Media Features&rdquo;). Social Media Features
                    may allow you to post information about your activities on
                    our website to outside platforms and social networks. Social
                    Media Features may also allow you to like or highlight
                    information we have posted on our website or our branded
                    social media pages. Social Media Features are either hosted
                    by each respective platform or hosted directly on our
                    website. To the extent the Social Media Features are hosted
                    by the platforms themselves, and you click through to these
                    from our websites, the platform may receive information
                    showing that you have visited our websites. If you are
                    logged in to your social media account, it is possible that
                    the respective social media network can link your visit to
                    our websites with your social media profile.
                  </p>
                  <p>
                    Salesforce also allows you to log in to certain of our
                    websites using sign-in services. These services authenticate
                    your identity and provide you the option to share certain
                    Personal Data from these services with us such as your name
                    and email address to pre-populate our sign-up form.
                  </p>
                  <p>
                    Your interactions with Social Media Features are governed by
                    the privacy policies of the companies providing them.
                  </p>
                  <p>
                    <span>4.6 Telephony log information</span>
                  </p>
                  <p>
                    If you use certain features of our services on a mobile
                    device, we may also collect telephony log information (like
                    phone numbers, time and date of calls, duration of calls,
                    SMS routing information and types of calls), device event
                    information (such as crashes, system activity, hardware
                    settings, browser language), and location information
                    (through IP address, GPS, and other sensors that may, for
                    example, provide us with information on nearby devices,
                    Wi-Fi access points and cell towers).
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="5-purposes-for-which-we-process-personal-data-and-the-legal-bases-on-which-we-rely"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    5. Purposes for which we process Personal Data and the legal
                    bases on which we rely
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    We collect and process your Personal Data (including, where
                    legally permissible, special categories of Personal Data)
                    for the following purposes. Where required by law, we obtain
                    your consent to use and process your Personal Data for the
                    below purposes. Otherwise, we rely on another authorized
                    legal basis (including but not limited to the (a)
                    performance of a contract or (b) legitimate interest) to
                    collect and process your Personal Data, as further detailed
                    below.
                  </p>
                  <ul>
                    <li>
                      Providing and promoting our websites and services
                      (including necessary functionality): We process your
                      Personal Data to perform our contract with you for the use
                      of our websites and services and to fulfill our
                      obligations under the applicable terms of use and service;
                      if we have not entered into a contract with you, we base
                      the processing of your Personal Data on our legitimate
                      interest to operate and administer our websites and
                      services and to provide you with content you access and
                      request (e.g., to download content from our websites);
                    </li>
                    <li>
                      Promoting the security of our websites and services: We
                      process your Personal Data by tracking use of our websites
                      and services, creating aggregated non-personal data,
                      verifying accounts and activity, investigating suspicious
                      activity, and enforcing our terms and policies to the
                      extent it is necessary for our legitimate interest in
                      promoting the safety and security of the services,
                      websites, systems and applications and the security of
                      Salesforce generally, and in protecting our rights and the
                      rights of others;
                    </li>
                    <li>
                      Managing user registrations: If you have registered for an
                      account with us, we process your Personal Data by managing
                      your user account for the purpose of performing our
                      contract with you according to applicable terms of
                      service;
                    </li>
                    <li>
                      Handling contact and user support requests: If you fill
                      out a &ldquo;Contact Me&rdquo; web form or request user
                      support, or if you contact us by other means including but
                      not limited to via phone, we process your Personal Data to
                      perform our contract with you and to the extent it is
                      necessary for our legitimate interest in fulfilling your
                      requests and communicating with you;
                    </li>
                    <li>
                      Managing event registrations and attendance: We process
                      your Personal Data to plan and host events or webinars for
                      which you have registered or that you attend, including
                      sending related communications to you or to perform our
                      contract with you or where we receive your consent;
                    </li>
                    <li>
                      Managing, and participating in, contests, programs or
                      promotions: If you register for a contest, promotion, or a
                      program, we process your Personal Data to perform our
                      contract with you or where we seek your consent. Some
                      contests, programs and promotions have additional rules
                      containing information about how we will process your
                      Personal Data;
                    </li>
                    <li>
                      Managing payments: If you have provided financial
                      information to us, we process your Personal Data to verify
                      that information and to collect payments to the extent
                      that doing so is necessary to complete a transaction and
                      perform our contract with you;
                    </li>
                    <li>
                      Contract fulfilment: We may process your Personal Data for
                      the purposes of fulfilling our contract with you or your
                      employer (e.g. if you are an authorized user of our
                      products and services or work for a service provider or
                      supplier to Salesforce). We do this where it is necessary
                      for the performance of the relevant contract;
                    </li>
                    <li>
                      Developing and improving our websites and services: We
                      process your Personal Data to analyze trends and to track
                      your usage of and interactions with our websites and
                      services to the extent it is necessary for our legitimate
                      interest in developing and improving our websites and
                      services and providing our users with more relevant
                      content and service offerings, or where we seek your
                      consent;
                    </li>
                    <li>
                      Assessing and improving user experience: We process device
                      and usage data as described in Section 4.1 above, which in
                      some cases may be associated with your Personal Data, to
                      analyze trends and assess and improve the overall user
                      experience to the extent it is necessary for our
                      legitimate interest in developing and improving the
                      service offering or website, or where we seek your
                      consent;&nbsp;
                    </li>
                    <li>
                      Reviewing compliance with applicable usage terms: We
                      process your Personal Data to validate that you are a
                      licensed user and to review compliance with the applicable
                      usage terms in our customer&rsquo;s or user&rsquo;s
                      contract to the extent that it is in our legitimate
                      interest to ensure adherence to the relevant terms;&nbsp;
                    </li>
                    <li>
                      Assessing capacity requirements: We process your Personal
                      Data to assess the capacity requirements of our services
                      to the extent that it is in our legitimate interest to
                      ensure that we are meeting the necessary capacity
                      requirements of our service offering;&nbsp;
                    </li>
                    <li>
                      Identifying customer opportunities: We process your
                      Personal Data to assess new potential customer
                      opportunities to the extent that it is in our legitimate
                      interest to ensure that we are meeting the demands of our
                      customers and their users&rsquo; experiences;&nbsp;
                    </li>
                    <li>
                      Registering and hosting visitors to the office or at
                      events: We process your Personal Data for security,
                      health, and safety reasons, to register visitors and to
                      manage non-disclosure agreements that visitors may be
                      required to sign, to the extent such processing is
                      necessary for our legitimate interest in protecting our
                      offices, staff and visitors and our confidential
                      information against unauthorized access. In addition,
                      certain health data (which may include special categories
                      of Personal Data) may be processed for office visits and
                      at events during the COVID-19 pandemic to ensure the
                      safety and security of our visitors and employees (where
                      legally permitted) with your consent or where necessary
                      for reasons of public interest in the area of public
                      health;
                    </li>
                    <li>
                      Recording phone calls: We may record phone calls for
                      training, quality assurance, and administration purposes.
                      If required under applicable law, we will obtain your
                      prior consent or give you the option to object to a call
                      being recorded;
                    </li>
                    <li>
                      Displaying personalized advertisements and content: We
                      process your Personal Data to conduct market research,
                      advertise to you, provide personalized information about
                      us on and off our websites and to provide other
                      personalized content based upon your activities and
                      interests to the extent it is necessary for our legitimate
                      interest in advertising our websites or, where necessary,
                      to the extent you have provided your prior consent (please
                      see Section 10 below, to learn how you can control the
                      processing of your Personal Data by Salesforce for
                      personalized advertising purposes);
                    </li>
                    <li>
                      Administering surveys and conducting research: We process
                      your Personal Data (including special categories of
                      Personal Data) in order to meet the goals set out in
                      surveys or research as well as to analyze our compliance
                      with internal policies. If required under applicable law,
                      we will obtain your consent to process your
                      responses;&nbsp;
                    </li>
                    <li>
                      Sending communications: We will process your Personal Data
                      or device and usage data, which in some cases may be
                      associated with your Personal Data, to send you marketing
                      information, product recommendations and other
                      non-transactional communications (e.g., marketing
                      newsletters, telemarketing calls, SMS, or push
                      notifications) about us and our affiliates and partners,
                      including information about our products, promotions, news
                      or events as necessary for our legitimate interest in
                      conducting direct marketing or to the extent you have
                      provided your prior consent (please see Section 10 below
                      to learn how you can control the processing of your
                      Personal Data by Salesforce for marketing purposes);&nbsp;
                    </li>
                    <li>
                      Collection of diversity information: We may process your
                      Personal Data (including special categories of Personal
                      Data) to the extent you voluntarily consent to provide it
                      to meet our broader community-minded goals related to
                      diversity and equality of opportunity. If required under
                      applicable law, we will obtain your consent or explicit
                      consent; and&nbsp;
                    </li>
                    <li>
                      Compliance with legal obligations: We process your
                      Personal Data when cooperating with public and government
                      authorities, courts or regulators in accordance with our
                      legal obligations under applicable laws to the extent this
                      requires the processing or disclosure of Personal Data to
                      protect our rights or is necessary for our legitimate
                      interest in protecting against misuse or abuse of our
                      websites or services, protecting personal property or
                      safety, pursuing remedies available to us and limiting our
                      damages, complying with judicial proceedings, court orders
                      or legal processes, respond to lawful requests, or for
                      auditing purposes.
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <br />
                    If we need to collect and process Personal Data by law, or
                    under a contract we have entered into with you, and you fail
                    to provide the required Personal Data when requested, we may
                    not be able to perform our contract with you.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="6-who-do-we-share-personal-data-with"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    6. Who do we share Personal Data with?
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>We may share your Personal Data as follows:</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      Service providers: With our contracted service providers,
                      who provide services such as IT and system administration
                      and hosting, credit card processing, research and
                      analytics, marketing, customer support and data enrichment
                      for the purposes and pursuant to the legal bases described
                      above in Section 5;
                    </li>
                    <li>
                      Affiliates: If you use our websites to register for an
                      event or webinar organized by one of our affiliates, we
                      may share your Personal Data with the affiliate to the
                      extent this is required on the basis of the
                      affiliate&rsquo;s contract with you to process your
                      registration and ensure your participation in the event;
                      in such instances, our affiliate will process the relevant
                      Personal Data as a separate controller and will provide
                      you with further information on the processing of your
                      Personal Data, where required.&nbsp;
                    </li>
                    <li>
                      Event sponsors: If you attend an event or webinar
                      organized by us, or download or access an asset on our
                      website, we may share your Personal Data with sponsors of
                      the event. If required by applicable law, you may consent
                      to such sharing via the registration form or by allowing
                      your attendee badge to be scanned at a sponsor booth. In
                      these circumstances, your information will be subject to
                      the sponsors&rsquo; privacy statements. If you do not wish
                      for your information to be shared, you may choose to not
                      opt-in via event/webinar registration or elect to not have
                      your badge scanned, or you can opt-out in accordance with
                      Section 10 below;
                    </li>
                    <li>
                      Customers with whom you are affiliated: If you use our
                      services as an authorized user, we may share your Personal
                      Data with your affiliated customer responsible for your
                      access to the services to the extent this is necessary for
                      verifying accounts and activity, investigating suspicious
                      activity, or enforcing our terms and policies;&nbsp;
                    </li>
                    <li>
                      Contest and promotion sponsors: With sponsors of contests
                      or promotions for which you register in order to fulfill
                      the contest or promotion;
                    </li>
                    <li>
                      Third party networks and websites: With third-party social
                      media networks, advertising networks and websites, so that
                      Salesforce can market and advertise on third party
                      platforms and websites;
                    </li>
                    <li>
                      Third party AppExchange partners: Specifically in relation
                      to the AppExchange website, if you choose to interact with
                      or use third-party tools, we may share your Personal Data
                      with our third party partners who may contact you
                      regarding their products or services;
                    </li>
                    <li>
                      Professional advisers: In individual instances, we may
                      share your Personal Data with professional advisers acting
                      as service providers, processors, or joint controllers -
                      including lawyers, bankers, auditors, and insurers who
                      provide consultancy, banking, legal, insurance and
                      accounting services, and to the extent we are legally
                      obliged to share or have a legitimate interest in sharing
                      your Personal Data;
                    </li>
                    <li>
                      Salesforce family affiliates: With affiliates within the
                      Salesforce corporate group and companies that we acquire
                      in the future after they are made part of the Salesforce
                      corporate group, to the extent such sharing of data is
                      necessary to fulfill a request you have submitted via our
                      websites or for customer support, marketing, technical
                      operations and account management purposes. A list of
                      Salesforce&rsquo;s affiliates can be found in the List of
                      Subsidiaries section of Salesforce&rsquo;s most recent
                      Form 10-K, available under the SEC Filings tab by
                      selecting the &ldquo;Annual Filings&rdquo; filter on the
                      page located&nbsp;here;&nbsp;
                    </li>
                    <li>
                      Third parties involved in a corporate transaction: If we
                      are involved in a merger, reorganization, dissolution or
                      other fundamental corporate change, or sell a website or
                      business unit, or if all or a portion of our business,
                      assets or stock are acquired by a third party. In
                      accordance with applicable laws, we will use reasonable
                      efforts to notify you of any transfer of Personal Data to
                      an unaffiliated third party; and
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <ul>
                    <li>
                      <p>
                        Third party accounts: In relation to Tableau, if you
                        connect to your third party accounts through our
                        products, we will use that information to authenticate
                        you, enumerate the data sources available to you,
                        download any data you request us to, and download and
                        refresh authentication tokens or persist authentication
                        information such as user names and passwords as
                        necessary to continue to connect to these data.
                      </p>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    We may also share anonymous or de-identified usage data with
                    Salesforce&rsquo;s service providers for the purpose of
                    helping Salesforce in such analysis and improvements.
                    Additionally, Salesforce may share such anonymous or
                    de-identified usage data on an aggregate basis in the normal
                    course of operating our business; for example, we may share
                    information publicly to show trends about the general use of
                    our services.
                  </p>
                  <p>
                    Anyone using our communities, forums, blogs, or chat rooms
                    on our websites may read any Personal Data or other
                    information you choose to submit and post.
                  </p>
                  <p>
                    For more information on the recipients of your Personal
                    Data, please contact us by using the information in Section
                    13 below.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="7-international-transfer-of-personal-data"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    7. International transfer of Personal Data
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    Your Personal Data may be collected, transferred to and
                    stored by us in the United States and by our affiliates and
                    third-parties disclosed in Section 6, above, that are based
                    in other countries.
                  </p>
                  <p>
                    Therefore, your Personal Data may be processed outside your
                    jurisdiction, including in countries and jurisdictions that
                    are not subject to an adequacy decision by the European
                    Commission or your local legislature or regulator, and that
                    may not provide for the same level of data protection as
                    your jurisdiction. We ensure that the recipient of your
                    Personal Data offers an adequate level of protection and
                    security, for instance by entering into the appropriate
                    back-to-back agreements and, if required, standard
                    contractual clauses or an alternative mechanism for the
                    transfer of data as approved by the European Commission
                    (Art. 46 GDPR) or other applicable regulators or
                    legislators. Where required by applicable law, we will only
                    share, transfer or store your Personal Data outside of your
                    jurisdiction with your prior consent.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="8-children"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    8. Children
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  Our websites and services are not directed at children. We do
                  not knowingly collect Personal Data from children under the
                  age of 16, or such other applicable age of consent for privacy
                  purposes in relevant individual jurisdictions, unless (a) we
                  have obtained consent from a parent or guardian, (b) such
                  collection is subject to a separate agreement with us or (c)
                  the visit by a child is unsolicited or incidental. If you are
                  a parent or guardian and believe your child has provided us
                  with Personal Data without your consent, please contact us by
                  using the information in Section 13 below and we will take
                  steps to delete their Personal Data from our systems.
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="update_info"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    9. How long do we keep your Personal Data?
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    We may retain your Personal Data for a period of time
                    consistent with the original purpose of collection (see
                    Section 5 above) or as long as required to fulfill our legal
                    obligations. We determine the appropriate retention period
                    for Personal Data on the basis of the amount, nature, and
                    sensitivity of the Personal Data being processed, the
                    potential risk of harm from unauthorized use or disclosure
                    of the Personal Data, whether we can achieve the purposes of
                    the processing through other means, and on the basis of
                    applicable legal requirements (such as applicable statutes
                    of limitation).
                  </p>
                  <p>
                    After expiry of the applicable retention periods, your
                    Personal Data will be deleted. If there is any data that we
                    are unable, for technical reasons, to delete entirely from
                    our systems, we will implement appropriate measures to
                    prevent any further use of such data.
                  </p>
                  <p>
                    For more information on data retention periods, please
                    contact us by using the information in the Section 13 below.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="10-your-rights-relating-to-your-personal-data"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    10. Your rights relating to your Personal Data
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>10.1 Your rights</span>
                  </p>
                  <p>
                    You may have certain rights relating to your Personal Data,
                    subject to local data protection laws. Depending on the
                    applicable laws these rights may include the right to:
                  </p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>Access your Personal Data held by us;</li>
                    <li>Know more about how we process your Personal Data;</li>
                    <li>
                      Rectify inaccurate Personal Data and, taking into account
                      the purpose of processing the Personal Data, ensure it is
                      complete;
                    </li>
                    <li>Erase or delete your Personal Data;</li>
                    <li>Restrict our processing of your Personal Data;</li>
                    <li>
                      Transfer your Personal Data to another controller, to the
                      extent possible;
                    </li>
                    <li>Object to any processing of your Personal Data;</li>
                    <li>
                      Opt out of certain disclosures of your Personal Data to
                      third parties;
                    </li>
                    <li>
                      If you&rsquo;re under the age of 16, or such other
                      applicable age of consent for privacy purposes in relevant
                      individual jurisdictions, opt in to certain disclosures of
                      your Personal Data to third parties;
                    </li>
                    <li>
                      Not be discriminated against for exercising your rights
                      described above;&nbsp;
                    </li>
                    <li>
                      Not be subject to a decision based solely on automated
                      processing, including profiling, which produces legal
                      effects ("Automated Decision-Making"); and
                    </li>
                    <li>
                      Withdraw your consent at any time (to the extent we base
                      processing on consent), without affecting the lawfulness
                      of the processing based on such consent before its
                      withdrawal.
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    Where we process your Personal Data for direct marketing
                    purposes or share it with third parties for their own direct
                    marketing purposes, you can exercise your right to object at
                    any time to such processing without having to provide any
                    specific reason for such objection
                  </p>
                  <p>
                    Please note that Automated Decision-Making currently does
                    not take place on our websites or in our services.
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>10.2 How to exercise your rights</span>
                  </p>
                  <p>
                    To exercise your rights, please contact us by using the
                    information in Section 13 below. Your Personal Data may be
                    processed in responding to these rights. We try to respond
                    to all legitimate requests within one month unless otherwise
                    required by law, and will contact you if we need additional
                    information from you in order to honor your request or
                    verify your identity. Occasionally it may take us longer
                    than a month, taking into account the complexity and number
                    of requests we receive. If you are an employee of a
                    Salesforce customer, we recommend you contact your
                    employer&rsquo;s system administrator for assistance in
                    correcting or updating your information.
                  </p>
                  <p>
                    Some registered users may update their user settings,
                    profiles, organization settings and event registrations by
                    logging into their accounts and editing their settings or
                    profiles.
                  </p>
                  <p>
                    To update your billing information, discontinue your account
                    or request return or deletion of your Personal Data and
                    other information associated with your account, please
                    contact us by using the information in Section 13 below.
                  </p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>10.3 Your rights relating to customer data</span>
                  </p>
                  <p>
                    As described above, we may also process Personal Data
                    submitted by or for a customer to our products and services.
                    To this end, if not stated otherwise in this Privacy
                    Statement or in a separate disclosure, we process such
                    Personal Data as a processor on behalf of our customer (and
                    its affiliates) who is the controller of the Personal Data
                    (see Section 1 above). We are not responsible for and have
                    no control over the privacy and data security practices of
                    our customers, which may differ from those explained in this
                    Privacy Statement. If your data has been submitted to us by
                    or on behalf of a Salesforce customer and you wish to
                    exercise any rights you may have under applicable data
                    protection laws, please inquire with them directly. Because
                    we may only access a customer&rsquo;s data upon their
                    instructions, if you wish to make your request directly to
                    us, please provide us the name of the Salesforce customer
                    who submitted your data to us. We will refer your request to
                    that customer, and will support them as needed in responding
                    to your request within a reasonable timeframe.
                  </p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>
                      10.4 Your preferences for email and SMS marketing
                      communications
                    </span>
                  </p>
                  <p>
                    If we process your Personal Data for the purpose of sending
                    you marketing communications, you may manage your receipt of
                    marketing and non-transactional communications from
                    Salesforce by clicking on the &ldquo;unsubscribe&rdquo; link
                    located on the bottom of Salesforce marketing emails, by
                    replying or texting &lsquo;STOP&rsquo; if you receive
                    Salesforce SMS communications, or by
                    unsubscribing&nbsp;here. Please note that, notwithstanding
                    the above, you will continue to receive marketing and
                    non-transactional communications from CMO Club unless you
                    manage your receipt of such communications by clicking on
                    the &ldquo;unsubscribe&rdquo; link located on the bottom of
                    CMO Club&rsquo;s marketing emails.
                  </p>
                  <p>
                    You may also turn off push notifications on Salesforce apps
                    on your device, or unsubscribe by contacting us using the
                    information in the &ldquo;Contacting us&rdquo; section,
                    below.
                  </p>
                  <p>
                    Please note that opting out of marketing communications does
                    not opt you out of receiving important business
                    communications related to your current relationship with us,
                    such as communications about your subscriptions or event
                    registrations, service announcements or security
                    information.
                  </p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    <span>
                      10.5 Your preferences for telemarketing communications
                    </span>
                  </p>
                  <p>
                    If you want your phone number to be added to our internal
                    Do-Not-Call telemarketing register, please contact us by
                    using the information in Section 13 below. Please include
                    your first name, last name, company and the phone number you
                    wish to add to our Do-Not-Call register.
                  </p>
                  <p>
                    Alternatively, you can always let us know during a
                    telemarketing call that you do not want to be called again
                    for marketing purposes.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="11-how-we-secure-your-personal-data"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    11. How we secure your Personal Data
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    We take appropriate precautions including organizational,
                    technical, and physical measures to help safeguard against
                    accidental or unlawful destruction, loss, alteration, and
                    unauthorized disclosure of, or access to, the Personal Data
                    we process or use.
                  </p>
                  <p>
                    While we follow generally accepted standards to protect
                    Personal Data, no method of storage or transmission is 100%
                    secure. You are solely responsible for protecting your
                    password, limiting access to your devices and signing out of
                    websites after your sessions. If you have any questions
                    about the security of our websites, please contact us by
                    using the information in Section 13 below.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="12-changes-to-this-privacy-statement"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    12. Changes to this Privacy Statement
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    We will update this Privacy Statement from time to time to
                    reflect changes in our practices, technologies, legal
                    requirements, and other factors. If we do, we will update
                    the &ldquo;effective date&rdquo; at the top. If we make a
                    material update, we may provide you with notice prior to the
                    update taking effect, such as by posting a notice on our
                    website or by contacting you directly, or where required
                    under applicable law and feasible, seek your consent to
                    these changes.
                  </p>
                  <p>
                    We encourage you to periodically review this Privacy
                    Statement to stay informed about our collection, processing
                    and sharing of your Personal Data.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="13-contacting-us"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    13. Contacting us
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    Salesforce has appointed a Data Protection Officer and, in
                    India, a Grievance Officer. To exercise your rights
                    regarding your Personal Data, or if you have questions
                    regarding this Privacy Statement or our privacy practices
                    please fill out&nbsp;this form, email us
                    at&nbsp;privacy@salesforce.com&nbsp;or&nbsp;datasubjectrequest@salesforce.com,
                    call us at 1-844-287-7147, or write to us at:
                  </p>
                  <p>
                    Salesforce Data Protection Officer (Salesforce Privacy Team)
                  </p>
                  <p>415 Mission St, 3rd Floor</p>
                  <p>San Francisco, CA 94105, USA&nbsp;</p>
                  <p>
                    When you contact us, please indicate in which country and/or
                    state you reside.
                  </p>
                  <p>
                    We are committed to working with you to obtain a fair
                    resolution of any complaint or concern about privacy. If,
                    however, you believe that we have not been able to assist
                    with your complaint or concern, and you are located in the
                    European Economic Area or the United Kingdom, you have the
                    right to lodge a complaint with the competent supervisory
                    authority.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="14-adherence-to-apec-privacy-recognition-for-processors-prp-system"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                col-lg-10        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    14. Adherence to APEC Privacy Recognition for Processors
                    (PRP) System
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  We participate in the APEC PRP system as per
                  Salesforce&rsquo;s&nbsp;APEC PRP Notice. If you have an
                  unresolved privacy or data use concern that we have not
                  addressed to your satisfaction, please contact our third party
                  dispute resolution provider (free of charge)
                  at&nbsp;https://feedback-form.truste.com/watchdog/request.
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="15-additional-disclosures-for-california-residents"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                col-lg-10        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    15. Additional Disclosures for California Residents
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <p>
                    The California Consumer Privacy Act requires businesses to
                    disclose whether they sell Personal Data. As a business
                    covered by the CCPA, we do not sell Personal Data.&nbsp; We
                    may share Personal Data with third parties or allow them to
                    collect Personal Data from our sites or services if those
                    third parties are authorized service providers or business
                    partners who have agreed to our contractual limitations as
                    to their retention, use, and disclosure of such Personal
                    Data, or if you use Salesforce sites or services to interact
                    with third parties or direct us to disclose your Personal
                    Data to third parties.
                  </p>
                  <p>
                    California law requires that we detail the categories of
                    Personal Data that we disclose for certain &ldquo;business
                    purposes,&rdquo; such as to service providers that assist us
                    with securing our services or marketing our products, and to
                    such other entities as described in Sections 5 and 6 of this
                    Privacy Statement. We disclose the following categories of
                    Personal Data for our business purposes:
                  </p>
                  <p>&nbsp;</p>
                  <ul>
                    <li>Identifiers;</li>
                    <li>Commercial information;</li>
                    <li>Internet activity information;</li>
                    <li>Financial information;&nbsp;&nbsp;</li>
                    <li>
                      Professional and employment-related information;&nbsp;
                    </li>
                    <li>Education information; and</li>
                    <li>
                      Inferences drawn from any of the above information
                      categories.&nbsp;
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    California law grants state residents certain rights,
                    including the rights to access specific types of Personal
                    Data, to learn how we process Personal Data, to request
                    deletion of Personal Data, and not to be denied goods or
                    services for exercising these rights.
                  </p>
                  <p>
                    If you are a California resident under the age of 18 and
                    have registered for an account with us, you may ask us to
                    remove content or information that you have posted to our
                    website(s). Please note that your request does not ensure
                    complete or comprehensive removal of the content or
                    information, because, for example, some of your content may
                    have been reposted by another user.
                  </p>
                  <p>
                    For information on how to exercise your rights, please refer
                    to Section 10.2 of this Privacy Statement. If you are an
                    authorized agent wishing to exercise rights on behalf of a
                    California resident, please contact us using the information
                    in the &ldquo;Contacting Us&rdquo; section above and provide
                    us with a copy of the consumer&rsquo;s written authorization
                    designating you as their agent.&nbsp;
                  </p>
                  <p>
                    We may need to verify your identity and place of residence
                    before completing your rights request.
                  </p>
                </div>
              </div>
              <div class="headingComponent parbase section">
                <h3
                  id="california-privacy-rights-reporting"
                  class="        h3lg        text-night        text-left        salesforce-sans-light                                                col-lg-10        margin-80-top-lg margin-40-bottom-lg        "
                >
                  <span class="            header-text                                    ">
                    California Privacy Rights Reporting
                  </span>
                </h3>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-default line-height-default        ">
                  <table border="1" cellspacing="0" cellpadding="3">
                    <colgroup>
                      <col width="200" />
                      <col width="200" />
                      <col width="200" />
                      <col width="200" />
                      <col width="200" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          <p>&nbsp;</p>
                        </th>
                        <th scope="col">
                          <span>Requests Submitted</span>
                        </th>
                        <th scope="col">
                          <span>Requests Fulfilled in Whole Or In Part*</span>
                        </th>
                        <th scope="col">
                          <span>Average Days to Respond</span>
                        </th>
                        <th scope="col">
                          <span>Median Days to Complete a Request**</span>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <span>Requests for access to Personal Data</span>
                        </td>
                        <th>21</th>
                        <th>11</th>
                        <th>1</th>
                        <th>31</th>
                      </tr>
                      <tr>
                        <td>
                          <span>Requests to delete Personal Data</span>
                        </td>
                        <th>21</th>
                        <th>8</th>
                        <th>1</th>
                        <th>31</th>
                      </tr>
                    </thead>
                  </table>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="bodyCopyComponent parbase section">
                <div class="  text-size-footnote line-height-default        ">
                  <p>
                    *Requests may be unable to be completed due to factors like
                    the inability to verify the resident&rsquo;s identity or
                    lack of response.
                  </p>
                  <p>
                    **Time to complete a request may depend in part on receipt
                    of information from the person making the request, such as
                    information to verify the person&rsquo;s identity or
                    information about the action requested.
                  </p>
                  <p>
                    Data is for all requests received by the Salesforce Family
                    Affiliates from California residents between January 1 and
                    December 31, 2020.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyStatement;
