import React from "react";
import Foliage from "../../assets/images/chart-foliage.png";
import { TEXT_TYPES } from "../../utils/constants";

const Chart = ({ ...props }) => {
  const { strings, subtitle } = props;
  return (
    <div className="sf-row sf-row-xs--center chart">
      <div
        className={`sf-col-xs-20 sf-col-md-20 ${
          subtitle ? "sf-col-lg-16" : "sf-col-lg-20"
        }`}
      >
        <div className="sf-row sf-row-xs--center">
          <div className={`${subtitle ? "text__sub" : "text"}`}>
            <h2
              className={`text__title ${
                subtitle ? "extra" : ""
              }  -grid sf-col-xs-20 sf-col-md-20`}
            >
              {strings.title}
            </h2>
            {strings.body && (
              <p className="text__body -grid sf-col-xs-20 sf-col-md-14">
                {strings.body}
              </p>
            )}
          </div>
        </div>
        <div className={`sf-row sf-row-xs--center`}>
          <div className={`sf-row sf-row-xs--center intro__percentage-wrap`}>
            <div className={`sf-row sf-row-xs--center`}>
              {strings.stats.map((item, index) => (
                <div
                  key={`percentage-text-${index}`}
                  className={`sf-row sf-row-xs--center`}
                  // style={{ alignItems: "start" }}
                >
                  <div
                    className={`sf-col-xs-16 sf-col-md-8 sf-col-lg-4 percentage-box`}
                  >
                    {item.multi && (
                      <div className={`chart__percentageSection`}>
                        <img
                          src={Foliage}
                          // alt={strings.logoAlt}
                          className="chart__foliage"
                          aria-hidden={true}
                        />
                        <p
                          className={`chart__percentageText ${
                            index == 1 && "second"
                          } ${index == 2 && "third"}`}
                        >
                          <span className="chart__plusSymbol">+</span>
                          <span className="chart__percentageTitle">
                            {item.multi}
                          </span>
                          <span className="chart__percentageSymbol">%</span>
                        </p>
                      </div>
                    )}
                    <div>
                      <p className="chart__subtitle">{item.title}</p>
                    </div>
                    {/* <div>
                      <p className="chart__moreLink">
                        <a href="">Learn more</a>
                      </p>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="sf-row sf-row-xs--center intro__wheel-ctasfooter">
              <p
                className={`text__body percentage -grid sf-col-xs-16 sf-col-md-16 sf-col-lg-12`}
              >
                {strings.citation}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
