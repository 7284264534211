const industriesPath = require("./industriesPath");

module.exports = {
  title: "Nonprofit",
  slug: "nonprofit",
  astro: "./astros/astro_education_executive_misc.png",
  roles: industriesPath.roles.nonprofit,
  businessType: industriesPath.businessType.nonprofit,
  generic: [
    {
      eyebrow: "For Nonprofit Leaders",
      headline:
        "Become a Connected Nonprofit with the Power of One Integrated Platform",
      images: {
        landscape: "./generic/nonprofit-landscape.jpg",
        square: "./generic/nonprofit-square.jpg",
      },
      body: "Learn how your nonprofit can make use of technology that provides a single source of truth and empowers your entire organization with the real-time data they need to make a bigger impact.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/sfdo/ngo/unlock-power-of-salesforce-platform/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/nonprofit2.png",
      headline:
        "Learn about the secure, flexible platform built for and used by over 50,000 nonprofits.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/sfdo/ngo/nonprofit-cloud-demo/?icid=SFORG:ngo-cloud-hero:",
    },
    {
      image: "./offers/nonprofit3.png",
      headline:
        "Maximize your nonprofit’s impact, and realize success now, with Nonprofit Success Pack.",
      cta: "Watch demo",
      link: "https://www.salesforce.org/nonprofit/nonprofit-success-pack-demo/?leadcreated=true&redirect=true&sfuuid=dbc97871-cecd-45ef-b89d-e985615b2ad6&d=70130000000sUVq&nc=7013y000002KLf3AAG",
    },
  ],
};
