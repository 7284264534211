import _ from "lodash";
export const controlInactivyTimeout = () => {
  const timeoutMessage = {
    event: "quiz:interaction",
    name: "",
  };
  const windowEvents = [
    "touchstart",
    "touchend",
    "touchmove",
    "mousemove",
    "scroll",
    "click",
  ];
  const sendTimeoutMessage = (name) => {
    window.parent.postMessage({ ...timeoutMessage, name }, "*");
  };
  windowEvents.forEach((name) => {
    window.addEventListener(
      name,
      _.debounce(() => sendTimeoutMessage(name), 100)
    );
  });
};
export const sendQuizResultsData = (role, url) => {
  const quizCompletedMessage = {
    event: "quiz:end",
    role: "",
    url: "",
  };
  quizCompletedMessage.role = role;
  quizCompletedMessage.url = url;
  window.parent.postMessage(quizCompletedMessage, "*");
};
