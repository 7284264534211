import ReactHtmlParser from "react-html-parser";
const FormattedText = (props) => {
  const { text, keepTogether, superset, superset2, breakline } = props;
  let htmlString = text;
  if (breakline) {
    const reBL = new RegExp(breakline, "g");
    htmlString = htmlString.replace(
      reBL,
      `<span class="breakline">${breakline}</span>`
    );
  }
  if (keepTogether) {
    const reKT = new RegExp(keepTogether, "g");
    htmlString = htmlString.replace(
      reKT,
      `<span class="keeptogether">${keepTogether}</span>`
    );
  }
  if (superset) {
    const reST = new RegExp(superset, "g");
    htmlString = htmlString.replace(
      reST,
      `<span class="superset">${superset}</span>`
    );
  }
  if (superset2) {
    const reST2 = new RegExp(superset2, "g");
    htmlString = htmlString.replace(
      reST2,
      `<span class="superset">${superset2}</span>`
    );
  }
  return ReactHtmlParser(htmlString);
};

export default FormattedText;
