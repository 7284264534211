import { useWindowSize } from "use-hooks";
import { BREAKPOINTS } from "../../utils/constants";

const useDevicesBreakpoints = () => {
  const size = useWindowSize();

  const getIsMobile = () => {
    return size.width < BREAKPOINTS.XL;
  };

  const getIsTablet = () => {
    return size.width < BREAKPOINTS.LG && size.width >= BREAKPOINTS.MED;
  };

  const getIsPhone = () => {
    return size.width < BREAKPOINTS.MED;
  };

  return {
    size: size,
    isMobile: getIsMobile(),
    isTablet: getIsTablet(),
    isPhone: getIsPhone(),
  };
};

export default useDevicesBreakpoints;
