import React from "react";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

const BackButton = ({ ...props }) => {
  const { back, step } = props;

  return (
    <div>
      <button
        className={`back-btn sf-row sf-row-xs--center sf-row-xs--middle ${
          step > 1 ? "" : "back-btn--visibility-hidden"
        }`}
        onClick={() => back()}
        disabled={step === 1 ? true : false}
        aria-label="Back"
      >
        <Arrow />
      </button>
    </div>
  );
};

export default BackButton;
