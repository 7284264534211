const industriesPath = require("./industriesPath");

module.exports = {
  title: "High Tech",
  slug: "high-tech",
  astro: "./astros/astro_education_executive_misc.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For High Tech Leaders",
      headline: "Discover New Ways To Work and Lead Through Change",
      images: {
        landscape: "./generic/high-tech-landscape.jpg",
        square: "./generic/high-tech-square.jpg",
      },
      body: "Design amazing employee experiences that set your teams up for success, today and in the future.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/platform/high-tech-future-of-work/",
    },
  ],
};
