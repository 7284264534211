module.exports = {
  roles: {
    general: [
      "sales",
      "it",
      "commerce",
      "service",
      "marketing",
      "executive",
      "data",
    ],
    education: [
      "recruitment-admissions-enrollment",
      "student-success",
      "advancement",
      "operations",
      "marketing-edu",
    ],
    nonprofit: [
      "fundraising-development",
      "program-management",
      "grants-management",
      "marketing-nonprofit",
      "executive-nonprofit",
    ],
    publicSector: [
      "it-public-sector",
      "compliance-security",
      "hr",
      "executive-public-sector",
    ],
  },
  businessType: {
    general: ["small-business", "midsize-business", "enterprise"],
    education: ["k-12", "higher-education"],
    nonprofit: [
      "small-business-nonprofit",
      "midsize-business-nonprofit",
      "enterprise-nonprofit",
    ],
  },
};
