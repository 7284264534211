module.exports = {
  title: "Higher Education",
  titlePlural: "Higher Education",
  slug: "higher-education",
  astro: "./astros/astro_education_executive_misc.png",
  generic: [
    {
      eyebrow: "For Higher Education Institutions",
      headline: "The Total Economic Impact™ of Salesforce.org Education Cloud",
      images: {
        landscape: "./generic/higher-education-landscape.jpg",
        square: "./generic/higher-education-square.jpg",
      },
      body: "Looking to prove the value of your education technology investments? Dive into a Forrester Consulting study, which highlights how higher education institutions are achieving a strong ROI with Salesforce.org Education Cloud.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/sfdo/edu/total-economic-impact-education-cloud/",
    },
    {
      eyebrow: "For Institutions of All Sizes",
      headline: "The Higher Ed Trailblazer’s Guide to Salesforce",
      images: {
        landscape: "./generic/institutions-landscape.jpg",
        square: "./generic/institutions-square.jpg",
      },
      body: "Explore the Salesforce Education Cloud with this quick start guide to success.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/sfdo/edu/higher-edu-trailblazers-guide/",
    },
  ],
};
