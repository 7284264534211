import React from "react";
import { AnswerContext } from "../../context/answer-context";

function StepCounter({ ...props }) {
  const getStepPercentage = (state) => {
    const percentage =
      (((state.industryType ? 1 : 0) +
        (state.roleType && !state.roleType.default ? 1 : 0) +
        (state.businessType && !state.businessType.default ? 1 : 0) +
        (state.priorityChoice ? 1 : 0)) *
        100) /
      (state.maxSteps - (state.skippedQuestion ? 1 : 0));
    return Math.trunc(percentage);
  };

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div className="counter">
          <p className="counter-title" key={state.stepNum.toString()}>
            {`${getStepPercentage(state)}% Complete`}
          </p>
          <div className="counter-progress">
            <div
              className="counter-progress_bar"
              style={{ width: `${getStepPercentage(state)}%` }}
            ></div>
          </div>
        </div>
      )}
    </AnswerContext.Consumer>
  );
}

export default StepCounter;
