import React from "react";

function QuestionTitle({ ...props }) {
  const { title, subTitle, isPhone } = props;
  return (
    <div className="question__title-wrap">
      <h1 className="question__title">{title}</h1>
      {!isPhone && <p className="question__subtitle">{subTitle}</p>}
    </div>
  );
}

export default QuestionTitle;
