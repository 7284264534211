import React, { useEffect, useContext } from "react";
import IntroHero from "./IntroHero";
import IntroWheel from "./IntroWheel";
import Chart from "../Recommendations/Chart";
import FadePage from "../Global/FadePage";
import { ANALYTICS } from "../../utils/constants";
import { removeChoice } from "../../utils/functions";
import TagManager from "react-gtm-module";
import { GlobalContext } from "../../context/answer-context";
const appStrings = require("../../assets/data/strings");

const handleAnalyticsData = (title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventTracker",
      eventCat: ANALYTICS.CATEGORY.GETSTARTED,
      eventAct: ANALYTICS.ACTION.GETSTARTED,
      eventLbl: title,
    },
  });
};

function Intro() {
  const { intro: strings } = appStrings;
  const { hasModeEventsInParams, searchParams } = useContext(GlobalContext);

  useEffect(() => {
    //if there are any preexisting user questionnaire choices stored in session storage, remove them at the start of the experience
    removeChoice();
  }, []);

  return (
    <FadePage show={true}>
      <div className="intro">
        <div className="intro__top-content">
          <div className={`intro__hero`}>
            <div className={`intro__hero-container sf-col-lg-20}`}>
              <IntroHero
                strings={strings.hero}
                handleAnalyticsData={handleAnalyticsData}
              />
            </div>
          </div>
          {!hasModeEventsInParams() ? (
            <>
              <Chart strings={strings.wheel} subtitle={true} />
            </>
          ) : null}
        </div>
      </div>
    </FadePage>
  );
}

export default Intro;
