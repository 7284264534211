import React from "react";
import { PILL, TIMING, GRID, VIEWMORE } from "../../utils/constants";

function BasicPillButton({ show = true, ...props }) {
  const {
    title,
    onChange,
    onKeyPress,
    name,
    type,
    desc,
    pillNum,
    Icon,
    selected,
  } = props;

  const gridTypeMap = {
    SMALL: "4",
    MEDIUM: "5",
    LARGE: "6",
    LONG: "9",
  };

  const gridTypeTabletMap = {
    SMALL: "9",
    MEDIUM: "9",
    LARGE: "9",
    LONG: "20",
  };

  return (
    <div
      className={`pill__wrap pill__wrap--${name} ${PILL.CLASSES[type]} sf-col-xs-20 sf-col-md-${gridTypeTabletMap[type]} sf-col-lg-${gridTypeMap[type]}`}
      style={{
        maxWidth: (gridTypeMap[type] / GRID.COLS) * 100 + "%",
      }}
    >
      <input
        name={name}
        id={title}
        className="pill__radio"
        type="radio"
        role={title === VIEWMORE.LABEL ? "button" : "radio"}
        onChange={onChange}
        onKeyPress={onKeyPress}
        required={title === VIEWMORE.LABEL ? false : true}
      />
      {show ? (
        <label
          className={
            "pill__label sf-row sf-row-xs--center sf-row-xs--middle" +
            (selected ? " pill__label_selected" : "")
          }
          htmlFor={title}
          style={{
            animationName: `buttonAppear`,
            animationDelay: `${
              TIMING.TIMING_250 + TIMING.TIMING_50 * pillNum
            }ms`,
          }}
        >
          <div className="pill__label-content" role="button">
            {title}
            {desc && <span className="pill__desc">{desc}</span>}
            {Icon && <Icon title={title} className="pill__icon" />}
          </div>
        </label>
      ) : null}
    </div>
  );
}

const LongPillButton = (props) => BasicPillButton(props);
const SmallPillButton = (props) => BasicPillButton(props);
const MediumPillButton = (props) => BasicPillButton(props);
const LargePillButton = (props) => BasicPillButton(props);

export const PillButton = {
  LONG: LongPillButton,
  SMALL: SmallPillButton,
  MEDIUM: MediumPillButton,
  LARGE: LargePillButton,
};

export default PillButton;
