module.exports = {
  title: "Service",
  slug: "service",
  astro: "./astros/astro_service.png",
  emailTemplate: "Service",
  generic: [
    {
      eyebrow: "For Service Teams",
      headline: "Provide Fast, Scalable Service from Anywhere",
      images: {
        landscape: "./generic/service-landscape.jpg",
        square: "./generic/service-square.jpg",
      },
      body: "Support customers on any channel and resolve cases faster from anywhere with a complete service solution that's built for change and resilience.",
      cta: "Learn More",
      ctaLink: "https://www.salesforce.com/products/service-cloud/overview/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Case Resolution via AI",
        multi: 30,
        single: 87,
      },
      {
        title:
          "Increase in Customer Satisfaction, Customer Effort, or Net Promoter Score",
        multi: 32,
        single: 51,
      },
      {
        title: "Increase in Customer Retention",
        multi: 30,
        single: 85,
      },
      {
        title: "Increase in Agent Productivity",
        multi: 30,
        single: 89,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/service2.png",
      headline: "Learn how much you can save with Service Cloud.",
      cta: "Try the ROI calculator",
      link: "https://www.salesforce.com/form/service-cloud/service-roi-calculator/",
    },
    {
      image: "./offers/service3.png",
      headline: "Solve cases faster on the #1 platform for service.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/demo/customer-service-demo/?d=pb",
    },
  ],
};
