import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import { Transition } from "react-transition-group";
import {
  LOADING,
  BREAKPOINTS,
  TIMING,
  ANIM_CURVES,
} from "../../utils/constants";

const Image = ({ ...props }) => {
  const {
    desktopSrc,
    tabletSrc,
    mobileSrc,
    alt = "",
    role = "",
    classes, //additional classes you want to apply to the image tag
    fadeDuration = TIMING.TIMING_250, //how long the fade should take
    easing = ANIM_CURVES.EASE_OUT, //easing curve used for fade
    cb, //any callback you want to pass to parent when image is lazy loaded
    noLazy, //disable lazy loading / animation
    toTranslate = false,
    ...restProps //any other props that need to be optionally passed to lazyload
  } = props;
  const images = require.context("../../assets/images", true);

  const getStyle = () => ({
    transition: `opacity ${fadeDuration}ms ${easing}, transform ${fadeDuration}ms ${easing}`,
    opacity: 0,
  });

  const transitionStyles = toTranslate
    ? {
        entering: { opacity: 0, transform: `translateY(32px)` },
        entered: { opacity: 1, transform: "translateY(0)" },
      }
    : {
        entering: { opacity: 0 },
        entered: { opacity: 1 },
      };

  const [loaded, setLoaded] = useState(false);
  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (loaded && cb) {
      cb();
    }
  }, [loaded]);

  return noLazy ? (
    <picture onLoad={onLoad}>
      {tabletSrc && (
        <source
          srcSet={images(desktopSrc)}
          media={`(min-width: ${BREAKPOINTS.LG}px)`}
        />
      )}
      <source
        srcSet={images(tabletSrc ? tabletSrc : desktopSrc)}
        media={`(min-width: ${BREAKPOINTS.MED}px)`}
      />
      <img
        className={`image ${classes ? classes : ""}`}
        src={images(mobileSrc)}
        alt={alt ? alt : ""}
        role={role ? role : ""}
      />
    </picture>
  ) : (
    <LazyLoad
      height={LOADING.HEIGHT}
      offset={LOADING.OFFSET}
      once
      {...restProps}
    >
      <Transition in={loaded} timeout={TIMING.TIMING_500}>
        {(state) => (
          <div
            style={{
              ...getStyle({ fadeDuration, easing }),
              ...transitionStyles[state],
            }}
            className="image-fade"
          >
            <picture onLoad={onLoad}>
              {tabletSrc && (
                <source
                  srcSet={images(desktopSrc)}
                  media={`(min-width: ${BREAKPOINTS.LG}px)`}
                />
              )}
              <source
                srcSet={images(tabletSrc ? tabletSrc : desktopSrc)}
                media={`(min-width: ${BREAKPOINTS.MED}px)`}
              />
              <img
                className={`image ${classes ? classes : ""}`}
                src={images(mobileSrc)}
                alt={alt ? alt : ""}
                role={role ? role : ""}
              />
            </picture>
          </div>
        )}
      </Transition>
    </LazyLoad>
  );
};

export default Image;
