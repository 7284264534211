import React from "react";

export const STATE_KEYS = {
  roleType: "roleType",
  industryType: "industryType",
  businessType: "businessType",
  priorityChoice: "priorityChoice",
  recommendedURL: "recommendedURL",
  formURL: "formURL",
  stepNum: "stepNum",
  formID: "formID",
  ctaVisible: "ctaVisible",
  selectAnotherGoal: "selectAnotherGoal",
  skippedQuestion: "skippedQuestion",
  refParameter: "refParameter",
};

export const ALL_CATEGORIES = ["industryType", "businessType", "roleType"];

// Map for prettier URLs
export const CATEGORY_MAP = {
  industryType: "industry",
  businessType: "business",
  roleType: "role",
};

// the tie between user choice value in state and its corresponding stepNum, for use in clearing values
export const STEP_CHOICE_MAP = {
  1: "industryType",
  2: "roleType",
  3: "businessType",
  4: "priorityChoice",
};

// Default options for questions when skipped
export const NON_OPTIONS_TYPE = {
  businessType: {
    title: "default",
    titlePlural: "defaults",
    slug: "business-type",
    default: true,
  },
};

export const AnswerContext = React.createContext({
  setState: () => {},
  clearState: () => {},
});

export const GlobalContext = React.createContext({});
