import React, { useEffect, useState } from "react";
import astroBush from "../../assets/images/astro_bush.png";
import astroYay from "../../assets/images/astro_yay.png";
import astroHiking from "../../assets/images/astro_cane.png";
import astroGliding from "../../assets/images/astro_hang_gliding.png";
import Q1_Front_Right from "../../assets/images/backgrounds/Q1_Front_Right.png";
import Q1_Back_Right from "../../assets/images/backgrounds/Q1_Back_Right.png";
import Q1_Front_Left from "../../assets/images/backgrounds/Q1_Front_Left.png";
import Q1_Back_Left from "../../assets/images/backgrounds/Q1_Back_Left.png";
import Q2_Front_Right from "../../assets/images/backgrounds/Q2_Front_Right.png";
import Q2_Back_Right from "../../assets/images/backgrounds/Q2_Back_Right.png";
import Q2_Front_Left from "../../assets/images/backgrounds/Q2_Front_Left.png";
import Q2_Back_Left from "../../assets/images/backgrounds/Q2_Back_Left.png";
import Q3_Front_Right from "../../assets/images/backgrounds/Q3_Front_Right.png";
import Q3_Back_Right from "../../assets/images/backgrounds/Q3_Back_Right.png";
import Q3_Front_Left from "../../assets/images/backgrounds/Q3_Front_Left.png";
import Q3_Back_Left from "../../assets/images/backgrounds/Q3_Back_Left.png";
import Q4_Front_Right from "../../assets/images/backgrounds/Q4_Front_Right.png";
import Q4_Back_Right from "../../assets/images/backgrounds/Q4_Back_Right.png";
import Q4_Front_Left from "../../assets/images/backgrounds/Q4_Front_Left.png";
import Q4_Back_Left from "../../assets/images/backgrounds/Q4_Back_Left.png";
import parallaxClouds from "../../assets/images/parallax-clouds.svg";
import { CLASSES } from "../../utils/constants";
function BackgroundParallax({ ...props }) {
  const { step, classPrefix, isMobile } = props;

  const [isLoaded, setLoaded] = useState(false);
  const [toMove, setToMove] = useState(false);

  const imageMap = {
    1: {
      astro: astroYay,
      bgLeft: Q1_Back_Left,
      fgLeft: Q1_Front_Left,
      bgRight: Q1_Back_Right,
      fgRight: Q1_Front_Right,
      clouds: parallaxClouds,
    },
    2: {
      astro: astroHiking,
      bgLeft: Q2_Back_Left,
      fgLeft: Q2_Front_Left,
      bgRight: Q2_Back_Right,
      fgRight: Q2_Front_Right,
      clouds: parallaxClouds,
    },
    3: {
      astro: astroGliding,
      bgLeft: Q3_Back_Left,
      fgLeft: Q3_Front_Left,
      bgRight: Q3_Back_Right,
      fgRight: Q3_Front_Right,
      clouds: parallaxClouds,
    },
    4: {
      astro: astroBush,
      bgLeft: Q4_Back_Left,
      fgLeft: Q4_Front_Left,
      bgRight: Q4_Back_Right,
      fgRight: Q4_Front_Right,
      clouds: parallaxClouds,
    },
  };

  useEffect(() => {
    if (!isMobile) {
      setLoaded(true);
      setToMove(true);
    }
  }, [isMobile]);

  return (
    <>
      <div
        className={`${classPrefix}__background-container background__container background__container--parallax`}
      >
        {imageMap[step] && imageMap !== null && (
          <>
            <img
              src={imageMap[step].bgLeft}
              alt=""
              className={`background__bg ${CLASSES.LEFT} ${
                toMove ? `${CLASSES.MOVE} ${CLASSES.DELAY}` : ""
              }`}
            />
            <img
              src={imageMap[step].fgLeft}
              alt=""
              className={`background__fg ${CLASSES.LEFT} ${
                toMove ? CLASSES.MOVE : ""
              }`}
            />
            <div
              className={`background__clouds-container ${
                isLoaded ? CLASSES.ACTIVE : ""
              }`}
            >
              <img
                src={imageMap[step].clouds}
                alt=""
                className="background__clouds"
              />
            </div>
            <img
              src={imageMap[step].bgRight}
              alt=""
              className={`background__bg ${CLASSES.RIGHT} ${
                toMove ? `${CLASSES.MOVE} ${CLASSES.DELAY}` : ""
              }`}
            />
            <img
              src={imageMap[step].fgRight}
              alt=""
              className={`background__fg ${CLASSES.RIGHT} ${
                toMove ? CLASSES.MOVE : ""
              }`}
            />
            <img
              src={imageMap[step].astro}
              alt=""
              className={`background__astro ${toMove ? CLASSES.MOVE : ""}`}
            />
          </>
        )}
      </div>
    </>
  );
}

export default BackgroundParallax;
