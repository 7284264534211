import React from "react";

function GradientCircle({ ...props }) {
  // options are: right, left, large, xl
  const { option, classes } = props;
  return (
    <div
      className={`gradient gradient--${option} ${classes ? classes : ""}`}
    ></div>
  );
}

export default GradientCircle;
