const strings = require("./data/strings");
const roleType = require("./data/roles");
const businessType = require("./data/businessType");
const industryType = require("./data/industries");
const priorities = require("./data/priorities");

module.exports = {
  strings,
  businessType,
  roleType,
  industryType,
  priorities,
};
