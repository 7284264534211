module.exports = {
  title: "Commerce",
  slug: "commerce",
  astro: "./astros/astro_sales_commerce.png",
  emailTemplate: "Commerce",
  generic: [
    {
      eyebrow: "For Commerce Teams",
      headline: "Build Meaningful Customer Relationships, One Order at a Time",
      images: {
        landscape: "./generic/commerce-landscape.jpg",
        square: "./generic/commerce-square.jpg",
      },
      body: "Grow your brand faster with customer-first ecommerce built on the world’s #1 CRM.",
      cta: "Learn More",
      ctaLink: "https://www.salesforce.com/products/commerce-cloud/overview/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Online Revenue",
        multi: 29,
        single: 85,
      },
      {
        title: "Decrease in Costs of Placing Orders over Legacy Channels",
        multi: 26,
        single: 64,
      },
      {
        title: "Faster Time to Execute New Strategies ",
        multi: 29,
        single: 58,
      },
      {
        title: "Increase in Gross Merchandise Value (GMV)",
        multi: 28,
        single: 89,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/commerce2.webp",
      headline: "See how AI can grow your commerce business.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/commerce/how-to-jumpstart-growth-in-commerce/",
    },
    {
      image: "./offers/commerce3.webp",
      headline: "Power growth everywhere with Commerce Cloud.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/commerce/commerce-cloud-overview-demo/?d=pb1",
    },
  ],
};
