import React, { useEffect } from "react";
import { VIDYARD_TYPES } from "../../utils/constants";
import VidyardEmbed from "@vidyard/embed-code";

const VidyardVideo = ({ ...props }) => {
  const { videoId, classes, type, forceSixteenNine } = props;

  useEffect(() => {
    VidyardEmbed.api.renderDOMPlayers();
  }, []);

  return (
    <div
      className={`vidyard-video-embed-wrap ${
        forceSixteenNine ? "vidyard-video-embed--force-sixteen-nine" : ""
      }`}
    >
      <img
        className={`vidyard-player-embed ${classes}`}
        src={`https://play.vidyard.com/${videoId}.jpg`}
        data-uuid={videoId}
        data-v="4"
        data-type={type ? type : VIDYARD_TYPES.INLINE} // inline or lightbox
        alt=""
        tabIndex="0"
      />
    </div>
  );
};

export default VidyardVideo;
