const industriesPath = require("./industriesPath");

module.exports = {
  title: "Manufacturing & Automotive",
  slug: "manufacturing-automotive",
  astro: "./astros/astro_manufacturing.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Manufacturing Leaders",
      headline: "Create Connected Experiences for Every Part of Your Ecosystem",
      images: {
        landscape: "./generic/manufacturing-landscape.jpg",
        square: "./generic/manufacturing-square.jpg",
      },
      body: "Deliver amazing experiences to customers, dealers, distributors, and field agents.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/manufacturing/overview/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/manufacturing2.png",
      headline:
        "Integrate sales and revenue management to maximize revenue at lower costs.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/guides/modernizing-commercial-operations/?d=cta-body-promo-1988",
    },
    {
      image: "./offers/manufacturing3.png",
      headline: "Elevate seller performance with workflow automation and AI.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/manufacturing/modernize-commercial-operations-demo/?d=cta-body-promo-1975",
    },
  ],
};
