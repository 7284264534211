import React from "react";
import { CLASSES, TEXT_TYPES } from "../../utils/constants";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-landpage.svg";
import FormattedText from "./FormattedText";

function TextList({ ...props }) {
  const {
    title,
    body,
    arrowsText,
    reverse = false,
    useGrid = true,
    centerMobile = false,
    type = "default",
    isPageHeading = false,
    isContactPageHeading = false,
    classes,
  } = props;

  const getHeaderClass = (type) => {
    switch (type) {
      case TEXT_TYPES.DEFAULT:
        return "-grid sf-col-xs-20 sf-col-md-20";
      case TEXT_TYPES.LARGE:
        return "-grid sf-col-xs-20 sf-col-md-14";
      case TEXT_TYPES.LEFT:
        return "-grid sf-col-xs-20 sf-col-md-6";
      case TEXT_TYPES.BODY:
        return "-grid sf-col-xs-18 sf-col-md-10";
      default:
        return "-grid sf-col-xs-20 sf-col-md-8";
    }
  };

  const getBodyClass = (type) => {
    switch (type) {
      case TEXT_TYPES.DEFAULT:
        return "-grid sf-col-xs-20 sf-col-md-8";
      case TEXT_TYPES.LARGE:
        return "-grid sf-col-xs-20 sf-col-md-10";
      case TEXT_TYPES.LEFT:
        return "-grid sf-col-xs-20 sf-col-md-6";
      default:
        return "-grid sf-col-xs-20 sf-col-md-8";
    }
  };

  const HeadingType =
    isContactPageHeading === true
      ? isPageHeading
        ? "h2"
        : "h3"
      : isPageHeading
      ? "h1"
      : "h2";

  const reverseClass = reverse ? CLASSES.REVERSE : "";
  const typeClass = type === TEXT_TYPES.LEFT ? CLASSES.LEFT : "";

  return (
    <div
      className={`text ${reverseClass} ${typeClass} ${classes ? classes : ""} ${
        centerMobile ? CLASSES.CENTER_MOBILE : ""
      } `}
    >
      <HeadingType
        className={`text__title ${useGrid ? getHeaderClass(type) : ""}`}
      >
        <FormattedText
          text={title}
          superset="™"
          superset2="®"
          keepTogether="F1® Grand Prix"
        ></FormattedText>
      </HeadingType>
      {body && (
        <p className={`text__body hero ${useGrid ? getBodyClass(type) : ""}`}>
          {body}
        </p>
      )}
      <div className="text__list-container">
        {arrowsText &&
          arrowsText.map((line, index) => (
            <div key={`intro-list-item-${index}`} className="text__list-item">
              <div>
                <Arrow className="text__arrow" aria-hidden={true} />
              </div>
              <p className="text__list">{line}</p>
            </div>
          ))}
      </div>
    </div>
  );
}
export default TextList;
