const industriesPath = require("./industriesPath");

module.exports = {
  title: "Financial Services",
  slug: "financial-services",
  astro: "./astros/astro_financial.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Financial Services",
      headline: "Start your free trial of our financial services solution.",
      images: {
        landscape: "./generic/financial-services-landscape.jpg",
        square: "./generic/financial-services-square.jpg",
      },
      body: "Financial Services Cloud enable bans, wealth management firms and insurance companies to speed time-to-value, improve productivity and streamline operational compliance.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/signup/financial-services-cloud-trial/?d=cta-conf-1",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Support cost reduction",
        multi: 29,
        single: 29,
      },
      {
        title: "Expect their needs to be anticipated",
        multi: 62,
        single: 62,
      },
      {
        title: "Increase in customer satisfaction",
        multi: 28,
        single: 28,
      },
      {
        title: "Decrease in maintenance costs",
        multi: 50,
        single: 50,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/financial2.png",
      headline: "Learn about the future of Financial Services.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/financial-services-report/?d=cta-body-promo-2073",
    },
    {
      image: "./offers/financial3.png",
      headline:
        "Empower your customers’ financial success with Data + AI + CRM + Trust.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/financial-services/demo/demo-sf-for-financial-services/?d=cta-header-1916",
    },
  ],
};
