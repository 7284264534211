const commerce = require("./roles/commerce");
const executive = require("./roles/executive");
const it = require("./roles/it");
const marketing = require("./roles/marketing");
const sales = require("./roles/sales");
const service = require("./roles/service");
const data = require("./roles/data");
// Education roles
const recruitmentAdmissionEnrollment = require("./roles/recruitmentAdmissionEnrollment");
const studentSuccess = require("./roles/studentSuccess");
const advancement = require("./roles/advancement");
const operations = require("./roles/operations");
const marketingEducation = require("./roles/marketingEdu");
// Nonprofit roles
const fundraisingDevelopment = require("./roles/fundraisingDevelopment");
const programManagment = require("./roles/programManagment");
const grantsManagement = require("./roles/grantsManagement");
const marketingNonprofit = require("./roles/marketingNonprofit");
const executiveNonprofit = require("./roles/executiveNonprofit");
// PublicSector roles
const complianceSecurity = require("./roles/complianceSecurity");
const hr = require("./roles/hr");
const itPublicSector = require("./roles/itPublicSector");
const executivePublicSector = require("./roles/executivePublicSector");

module.exports = [
  sales,
  it,
  commerce,
  service,
  marketing,
  executive,
  recruitmentAdmissionEnrollment,
  studentSuccess,
  advancement,
  operations,
  fundraisingDevelopment,
  programManagment,
  grantsManagement,
  complianceSecurity,
  hr,
  marketingEducation,
  marketingNonprofit,
  executiveNonprofit,
  itPublicSector,
  executivePublicSector,
  data,
];
