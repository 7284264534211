import React, { useContext } from "react";
import Image from "../Global/Image";
import { ReactComponent as Quotes } from "../../assets/icons/quotes.svg";
import { CLASSES } from "../../utils/constants";
import { GlobalContext } from "../../context/answer-context";

const Testimonial = ({ ...props }) => {
  const { data, strings, isPhone } = props;
  const { hasModeEventsInParams } = useContext(GlobalContext);

  return (
    <div className={`testimonial ${CLASSES.ACTIVE}`}>
      <div className="sf-row sf-row-xs--center sf-row-md--middle">
        <div className="sf-col-xs-20 sf-col-md-16 sf-col-lg-16 testimonial__box">
          {isPhone ? (
            <div />
          ) : (
            <div className="testimonial__icons">
              <div className="testimonial__fly">
                <Image
                  desktopSrc="./Butterfly1.png"
                  mobileSrc="./Butterfly1.png"
                  alt=""
                />
              </div>
              <div className="testimonial__leaf--desktop">
                <Image
                  desktopSrc="./Leaf_desktop.png"
                  mobileSrc="./Leaf_desktop.png"
                  alt=""
                />
              </div>
            </div>
          )}
          <div className={`sf-row sf-row-xs--middle testimonial__mobile`}>
            {(data.image || data.logo) && (
              <div className="testimonial__image-wrapper">
                {isPhone ? (
                  <div className="testimonial__leaf--mobile">
                    <Image
                      desktopSrc="./Leaf_desktop.png"
                      mobileSrc="./Leaf_mobile.png"
                      alt=""
                    />
                  </div>
                ) : (
                  <div />
                )}
                {data.image ? (
                  <>
                    <Image
                      desktopSrc={`./testimonials/people-head/${data.image}`}
                      mobileSrc={`./testimonials/people-head/${data.image}`}
                      classes="testimonial__image"
                      alt={`${strings.portraitOf} ${data.attributionName}, ${data.attributionTitle}`}
                    />
                    <div className="testimonial__logo-container sf-row sf-row-xs--middle sf-row-xs--center">
                      <Image
                        desktopSrc={`./testimonials/logos/${data.logo}`}
                        mobileSrc={`./testimonials/logos/${data.logo}`}
                        classes="testimonial__logo-image"
                        alt={data.attributionCompany}
                      />
                    </div>
                  </>
                ) : (
                  <div className="testimonial__logo-container testimonial__logo-container__main sf-row sf-row-xs--middle sf-row-xs--center">
                    <Image
                      desktopSrc={`./testimonials/logos/${data.logo}`}
                      mobileSrc={`./testimonials/logos/${data.logo}`}
                      classes="testimonial__logo-image"
                      alt={data.attributionCompany}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="sf-col-xs-18 sf-col-md-10 sf-col-lg-12 testimonial__quotation">
              <span className="testimonial__quotes">
                <Quotes />
              </span>
              <blockquote className="testimonial__quote">
                {data.quote}
              </blockquote>
              <p className="testimonial__attribution-name">
                {data.attributionName}
                <span className="testimonial__attribution-title">
                  {data.attributionTitle}
                </span>
              </p>
              {!hasModeEventsInParams() && data.storyLink ? (
                <a
                  href={data.storyLink}
                  className="testimonial__attribution-history"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  {strings.testimonialCustomerStory}
                  <span aria-hidden="true"> ＞</span>
                </a>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
