import React from "react";
import { CLASSES, TEXT_TYPES } from "../../utils/constants";

function TextLockup({ ...props }) {
  const {
    title,
    body,
    subtitle,
    cta,
    ctaLink,
    reverse = false,
    useGrid = true,
    centerMobile = false,
    type = "default",
    isPageHeading = false,
    isContactPageHeading = false,
    classes,
    analytics,
  } = props;

  const getHeaderClass = (type) => {
    switch (type) {
      case TEXT_TYPES.DEFAULT:
        return "-grid sf-col-xs-20 sf-col-md-20";
      case TEXT_TYPES.LARGE:
        return "-grid sf-col-xs-20 sf-col-md-14";
      case TEXT_TYPES.LEFT:
        return "-grid sf-col-xs-20 sf-col-md-6";
      case TEXT_TYPES.BODY:
        return "-grid sf-col-xs-18 sf-col-md-10";
      default:
        return "-grid sf-col-xs-20 sf-col-md-8";
    }
  };

  const getBodyClass = (type) => {
    switch (type) {
      case TEXT_TYPES.DEFAULT:
        return "-grid sf-col-xs-20 sf-col-md-8";
      case TEXT_TYPES.LARGE:
        return "-grid sf-col-xs-20 sf-col-md-10";
      case TEXT_TYPES.LEFT:
        return "-grid sf-col-xs-20 sf-col-md-6";
      default:
        return "-grid sf-col-xs-20 sf-col-md-8";
    }
  };

  const HeadingType =
    isContactPageHeading === true
      ? isPageHeading
        ? "h2"
        : "h3"
      : isPageHeading
      ? "h1"
      : "h2";

  const reverseClass = reverse ? CLASSES.REVERSE : "";
  const typeClass = type === TEXT_TYPES.LEFT ? CLASSES.LEFT : "";

  return (
    <div
      className={`${
        subtitle ? "text__sub" : "text"
      } ${reverseClass} ${typeClass} ${classes ? classes : ""} ${
        centerMobile ? CLASSES.CENTER_MOBILE : ""
      } `}
    >
      <HeadingType
        className={`text__title ${subtitle ? "extra" : ""} ${
          useGrid ? getHeaderClass(type) : ""
        }`}
      >
        {title}
      </HeadingType>
      {body && (
        <p className={`text__body ${useGrid ? getBodyClass(type) : ""}`}>
          {body}
        </p>
      )}
      {cta && ctaLink && (
        <div
          className={`text__cta-container ${useGrid ? getBodyClass(type) : ""}`}
        >
          <a
            className="text__cta"
            href={ctaLink}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            rel="noopener"
            onClick={analytics}
            aria-label={`${cta} - ${title}`}
          >
            {cta}
          </a>
        </div>
      )}
    </div>
  );
}
export default TextLockup;
