import React from "react";
import Image from "./Image";

function Background({ ...props }) {
  const { step = "default", full = false, cb = null } = props;
  //step allows us to select the right background from the image map
  //full allows us to use full screen backgrounds, i.e. when a tracker is not present
  //cb = callback when images are loaded

  const imageMap = {
    1: {
      tablet: "./bg-waterfall-tablet.jpg",
      mobile: "./bg-waterfall-mobile.jpg",
    },
    2: {
      tablet: "./bg-valley-tablet.jpg",
      mobile: "./bg-valley-mobile.jpg",
    },
    3: {
      tablet: "./bg-lake-tablet.jpg",
      mobile: "./bg-lake-mobile.jpg",
    },
    4: {
      tablet: "./bg-hills-tablet.jpg",
      mobile: "./bg-hills-mobile.jpg",
    },
    // 404 page
    404: {
      desktop: "./bg-notfound-desktop.jpg",
      tablet: "./bg-notfound-tablet.jpg",
      mobile: "./bg-notfound-mobile.jpg",
    },
    timeline: {
      desktop: "./bg-timeline.jpg",
      tablet: "./bg-timeline-tablet.jpg",
      mobile: "./bg-timeline-mobile.jpg",
    },
    default: {
      tablet: "./bg-valley.jpg",
      mobile: "./bg-valley-mobile.jpg",
    },
  };
  return (
    <div
      className={`background__container background__container--static ${
        full ? "--full" : ""
      }`}
    >
      <Image
        desktopSrc={
          imageMap[step].desktop
            ? imageMap[step].desktop
            : imageMap[step].tablet
        }
        tabletSrc={imageMap[step].tablet}
        mobileSrc={imageMap[step].mobile}
        cb={cb}
      />
    </div>
  );
}

export default Background;
