import analyticsIcon from "./icons/product-analytics.svg";
import commerceIcon from "./icons/product-commerce.svg";
import dataIcon from "./icons/product-data.svg";
import partnersIcon from "./icons/product-partners.svg";
import successIcon from "./icons/product-success.svg";
import industriesIcon from "./icons/product-industries.svg";
import integrationIcon from "./icons/product-integration.svg";
import marketingIcon from "./icons/product-marketing.svg";
import salesIcon from "./icons/product-sales.svg";
import serviceIcon from "./icons/product-service.svg";
import slackIcon from "./icons/product-slack.svg";
import sustainabilityIcon from "./icons/product-sustainability.svg";
import healthIcon from "./icons/product-health.svg";

const products = [
  {
    wheel: true,
    wheelOrder: 1,
    slug: "sales",
    name: "Sales",
    highlights: ["Sales"],
    icon: salesIcon,
    description:
      "Start with Sales Cloud to connect your teams, close more deals, and streamline your sales process.",
    followingDescription:
      "Add Sales Cloud to connect your teams, close more deals, and streamline your sales process.",
    learnMore: "https://www.salesforce.com/products/sales/",
    cellphoneScreen: "./products/screens/sales.png",
    popupElements: [{ src: "./products/popups/sales.png", side: "right" }],
    color: "#04E1CB",
  },
  {
    wheel: true,
    wheelOrder: 2,
    slug: "service",
    name: "Service",
    highlights: ["Service"],
    icon: serviceIcon,
    description:
      "Incorporate easy-to-use support tools, powered by AI, to manage customer support cases faster across every channel.",
    followingDescription:
      "Incorporate easy-to-use support tools, powered by AI, to manage customer support cases faster across every channel.",
    learnMore: "https://www.salesforce.com/products/service/",
    cellphoneScreen: "./products/screens/service.png",
    popupElements: [{ src: "./products/popups/service.png", side: "right" }],
    color: "#E26E8C",
  },
  {
    wheel: true,
    wheelOrder: 3,
    slug: "marketing",
    name: "Marketing",
    highlights: ["Marketing"],
    icon: marketingIcon,
    description:
      "Start with Marketing Cloud so teams can build, optimize, and personalize campaigns and customer journeys.",
    followingDescription:
      "Connect Marketing Cloud so teams can build, optimize, and personalize campaigns and customer journeys.",
    learnMore: "https://www.salesforce.com/products/marketing-cloud/overview/",
    cellphoneScreen: "./products/screens/marketing.png",
    popupElements: [{ src: "./products/popups/marketing.png", side: "right" }],
    color: "#FE9339",
  },
  {
    wheel: true,
    wheelOrder: 4,
    slug: "commerce",
    name: "Commerce",
    highlights: ["Commerce"],
    icon: commerceIcon,
    description:
      "Start with Salesforce B2C and B2B Commerce to engage buyers online and in-store.",
    followingDescription:
      "Integrate Salesforce B2C and B2B Commerce to engage buyers online and in-store.",
    learnMore: "https://www.salesforce.com/products/commerce-cloud/overview/",
    cellphoneScreen: "./products/screens/commerce.png",
    popupElements: [{ src: "./products/popups/commerce.png", side: "right" }],
    color: "#70BF75",
  },
  {
    wheel: true,
    wheelOrder: 5,
    slug: "tableau",
    name: "Tableau",
    highlights: ["Tableau"],
    icon: analyticsIcon,
    description:
      "Start with Analytics Cloud to give every team access to powerful insights from any data — anytime, anywhere.",
    followingDescription:
      "Give every team access to powerful insights from any data — anytime, anywhere.",
    learnMore: "https://www.salesforce.com/products/analytics/overview/",
    cellphoneScreen: "./products/screens/analytics.png",
    popupElements: [{ src: "./products/popups/analytics.png", side: "right" }],
    color: "#FF784F",
  },
  {
    wheel: true,
    wheelOrder: 6,
    slug: "mulesoft",
    name: "MuleSoft",
    highlights: ["MuleSoft"],
    icon: integrationIcon,
    description:
      "Start with MuleSoft to connect any app, data, or device — in the cloud or on-premises.",
    followingDescription:
      "Strengthen your customer profiles and connect any app, data, or device — in the cloud or on-premises.",
    learnMore: "https://www.salesforce.com/products/integration/overview/",
    cellphoneScreen: "./products/screens/integration.png",
    popupElements: [
      { src: "./products/popups/integration1.png", side: "left" },
      { src: "./products/popups/integration2.png", side: "right" },
    ],
    color: "#0B5CAB",
  },
  {
    wheel: true,
    wheelOrder: 7,
    slug: "data",
    name: "Data Cloud",
    highlights: ["Data"],
    icon: dataIcon,
    description:
      "Start with the Salesforce Platform to build and integrate apps that connect your customers and employees.",
    followingDescription:
      "Empower teams to engage customers, at every touchpoint, with relevant insights and contextual data in the flow of work.",
    learnMore: "https://www.salesforce.com/products/platform/overview/",
    cellphoneScreen: "./products/screens/data.png",
    popupElements: [{ src: "./products/popups/data1.png", side: "right" }],
    color: "#8A8ED1",
  },
  {
    wheel: true,
    wheelOrder: 10,
    slug: "industries",
    name: "Industries",
    highlights: ["Industries"],
    icon: industriesIcon,
    description:
      "Start with Salesforce industry clouds to incorporate custom-built solutions for every team, customer, and industry.",
    followingDescription:
      "Find custom-built solutions to meet the needs of your industry.",
    learnMore: "https://www.salesforce.com/solutions/industries/",
    cellphoneScreen: "./products/screens/industries.png",
    popupElements: [{ src: "./products/popups/industries.png", side: "right" }],
    color: "#730394",
  },
  {
    wheel: false,
    slug: "safety",
    name: "Safety",
    highlights: ["Safety"],
    icon: healthIcon,
    description:
      "Start with Safety Cloud to bring customers and employees together, safely.",
    followingDescription: "Bring customers and employees together, safely.",
    learnMore: "https://www.salesforce.com/products/safety-cloud/overview/",
    cellphoneScreen: "./products/screens/safety.png",
    popupElements: [{ src: "./products/popups/safety.png", side: "right" }],
    color: "#0176D3",
  },
  {
    wheel: true,
    wheelOrder: 9,
    slug: "netzero",
    name: "Net Zero",
    highlights: ["Net Zero"],
    icon: sustainabilityIcon,
    description:
      "Start with Net Zero Cloud to create sustainable business value with trusted analytics to achieve carbon neutrality.",
    followingDescription:
      "Create sustainable business value with trusted analytics to achieve carbon neutrality.",
    learnMore: "https://www.salesforce.com/products/net-zero/overview/",
    cellphoneScreen: "./products/screens/sustainability.png",
    popupElements: [
      { src: "./products/popups/sustainability.png", side: "right" },
    ],
    color: "#91DB8B",
  },
  {
    wheel: true,
    wheelOrder: 11,
    slug: "partners",
    name: "Partners",
    highlights: ["Partners"],
    icon: partnersIcon,
    description: "",
    followingDescription:
      "Provide your teams with additional insights, expertise, and solutions from a robust partner ecosystem.",
    learnMore: "https://www.salesforce.com/partners/",
    cellphoneScreen: "./products/screens/partners.png",
    popupElements: [{ src: "./products/popups/partners.png", side: "right" }],
    color: "#FCC003",
  },
  {
    wheel: true,
    wheelOrder: 12,
    slug: "success",
    name: "Customer Success",
    highlights: ["Success"],
    icon: successIcon,
    description: "",
    followingDescription:
      "Maximize ROI with our ecosystem of learning, support, and guidance.",
    learnMore: "https://www.salesforce.com/services/overview/",
    cellphoneScreen: "./products/screens/success.png",
    popupElements: [{ src: "./products/popups/success.png", side: "right" }],
    color: "#0D9DDA",
  },
  {
    wheel: true,
    wheelOrder: 8,
    slug: "slack",
    name: "Slack",
    icon: slackIcon,
    highlights: ["Slack"],
    description: "",
    followingDescription:
      "Unite your employee, customer, and partner conversations, files, and apps in one place.",
    learnMore: "https://www.salesforce.com/products/slack/overview/",
    cellphoneScreen: "./products/screens/slack.png",
    popupElements: [{ src: "./products/popups/slack.png", side: "right" }],
    color: "#611F69",
  },
];

export default products;
