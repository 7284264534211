module.exports = {
  title: "Executive",
  slug: "executive",
  astro: "./astros/astro_education_executive_misc.png",
  emailTemplate: "Executive",
  generic: [
    {
      eyebrow: "For Executives",
      headline: "Put Employees First with an All-in-One Platform",
      images: {
        landscape: "./generic/executive-landscape.jpg",
        square: "./generic/executive-square.jpg",
      },
      body: "Hybrid work is here. And employees need to be engaged from everywhere. Support their success with intuitive self service, automated processes, and more.",
      cta: "Learn More",
      ctaLink: "https://www.work.com",
    },
    {
      eyebrow: "For Executives",
      headline: "Unlock, Analyze, and Act on All Your Data",
      images: {
        landscape: "./generic/workcom-landscape.jpg",
        square: "./generic/workcom-square.jpg",
      },
      body: "Data and analytics are key to business growth. Solve complex business challenges with a scalable data strategy.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/resources/guides/data-strategy-playbook/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Business User Productivity",
        multi: 29,
        single: 85,
      },
      {
        title: "Increase in Win Rate",
        multi: 27,
        single: 89,
      },
      {
        title: "Increase in Customer Retention",
        multi: 30,
        single: 85,
      },
      {
        title: "Increase in Forecast Accuracy",
        multi: 28,
        single: 89,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/executive2.webp",
      headline: "Unlock, Analyze, and Act on Your Data.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/guides/data-strategy-playbook/",
    },
    {
      image: "./offers/executive3.webp",
      headline:
        "Learn more about the first real-time platform for customer magic.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/demo/data-cloud-demo/?d=pb",
    },
  ],
};
