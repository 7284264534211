import React from "react";
import EventsForm from "./EventsForm";
import Logo from "../../assets/icons/sf-logo.svg";
import { EVENTS as constants } from "../../utils/constants";

const EventsControl = () => {
  const {
    INACTIVITY_TIME_MS,
    SOLUTION_FINDER_IFRAME_TITLE,
    BUTTON_START_OVER,
    BUTTON_SEND_RESULTS,
  } = constants;
  const solutionFinderIframe = React.useRef();
  const sendResultsButton = React.useRef();
  const [showModal, setShowModal] = React.useState(false);
  const [iframeUrl, setIframeUrl] = React.useState(false);
  var timerId;

  // For testing recieving postMessages
  // React.useEffect( () => {
  //   window.addEventListener("message", (event) => {
  //     console.log("Comming from", event.origin, "with",event.data)
  //     // if (event.origin !== "http://example.org:8080")
  //     //   return;

  //     // …
  //   }, false);
  // },[])

  const iframeHasLoaded = () => {
    // addTouchEventToIframe();
    activeOrDeactiveSendButton();
  };

  const addTouchEventToIframe = () => {
    if (detectIfPrimaryInputDeviceIsTouch()) {
      getIframeDOM().body.ontouchstart = refreshTimer;
    } else {
      console.log("Doesn't have touch screen as main input device");
    }
  };

  const detectIfPrimaryInputDeviceIsTouch = () =>
    window.matchMedia("(pointer: coarse)").matches;

  const getIframeDOM = () => {
    let iframeElement = solutionFinderIframe.current;
    let iframeDocument =
      iframeElement.contentWindow || iframeElement.contentDocument;
    if (iframeDocument.document) iframeDocument = iframeDocument.document;
    return iframeDocument;
  };

  const refreshTimer = () => {
    if (iframeUrlIsNotStart()) {
      clearTimer();
      timerId = startTimer();
    }
  };
  const clearTimer = () => clearTimeout(timerId);
  const startTimer = () =>
    setTimeout(() => {
      setIframeUrlToStart();
      setShowModal(false);
    }, INACTIVITY_TIME_MS);

  const activeOrDeactiveSendButton = () => {
    if (iframeUrlIsResultPage()) {
      sendResultsButton.current.disabled = false;
    } else {
      sendResultsButton.current.disabled = true;
    }
  };

  const openFormModal = () => {
    if (iframeUrlIsResultPage()) {
      clearTimer();
      setShowModal(true);
      setIframeUrl(getIframeDOM().location.pathname);
    }
  };

  const closeModal = () => {
    // refreshTimer();
    setShowModal(false);
  };

  const iframeUrlIsNotStart = () => getIframeDOM().location.pathname !== "/";
  const iframeUrlIsResultPage = () =>
    getIframeDOM().location.pathname.startsWith("/result");
  const setIframeUrlToStart = () =>
    (solutionFinderIframe.current.src = "/?mode=events");

  return (
    <main id="mainContent" tabIndex="-1">
      <div className="events__container">
        <img src={Logo} alt="Salesforce Logo" className="events__logo" />
        <iframe
          ref={solutionFinderIframe}
          src="/?redirect=quiz&mode=events"
          title={SOLUTION_FINDER_IFRAME_TITLE}
          onLoad={iframeHasLoaded}
          className="events__iframe"
        ></iframe>
        <div className="events__buttons">
          <button
            className="intro__button-cta button button--start__over"
            onClick={setIframeUrlToStart}
          >
            {BUTTON_START_OVER}
          </button>
          <button
            ref={sendResultsButton}
            className="intro__button-cta button button--send"
            onClick={openFormModal}
          >
            {BUTTON_SEND_RESULTS}
          </button>
        </div>
        <EventsForm
          showModal={showModal}
          url={iframeUrl}
          onClose={closeModal}
        />
      </div>
    </main>
  );
};

export default EventsControl;
