import React, { useEffect, useContext, useState } from "react";
import Products from "./Products";
import Contact from "./Contact";
import Share from "./Share";
import Testimonial from "./Testimonial";
import RecsVideo from "./RecsVideo";
import RecsConditionalModules from "./RecsConditionalModules";
import Wheel from "../Wheel/Wheel";
import Benefits from "./Benefits";
import TextLockup from "../Global/TextLockup";
import Image from "../Global/Image";
import GradientCircle from "../Global/GradientCircle";
import productData from "../../assets/products";
import {
  TEXT_TYPES,
  CIRCLE_TYPES,
  CLASSES,
  ANALYTICS,
} from "../../utils/constants";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import AltPriorities from "./AltPriorities";
import RecsInterstitial from "./RecsInterstitial";
import Chart from "./Chart";
import FadeComponent from "../Global/FadeComponent";
import TagManager from "react-gtm-module";
import { sendQuizResultsData } from "../Events/Events";
import useDevicesBreakpoints from "../hooks/useDevicesBreakpoints";
import useIndicatorButton from "../hooks/useIndicatorButton";
import Footer from "../Global/Footer";
import { GlobalContext } from "../../context/answer-context";
import Offers from "./Offers";
import AIBanner from "./AIBanner";
const appStrings = require("../../assets/data/strings");

const handleAnalyticsData = (title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventTracker",
      eventCat: ANALYTICS.CATEGORY.CONTACTSALES,
      eventAct: ANALYTICS.ACTION.HEROTOUCH,
      eventLbl: `${title}`,
    },
  });
};

function Results({ ...props }) {
  const { data } = props;
  const { hasModeEventsInParams } = useContext(GlobalContext);
  const strings = { ...appStrings.recommendations, ...appStrings.tracker };
  const { searchParams } = useContext(GlobalContext);
  const { isPhone, isMobile } = useDevicesBreakpoints();
  const { generics, astro, offers } = data;
  const { roleParam, solution } = data;
  const {
    productGrid,
    benefitTitle1,
    benefitDesc1,
    benefitTitle2,
    benefitDesc2,
    benefitTitle3,
    benefitDesc3,
    benefitTitle4,
    benefitDesc4,
    formattedFormUrl: formUrl,
    priority,
    description,
    slug,
    video,
  } = solution;

  // Create array of individual benefit arrays
  const structuredBenefits = [
    [benefitTitle1, benefitDesc1],
    [benefitTitle2, benefitDesc2],
    [benefitTitle3, benefitDesc3],
    [benefitTitle4, benefitDesc4],
  ];

  // Empty benefits come through as empty strings
  // So filter out empty strings, and empty arrays
  const benefitsArray = structuredBenefits
    .map((subArr) => subArr.filter(Boolean))
    .filter((arr) => arr.length);

  // Map recommended data to product data
  // To highlight products in the wheel
  const highlights = productData.filter((productItem) => {
    return productGrid.includes(productItem.name);
  });

  // Contact strings
  const contactStrings = { ...strings.contact, ctaLink: formUrl };

  // Handle state of the page after intersitial end
  const [isPageActive, setIsPageActive] = React.useState(false);
  const concludeInterstitial = () => setIsPageActive(true);

  const showButton = useIndicatorButton();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (hasModeEventsInParams()) {
      sendQuizResultsData(roleParam.emailTemplate, document.location.href);
    }
  }, []);

  const [hideGif, setHideGif] = useState(false);
  const [showCarouselIntro, setCarouselIntro] = useState(true);

  useEffect(() => {
    if (isPageActive) {
      setTimeout(() => {
        setHideGif(true);
      }, 4000);
      setTimeout(() => {
        setCarouselIntro(false);
      }, 5000);
    }
  }, [isPageActive]);

  return (
    <div className="app__container intro__app_container">
      <main id="mainContent" tabIndex="-1">
        <div className={`recs ${!isPageActive ? CLASSES.INACTIVE : ""}`}>
          <div className={`recs__hero ${isPageActive ? CLASSES.ACTIVE : ""}`}>
            <div className="sf-row sf-row-xs--center sf-row-xs--between">
              <div className="sf-col-md-22 sf-col-lg-20 sf-container hero-container">
                <div className="recs__hero-content">
                  <div className="recs__intro-container faded-text">
                    <h1 className="text__title -grid">{priority}</h1>
                    {!isMobile ? (
                      <>
                        <p className="text__body -grid">{description}</p>
                        <div className="sf-row sf-row-md--center sf-row-lg--between cta__astro__container">
                          {!hasModeEventsInParams() ? (
                            <div className="sf-col-xs-6">
                              <a
                                className="text__cta text__getintouch"
                                href={formUrl}
                                // eslint-disable-next-line react/jsx-no-target-blank
                                target="_blank"
                                rel="noopener"
                                onClick={() => handleAnalyticsData(priority)}
                              >
                                {strings.lockupSolution.cta}
                              </a>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* <div className="sf-col-lg-1"></div> */}
                  <div className="recs__products-container faded-text">
                    <div
                      className={`carousel_intro-container ${
                        !hideGif ? "active" : "inactive"
                      } ${showCarouselIntro ? "active" : "inactive2"}`}
                    >
                      <Image
                        desktopSrc="./results-hero-wheel.gif"
                        mobileSrc="./results-hero-wheel.gif"
                        classes={`carousel_intro`}
                        alt=""
                      />
                    </div>
                    {!showCarouselIntro ? (
                      <Products
                        grid={productGrid}
                        strings={strings.lockupProducts}
                      />
                    ) : null}
                  </div>
                  {isMobile ? (
                    <div className="recs__descri-container faded-text">
                      <p className="text__body -grid">{description}</p>
                      <div className="sf-row sf-row-md--center sf-row-lg--between cta__astro__container">
                        {!hasModeEventsInParams() ? (
                          <div
                            className="sf-col-xs-6"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              className="text__cta text__getintouch"
                              href={formUrl}
                              // eslint-disable-next-line react/jsx-no-target-blank
                              target="_blank"
                              rel="noopener"
                              onClick={() => handleAnalyticsData(priority)}
                            >
                              {strings.lockupSolution.cta}
                            </a>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={`recs__body ${isPageActive ? CLASSES.ACTIVE : ""}`}>
            <TextLockup
              title={strings.benefitTitle}
              type={TEXT_TYPES.BODY}
              centerMobile={true}
            />
            <Benefits data={benefitsArray} isPhone={isPhone} />
            <div style={{ marginTop: "96px" }}>
              <TextLockup
                title="All sorts of content. All to help you grow."
                type={TEXT_TYPES.LARGE}
                centerMobile={true}
              />
            </div>
            <Offers data={offers} />
            {/* <FadeComponent isPhone={isPhone}>
              <div className="recs__recommended-lockup">
                <TextLockup
                  {...strings.lockupProducts}
                  type={TEXT_TYPES.LARGE}
                  centerMobile={true}
                  classes="recs__recommened"
                />
              </div>
            </FadeComponent> */}
            <GradientCircle option={CIRCLE_TYPES.XL} />
            {/* <Products grid={productGrid} strings={strings.lockupProducts} /> */}
            {solution.testimonial && (
              <Testimonial
                data={solution.testimonial}
                strings={strings}
                isPhone={isPhone}
              />
            )}
            <AIBanner isPhone={isPhone} />
            {!hasModeEventsInParams() && video && (
              <RecsVideo data={video} isPhone={isPhone} />
            )}
          </div>
          <div
            className="chart__background"
            style={hasModeEventsInParams() ? { paddingBottom: "64px" } : {}}
          >
            <Chart
              isPhone={isPhone}
              strings={{ ...strings.roi, ...roleParam.roi }}
              subtitle={false}
            />
          </div>
          {!hasModeEventsInParams() && (
            <div className="recs__contact-section" tabIndex="-1">
              <FadeComponent isPhone={isPhone}>
                <h2 className="recs__title">{contactStrings.nextStep}</h2>
                <div className="sf-row sf-row-xs--center sf-row-xs--start">
                  <Contact strings={contactStrings} />
                  <Share
                    strings={strings.share}
                    title={priority}
                    isPhone={isPhone}
                  />
                </div>
              </FadeComponent>
            </div>
          )}
          {!hasModeEventsInParams() && (
            <AltPriorities
              strings={{
                lockup: strings.lockupAlternatePriority,
                retakeQuiz: strings.retakeQuiz,
                selectGoal: strings.selectGoal,
                businessNeeds: strings.businessNeeds,
                startOver: strings.startOver,
                moreSolutions: strings.moreSolutions,
              }}
              data={data}
              // onRestartClick={() => backToStart(state.clearState)}
              // onRestartClick={() => backToStart()}
              // onSelectGoalClick={() => backToGoal(state)}
              // onSelectGoalClick={() => backToGoal()}
              isPhone={isPhone}
            />
          )}
          {!hasModeEventsInParams() && generics && (
            <FadeComponent isPhone={isPhone}>
              <RecsConditionalModules
                strings
                data={generics}
                title={strings.conditionalModuleTitle}
                subTitle={strings.conditionalModulesubTitle}
                conditionalModuleCta={strings.conditionalModuleCta}
                ctaLink={strings.ctaLink}
                isMobile={isMobile}
              />
            </FadeComponent>
          )}
          {isPageActive && showButton ? (
            <div
              className="text__more"
              style={{
                bottom: "40px",
                fontSize: "20px",
                padding: "10px 30px",
                zIndex: 999999,
              }}
            >
              More
              <Arrow className="text__more-arrow" aria-hidden={true} />
            </div>
          ) : null}
        </div>
        <RecsInterstitial
          loadingText={strings.loadingText}
          solution={slug}
          concludeInterstitial={concludeInterstitial}
        />
      </main>
      {!hasModeEventsInParams() && <Footer display={isPageActive} />}
    </div>
  );
}

export default Results;
