const small = require("./businessType/small");
const midsize = require("./businessType/midsize");
const enterprise = require("./businessType/enterprise");
// For Education
const k12 = require("./businessType/k-12");
const higherEducation = require("./businessType/higherEducation");
// For public sector
const smallNonprofit = require("./businessType/smallNonprofit");
const midsizeNonprofit = require("./businessType/midsizeNonprofit");
const enterpriseNonprofit = require("./businessType/enterpriseNonprofit");

module.exports = [
  small,
  midsize,
  enterprise,
  k12,
  higherEducation,
  smallNonprofit,
  midsizeNonprofit,
  enterpriseNonprofit,
];
