module.exports = {
  title: "Data",
  slug: "data",
  astro: "./astros/astro_education_executive_misc.png",
  emailTemplate: "General",
  generic: [],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Decrease For Service in Average Case Age",
        multi: 33,
        single: 89,
      },
      {
        title: "Increase for Sales in Close Rate",
        multi: 26,
        single: 85,
      },
      {
        title: "Increase in Revenue For Everyone",
        multi: 29,
        single: 89,
      },
      {
        title: "Faster time to business insights",
        multi: 29,
        single: 85,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/data2.webp",
      headline: "Unlock, Analyze, and Act on Your Data.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/guides/data-strategy-playbook/",
    },
    {
      image: "./offers/data3.webp",
      headline:
        "Learn more about the first real-time platform for customer magic.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/demo/data-cloud-demo/?d=pb",
    },
  ],
};
