import React, { useState, useRef, useContext } from "react";
import Image from "../Global/Image";
import TextList from "../Global/TextList";
import { TEXT_TYPES, CLASSES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/answer-context";
import FormattedText from "../Global/FormattedText";

function IntroHero({ ...props }) {
  const { strings, handleAnalyticsData } = props;
  const [introImagesLoaded, setIntroImagesLoaded] = useState(false);
  const loadedIntroImages = useRef(0);
  const navigate = useNavigate();
  const { searchParamsToString } = useContext(GlobalContext);

  const handleIntroImageLoad = () => {
    loadedIntroImages.current++;
    if (loadedIntroImages.current === 4) {
      setIntroImagesLoaded(true);
    }
  };

  return (
    <div
      className={`sf-row sf-row-xs--center intro-hero formula1 sf-row-lg--start"`}
    >
      <div
        className={`sf-col-xs-20 sf-col-t-20 sf-col-md-10 intro__hero-textcontainer`}
      >
        <TextList
          title={strings.title}
          body={strings.body}
          arrowsText={strings.arrowsText}
          eyebrowImage="./logo_customer360.svg"
          eyebrowImageAlt={strings.eyebrowAlt}
          type={TEXT_TYPES.LARGE}
          useGrid={false}
          classes="intro__text-content"
          centerMobile
          isPageHeading={true}
        />
        <button
          className="intro__button-cta mobile"
          onClick={() => {
            handleAnalyticsData("Home Hero");
            navigate("/quiz/1" + searchParamsToString());
          }}
          role="link"
        >
          {strings.cta}
        </button>
      </div>
      <div className="sf-col-md-2" />
      <div
        className={`intro__hero-img-wrap sf-col-xs-20 sf-col-md-8 ${
          introImagesLoaded ? CLASSES.ACTIVE : ""
        }`}
      >
        <div
          className={`intro__hero-img intro__hero-img--people ${
            introImagesLoaded ? CLASSES.ACTIVE : ""
          }`}
        >
          <Image
            role="presentation"
            desktopSrc="./intro-hero-wheel.png"
            mobileSrc="./intro-hero-wheel.png"
            alt="intro-hero"
            noLazy
            cb={handleIntroImageLoad}
          />
        </div>
      </div>
      <div className="sf-col-md-2" />
    </div>
  );
}

export default IntroHero;
