import React, { useEffect } from "react";
const useEvents = () => {
  const [isActive, setIsActive] = React.useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch("/api/events");
        const resJson = await res.json();
        setIsActive(resJson.isActive);
      } catch (error) {
        console.log(error);
        setIsActive(false);
      }
    }
    fetchData();
  }, []);
  return isActive;
};

export default useEvents;
