const industriesPath = require("./industriesPath");

module.exports = {
  title: "Education",
  slug: "education",
  astro: "./astros/astro_education_executive_misc.png",
  roles: industriesPath.roles.education,
  businessType: industriesPath.businessType.education,
  generic: [],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/education2.png",
      headline: "Uncover the benefits and ROI of Salesforce for Education.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/sfdo/edu/forrester-tei-edu-2023/",
    },
    {
      image: "./offers/education3.png",
      headline:
        "Explore global insights from students and staff in our latest trends report.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/sfdo/edu/connected-student-report-third-edition/?icid=SFORG:connected-student-report-hero:",
    },
  ],
};
