const industriesPath = require("./industriesPath");

module.exports = {
  title: "Media",
  slug: "media",
  astro: "./astros/astro_comms_media_transportation_hospitality.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Media Leaders",
      headline: "Learn How to Win the War for Attention",
      images: {
        landscape: "./generic/media-landscape.jpg",
        square: "./generic/media-square.jpg",
      },
      body: "Audiences have more outlets than ever to direct their attention. Learn how new technologies change the way attention is captured and how personalization is the key to keep customers engaged.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/conf/industries/media/the-war-for-attention/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/media2.png",
      headline: "Grow Subscriber Relationships in Media.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/industries/media/grow-relationships-demo/?d=cta-body-promo-2053",
    },
    {
      image: "./offers/media3.png",
      headline: "Grow and monetize loyal audiences and advertisers faster.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/media/media-cloud-demo/?d=cta-body-promo-2252",
    },
  ],
};
