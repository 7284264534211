module.exports = {
  title: "Enterprise",
  titlePlural: "Enterprise Organization",
  astro: "./astros/astro_education_executive_misc.png",
  slug: "enterprise-nonprofit",
  desc: "250+ Employees",
  generic: [
    {
      eyebrow: "For Transformation Leaders",
      headline: "Chart Your Course with a Digital Transformation Roadmap",
      images: {
        landscape: "./generic/enterprise-landscape.jpg",
        square: "./generic/enterprise-square.jpg",
      },
      body: 'Learn how to change mindsets, connect silos, and center around your customer with the "Transformation Playbook."',
      cta: "Read the Playbook",
      ctaLink:
        "https://www.salesforce.com/resources/guides/customer-transformation-playbook-changing-company-mindsets/",
    },
  ],
};
