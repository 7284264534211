module.exports = {
  title: "Marketing",
  slug: "marketing-edu",
  astro: "./astros/astro_smb_marketing.png",
  emailTemplate: "Marketing",
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Lead Volume",
        multi: 32,
        single: 85,
      },
      {
        title: "Increase in Overall Marketing ROI",
        multi: 28,
        single: 64,
      },
      {
        title: "Increase in Lead Velocity or Speed",
        multi: 33,
        single: 62,
      },
      {
        title: "Decrease in Costs to Acquire New Customers",
        multi: 27,
        single: 89,
      },
    ],
  },
  generic: [],
};
