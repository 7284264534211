import React from "react";
import Image from "../Global/Image";
import FadeComponent from "../Global/FadeComponent";

function AIBanner({ ...props }) {
  const { isPhone } = props;
  return (
    <FadeComponent isPhone={isPhone}>
      <div className="sf-row sf-row-xs--center">
        <div className="sf-col-xs-21 banner">
          <div className="text-container sf-col-xs-16 sf-col-md-12 sf-col-lg-6">
            <h2 className="text__title text__main -grid">AI Strategy Guide</h2>
            <h2 className="text__title -grid">
              Make Data + AI + CRM Your Trusted Formula
            </h2>
            <a
              className="text__cta"
              href={"https://www.salesforce.com/blog/playbook/ai-guide/"}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noopener"
              // onClick={() => handleAnalyticsData(priority)}
            >
              Get the guide
            </a>
          </div>
        </div>
      </div>
    </FadeComponent>
  );
}

export default AIBanner;
