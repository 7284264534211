const industriesPath = require("./industriesPath");

module.exports = {
  title: "Healthcare & Life Sciences",
  slug: "healthcare-life-sciences",
  astro: "./astros/astro_healthcare.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Healthcare and Life Sciences Leaders",
      headline:
        "Deliver Amazing Patient Experiences at Every Stage of the Journey",
      images: {
        landscape: "./generic/health-life-science-landscape.jpg",
        square: "./generic/health-life-science-square.jpg",
      },
      body: "Transform the patient experience. Create tailored solutions for your healthcare and life sciences organizations.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/healthcare/overview/",
    },
    // {
    //   eyebrow: "For Healthcare and Life Sciences Leaders",
    //   headline:
    //     "Deliver Amazing Patient Experiences at Every Stage of the Journey",
    //   images: {
    //     landscape: "./generic/health-life-science-landscape.jpg",
    //     square: "./generic/health-life-science-square.jpg",
    //   },
    //   body: "Transform the patient experience. Create tailored solutions for your healthcare and life sciences organizations.",
    //   cta: "Learn More",
    //   ctaLink:
    //     "https://www.salesforce.com/solutions/industries/healthcare/overview/",
    // },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "ROI Achieved",
        multi: 29,
        single: 29,
      },
      {
        title: "Decrease in Service/Support Costs",
        multi: 36,
        single: 62,
      },
      {
        title: "Decrease in Operational Costs",
        multi: 28,
        single: 28,
      },
      {
        title: "Decrease in Service/Support Costs",
        multi: 36,
        single: 50,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/health2.png",
      headline:
        "400 global industry leaders discuss automation, efficiencies, and technology integration.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/healthcare-life-science-insights-report/?d=cta-body-promo-2361",
    },
    {
      image: "./offers/health3.png",
      headline:
        "Salesforce AI for Health: Your Prescription to Reduce Administrative Burnout.",
      cta: "Watch now",
      link: "https://www.salesforce.com/form/events/webinars/form-rss/4328136",
    },
  ],
};
