module.exports = {
  title: "Midsize",
  titlePlural: "Midsize Businesses",
  slug: "midsize-business",
  astro: "./astros/astro_education_executive_misc.png",
  desc: "200-1000 Employees",
  generic: [
    {
      eyebrow: "For Transformation Leaders",
      headline: "Chart Your Course with a Digital Transformation Roadmap",
      images: {
        landscape: "./generic/medium-business-landscape.jpg",
        square: "./generic/medium-business-square.jpg",
      },
      body: 'Learn how to change mindsets, connect silos, and center around your customer with the "Transformation Playbook."',
      cta: "Read the Playbook",
      ctaLink:
        "https://www.salesforce.com/resources/guides/customer-transformation-playbook-changing-company-mindsets/",
    },
  ],
};
