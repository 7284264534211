import React from "react";
import Image from "../Global/Image";
import VidyardVideo from "../Global/VidyardVideo";
import TextLockup from "../Global/TextLockup";
import FadeComponent from "../Global/FadeComponent";

const RecsVideo = ({ ...props }) => {
  const { data, isPhone } = props;
  return (
    <FadeComponent isPhone={isPhone}>
      <div className="recs-video">
        <div className="recs-video__wrap">
          <div className="sf-row sf-row-xs--center sf-row-md--middle">
            <div className="sf-col-xs-20 sf-col-md-16 recs-video__lockup-container">
              <TextLockup
                {...data}
                classes="recs-video__lockup"
                useGrid={false}
              />
              <div className="recs-video__butterfly-wrap">
                <Image
                  desktopSrc="./Butterfly2.png"
                  mobileSrc="./Butterfly2.png"
                  classes="recs-video__butterfly"
                />
              </div>
            </div>
          </div>
          <div className="sf-row sf-row-xs--center sf-row-md--middle">
            <div className="sf-col-xs-20 sf-col-md-12 recs-video__player-wrap">
              <VidyardVideo videoId={data.videoID} classes="intro__video" />
            </div>
          </div>
        </div>
      </div>
    </FadeComponent>
  );
};

export default RecsVideo;
