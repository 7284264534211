const industriesPath = require("./industriesPath");

module.exports = {
  title: "Travel, Transportation & Hospitality",
  slug: "travel-transportation-hospitality",
  astro: "./astros/astro_comms_media_transportation_hospitality.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Transportation and Hospitality Leaders",
      headline: "Build Loyalty With Travelers and Employees",
      images: {
        landscape: "./generic/transportation-hospitality-landscape.jpg",
        square: "./generic/transportation-hospitality-square.jpg",
      },
      body: "Provide smarter, more personalized service. Create seamless and unforgettable experiences for travelers. Streamline logistics for both customers and employees.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/transportation-hospitality/overview/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/transport2.jpeg",
      headline:
        "Revolutionize Travel and Hospitality Experiences with Data and AI.",
      cta: "Watch now",
      link: "https://www.salesforce.com/resources/webinars/revolutionize-travel-and-hospitality-experiences-with-data-and-ai/?d=cta-body-promo-2012",
    },
    {
      image: "./offers/transport3.jpeg",
      headline:
        "How to Drive Efficient Growth in a Changing Transportation & Logistics Landscape.",
      cta: "Get the article",
      link: "https://www.salesforce.com/resources/articles/drive-efficient-transportation-growth-form/",
    },
  ],
};
