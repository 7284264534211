module.exports = {
  title: "Small & Emerging",
  titlePlural: "Small & Emerging Businesses",
  slug: "small-business",
  astro: "./astros/astro_smb_marketing.png",
  desc: "1-200 Employees",
  generic: [
    {
      eyebrow: "For Small Business Leaders",
      headline:
        "Grow Your Small Business with Out-of-the-box Sales, Service, and Marketing",
      images: {
        landscape: "./generic/small-business-landscape.jpg",
        square: "./generic/small-business-square.jpg",
      },
      body: "Get simple, essential small business tools that turn opportunities into sales. Understand and deliver on customer needs so you can reach out with the right message at the right time.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/small-business-solutions/overview/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/smb2.webp",
      headline: "Meet Salesforce Starter: the simple, powerful CRM suite.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/starter/overview-demo/",
    },
    {
      image: "./offers/smb3.png",
      headline:
        "Take Starter for a spin. No credit card required, no software to install.",
      cta: "Start my free trial",
      link: "https://www.salesforce.com/form/signup/freetrial-elf-v2/?d=cta-conf-1",
    },
  ],
};
