import { useState, useEffect } from "react";

const useIndicatorButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const windowScroll = () => {
      // Additional treshold for not showing the more button in formula.
      // const treshold = 120;
      setShowButton(window.pageYOffset === 0);
    };
    window.addEventListener("scroll", windowScroll);
    windowScroll();
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  return showButton;
};

export default useIndicatorButton;
