module.exports = {
  title: "Sales",
  slug: "sales",
  astro: "./astros/astro_sales_commerce.png",
  emailTemplate: "Sales",
  generic: [
    {
      eyebrow: "For Sales Teams",
      headline:
        "Find More Leads, Close More Deals, and Understand Your Entire Business",
      images: {
        landscape: "./generic/sales-landscape.jpg",
        square: "./generic/sales-square.jpg",
      },
      body: "Give your entire sales team, from rep to leader, the necessary tools to better connect to customers, employees, and partners at every stage of any journey.",
      cta: "Learn More",
      ctaLink: "https://www.salesforce.com/products/sales-cloud/overview/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Win Rate",
        multi: 27,
        single: 88,
      },
      {
        title: "Increase in Forecast Accuracy",
        multi: 28,
        single: 64,
      },
      {
        title: "Faster Sales Deal Closure",
        multi: 28,
        single: 89,
      },
      {
        title: "Decrease in Time Onboarding New Reps",
        multi: 25,
        single: 67,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/sales2.webp",
      headline:
        "Discover the latest insights from more than 7,700 sales professionals.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/state-of-sales/",
    },
    {
      image: "./offers/sales3.webp",
      headline: "Unleash growth now with the #1 AI CRM for Sales.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/sales/unlimited-edition/?d=pb1",
    },
  ],
};
