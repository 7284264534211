import React, { useRef, useCallback, useContext } from "react";
import { AnswerContext } from "../../context/answer-context";
import Background from "../Global/Background";
import BackgroundParallax from "../Global/BackgroundParallax";
import FadePage from "../Global/FadePage";
import PillGroup from "../Global/PillGroup";
import StepCounter from "../Global/StepCounter";
import QuestionTitle from "../Global/QuestionTitle";
import { getComponentsHeights } from "../../utils/functions";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import useDevicesBreakpoints from "../hooks/useDevicesBreakpoints";
import { GlobalContext } from "../../context/answer-context";
const appData = require("../../assets/data");

const Question = ({ ...props }) => {
  const {
    strings,
    answers,
    industryType,
    includeCustomAnswersByIndustry,
    questionName,
    stateKey,
    className,
    isPrioritiesQuestion,
    pillType,
  } = props;
  const { hasModeEventsInParams, searchParams } = useContext(GlobalContext);
  const { size, isMobile, isPhone } = useDevicesBreakpoints();
  const pill = useRef(null);
  const pillGroup = useRef(null);
  const [showMoreIndicator, setShowMoreIndicator] = React.useState(false);
  let answersX = answers;
  const comingFromCampaing = searchParams.get("campaign");

  if (includeCustomAnswersByIndustry) {
    const filterAnswersByIndustry = () => {
      const getIndustryJSONFromData = () =>
        appData.industryType.find((item) => item.slug === industryType.slug);

      const industryJSON = getIndustryJSONFromData();
      answersX = answers.filter((item) =>
        industryJSON[questionName].includes(item.slug)
      );
    };

    filterAnswersByIndustry();
  }

  if (isPrioritiesQuestion) {
    const removeSomePriorities = () => {
      const isSmallBusiness = props.businessType.slug === "small-business";
      const isExecutive = props.roleType.slug === "executive";

      //Remove executive priorities when is small business
      if (isExecutive && isSmallBusiness) {
        answersX = answersX.filter((d) => d.category !== "roleType");
      }
    };

    const sortPrioritiesByRank = () => {
      //rank - lowest number is highest rank
      answersX.sort((a, b) => (a.rank > b.rank ? 1 : -1));
    };

    removeSomePriorities();
    sortPrioritiesByRank();
  }

  const onAnimationEnd = () => {
    if (pill.current) {
      pill.current.focus();
    }
  };
  const { sfHeader, quizTracker } = getComponentsHeights(size.width);

  const windowScroll = useCallback(() => {
    const element = pillGroup.current;
    setShowMoreIndicator(
      window.innerHeight + window.pageYOffset <
        (element && element.clientHeight) +
          quizTracker +
          (!hasModeEventsInParams() ? sfHeader : 0)
    );
  }, [hasModeEventsInParams(), quizTracker, sfHeader]);

  React.useEffect(() => {
    windowScroll();
  }, [size, windowScroll]);

  React.useEffect(() => {
    window.addEventListener("scroll", windowScroll);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, [windowScroll]);

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <>
          <FadePage show={true} onAnimationEnded={onAnimationEnd}>
            <div
              className={`sf-row sf-row-xs--center ${className}__outline`}
              ref={pill}
              tabIndex="-1"
            >
              <div
                className={`sf-col-xs-20 ${className}__container ${
                  hasModeEventsInParams() ? "events" : ""
                }`}
              >
                <div className={`${className}`} ref={pillGroup}>
                  <StepCounter />
                  {comingFromCampaing === "industry" ? (
                    <h1 className="question-welcome__message">Welcome</h1>
                  ) : null}
                  <QuestionTitle
                    title={strings.title}
                    subTitle={strings.subTitle}
                    isPhone={isPhone}
                  />
                  <div
                    className={`sf-row sf-row-xs--center ${className}__options`}
                  >
                    <PillGroup
                      legend={strings.slug}
                      data={answersX}
                      stateKey={stateKey}
                      pillType={pillType}
                    />
                  </div>
                  {showMoreIndicator ? (
                    <div className="text__more">
                      {strings.moreIndicator}
                      <Arrow className="text__more-arrow" aria-hidden={true} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </FadePage>
          {isMobile ? (
            <Background step={state.stepNum} />
          ) : (
            <BackgroundParallax
              classPrefix={`${className}`}
              step={state.stepNum}
              isMobile={isMobile}
            />
          )}
        </>
      )}
    </AnswerContext.Consumer>
  );
};

export default Question;
