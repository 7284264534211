import React, { useContext } from "react";
import {
  ALL_CATEGORIES,
  CATEGORY_MAP,
  STATE_KEYS,
  NON_OPTIONS_TYPE,
} from "../../context/answer-context";
import { useParams, Navigate } from "react-router-dom";
import Results from "./Results";
import { URL_PARAMETERS } from "../../utils/constants";
import { GlobalContext } from "../../context/answer-context";
const appData = require("../../assets/data");

function ValidateResults() {
  const { rol, ind, cat, biz, prio } = useParams();
  const { searchParams } = useContext(GlobalContext);
  const refValue = searchParams.get(URL_PARAMETERS.REF.key);
  const catToUse = Object.keys(CATEGORY_MAP).find(
    (c) => CATEGORY_MAP[c] === cat
  );

  // Pull appropriate data from URL params
  const data = useBuildDataFromParams(ind, rol, biz, prio, catToUse, refValue);

  const isValid = validateData(data);
  if (!isValid) return <Navigate to="/404" />;

  return <Results data={data} />;
}

// Function to find necessary data from URL params
const useBuildDataFromParams = (
  industry,
  role,
  biz,
  priority,
  category,
  refValue
) => {
  const allIndustries = appData.industryType;
  const allRoles = appData.roleType;
  const allBusiness = appData.businessType;
  const allCategories = appData[category];
  const map = {
    industryType: industry,
    roleType: role,
    businessType: biz,
  };

  const industryParam = allIndustries.find((item) => item.slug === industry);
  const roleParam = allRoles.find((item) => item.slug === role);
  const businessParam =
    allBusiness.find((item) => item.slug === biz) ||
    NON_OPTIONS_TYPE[STATE_KEYS.businessType];
  const solution = appData.priorities.find((item) => item.slug === priority);
  let offers;

  //Here we set the new form URl. We call the function to obtain the right URL based on the priority and search params
  if (typeof solution !== "undefined") {
    // Setting which type of form we choose
    const formType = URL_PARAMETERS.REF.values.includes(refValue)
      ? "email"
      : "default";
    solution.formattedFormUrl = buildFormUrl(solution.formUrl[formType]);
    offers = appData[solution.categoryOffer].find(
      (c) => c.slug === solution.offerSection
    ).offers;
  }

  const unusedCategories =
    industry === "education" || industry === "public-sector"
      ? ALL_CATEGORIES
      : ALL_CATEGORIES.filter((cat) => ![category].includes(cat));

  const generics = unusedCategories
    .map((cat) => appData[cat].find((c) => c.slug === map[cat]))
    .filter((item) => item !== undefined);

  const catParam = allCategories.find((item) => item.slug === map[category]);
  const astro = catParam.astro;

  return {
    businessParam,
    roleParam,
    industryParam,
    solution,
    generics,
    astro,
    offers,
  };
};

//With this funcion we'll get the right campaign and driver for the form URL
const buildFormUrl = ({ url, campaignId, driver }) => {
  return `${url}?${campaignId}&${driver}`;
};

// If any data is undefined, abort and redirect to 404
const validateData = (data) => {
  const { industryParam, roleParam, businessParam, solution, generics } = data;
  const allItems = [
    industryParam,
    roleParam,
    businessParam,
    solution,
    ...generics,
  ];
  return allItems.indexOf(undefined) === -1 ? true : false;
};

export default ValidateResults;
