const industriesPath = require("./industriesPath");

module.exports = {
  title: "Retail",
  slug: "retail",
  astro: "./astros/astro_retail.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Retail Trailblazers",
      headline: "Learn how customers saw 257% ROI with Salesforce for retail.",
      images: {
        landscape: "./generic/retail-landscape.jpg",
        square: "./generic/retail-square.jpg",
      },
      body: "The Forrester Consulting Total Economic Impact study tracks how Salesforce for Retail performs in areas such as marketing, commerce, service, and analytics.",
      cta: "Get the Report",
      ctaLink:
        "https://www.salesforce.com/form/conf/industries/retail/retailroi/?d=cta-body-promo-1944 ",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/retail2.png",
      headline: "Learn how customers saw 257% ROI with Salesforce for retail.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/industries/retail/retailroi/?d=cta-body-promo-1944",
    },
    {
      image: "./offers/retail3.jpeg",
      headline:
        "Drive costs down and loyalty up with shoppers at the center of every experience.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/salesforce-retail-demo/?d=701300000021HEo",
    },
  ],
};
