import React from "react";
import FadePage from "../Global/FadePage";
import Background from "../Global/Background";
import { PAGES } from "../../utils/constants";
const appStrings = require("../../assets/data/strings");

function PageNotFound() {
  const strings = appStrings.notFound;
  return (
    <div className={`app__container ${PAGES[0]}__app_container`}>
      <main>
        <div className="notFound">
          <FadePage show={true}>
            <div className="notFound__wrap sf-row sf-row-xs--center sf-row-xs--middle">
              <div className="sf-col-xs-20 sf-col-md-12 sf-col-lg-10">
                <h1 className="notFound__title">{strings.title}</h1>
                <a className="notFound__cta" href="/">
                  {strings.cta}
                </a>
              </div>
            </div>
          </FadePage>
          <Background step={404} full />
        </div>
      </main>
    </div>
  );
}

export default PageNotFound;
