import React, { useEffect, useState } from "react";

const FadePage = ({ show, onAnimationEnded, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
    if (onAnimationEnded) {
      onAnimationEnded();
    }
  };

  return (
    shouldRender && (
      <div
        className="fade-container"
        style={{
          animation: `${show ? "fadeIn 700ms ease" : "fadeOut 100ms ease"}`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default FadePage;
