module.exports = {
  title: "Program Management",
  slug: "program-management",
  astro: "./astros/astro_education_executive_misc.png",
  emailTemplate: "General",
  generic: [],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Business User Productivity",
        multi: 29,
        single: 89,
      },
      {
        title: "Faster Decision-Making",
        multi: 30,
        single: 85,
      },
      {
        title: "ROI Achieved",
        multi: 29,
        single: 89,
      },
      {
        title: "Increase in Team Collaboration",
        multi: 30,
        single: 85,
      },
    ],
  },
};
