const industriesPath = require("./industriesPath");

module.exports = {
  title: "Public Sector",
  slug: "public-sector",
  astro: "./astros/astro_public_sector.png",
  roles: industriesPath.roles.publicSector,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Government Leaders",
      headline:
        "See how Leading Government Organizations Modernize and Innovate",
      images: {
        landscape: "./generic/public-sector-landscape.jpg",
        square: "./generic/public-sector-square.jpg",
      },
      body: "Discover best practices from Trailblazer’s collective experience with the cloud in in this eBook.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/conf/industries/government/government-leaders-book-of-best-practices",
    },
    {
      eyebrow: "Build Constituent Trust",
      headline: "Vaccine Management Playbook",
      images: {
        landscape: "./generic/public-sector-landscape2.jpg",
        square: "./generic/public-sector-square2.jpg",
      },
      body: "Your guide to building trust through public service during a global pandemic.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/government/capabilities/vaccine-management/vaccine-management-playbook/?d=cta-header-1",
    },
  ],
  roi: {
    title:
      "Accelerate hiring and streamline employee requests in a trusted operations hub",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Decrease in service/support costs",
        multi: 20,
        single: 29,
      },
      {
        title:
          "Increase in case resolution via self-service, automation, and/or AI",
        multi: 23,
        single: 62,
      },
      {
        title: "Increase in remote employee productivity",
        multi: 24,
        single: 28,
      },
      {
        title: "Faster collaboration",
        multi: 23,
        single: 50,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/publicsector2.png",
      headline: "Trends Shaping the Future of Public Sector Digital Service.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/connected-government-report-form/?d=cta-body-promo-2460",
    },
    {
      image: "./offers/publicsector3.png",
      headline:
        "Modernize government service to increase mission success and agency efficiency.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/government/digital-government-demos/",
    },
  ],
};
