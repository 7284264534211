import React from "react";
import { AnswerContext } from "../../context/answer-context";
import _ from "lodash";
import Image from "../Global/Image";
import TextLockup from "../Global/TextLockup";
import { ANALYTICS, TEXT_TYPES } from "../../utils/constants";
import TagManager from "react-gtm-module";

const handleAnalyticsData = (type, title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventTracker",
      eventCat: ANALYTICS.CATEGORY.LEARNMORE,
      eventAct: ANALYTICS.ACTION.LEARNMORE,
      eventLbl: `${type}, ${title}`,
    },
  });
};

function RecsConditionalModules({ ...props }) {
  const { data, title, subTitle, conditionalModuleCta, ctaLink } = props;
  const allGenerics = data.map((d) => d.generic).flat();
  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div className="recs-conditionals__wrap sf-row sf-row-xs--center">
          <div className="sf-col-xs-20 sf-col-md-22">
            <h2 className="recs-conditionals__title">{title}</h2>
            <div className="sf-row sf-row-xs--center recs-conditionals__cardlayout sf-row-md--between">
              {_.take(allGenerics, 2).map((conditional, i) => {
                const aspectRatio = conditional.images.square;
                return (
                  <div
                    key={conditional.headline}
                    className="recs-conditionals__item  recs-conditionals__item--1 sf-col-xs-20 sf-col-md-8 sf-col-lg-6"
                  >
                    <Image
                      classes="recs-conditionals__image"
                      desktopSrc={aspectRatio}
                      mobileSrc={conditional.images.square}
                      alt=""
                      toTranslate={true}
                    />
                    <TextLockup
                      eyebrow={conditional.eyebrow}
                      isContactPageHeading={true}
                      title={conditional.headline}
                      body={conditional.body}
                      cta={conditional.cta}
                      ctaLink={conditional.ctaLink}
                      type={TEXT_TYPES.LEFT}
                      classes="recs-conditionals__lockup"
                      useGrid={false}
                      analytics={() =>
                        handleAnalyticsData(
                          data[i].title,
                          state.priorityChoice.title
                        )
                      }
                    />
                  </div>
                );
              })}
              <div className="recs-conditionals__item  recs-conditionals__item--3 sf-col-xs-20 sf-col-md-8 sf-col-lg-6">
                <TextLockup
                  isContactPageHeading={true}
                  title={subTitle}
                  cta={conditionalModuleCta}
                  ctaLink={ctaLink}
                  type={TEXT_TYPES.LEFT}
                  classes="recs-conditionals__brandcard"
                  useGrid={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AnswerContext.Consumer>
  );
}

export default RecsConditionalModules;
