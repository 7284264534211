import React, { useContext } from "react";
import Image from "../Global/Image";
import TextSubtitles from "../Global/TextSubtitles";
import TextBody from "../Global/TextBody";
import { Link } from "react-router-dom";
import { TEXT_TYPES, TIMING } from "../../utils/constants";
import { ReactComponent as BlueBackground } from "../../assets/images/background-blue.svg";
import { ReactComponent as BlueBackgroundMobile } from "../../assets/images/background-blue-mobile.svg";
import useDevicesBreakpoints from "../hooks/useDevicesBreakpoints.js";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/answer-context";

function IntroWheel({ ...props }) {
  const { strings, handleAnalyticsData } = props;
  const { isPhone } = useDevicesBreakpoints();
  const { searchParamsToString } = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <div className="intro__wheel-container sf-row sf-row-xs--center">
      <div className="intro__wheel sf-col-xs-20 sf-col-md-20 sf-col-lg-20">
        <div>
          <div className="sf-row sf-row-xs--center sf-row-xs--middle sf-col-xs-20 sf-col-md-20 sf-col-lg-20">
            <div className="sf-col-xs-20 sf-col-md-9 sf-col-lg-9">
              <Image
                desktopSrc="./intro_wheel.png"
                mobileSrc="./intro_wheel.png"
                alt="Customer-360 image"
                fadeDuration={TIMING.TIMING_500}
                toTranslate={true}
                classes="intro__wheel-wheel-img"
                role="presentation"
              />
            </div>
            <div className="sf-col-xs-20 sf-col-md-2 sf-col-lg-2" />
            <div className="sf-col-xs-20 sf-col-md-9 sf-col-lg-9">
              <div className="sf-row sf-row-xs--center">
                <TextSubtitles
                  title={strings.subTitle}
                  type={TEXT_TYPES.LARGE}
                  isPageHeading={true}
                />
              </div>
              <div className={`sf-row sf-row-xs--start sf-row-lg--start`}>
                <div className="intro__wheel-informationText">
                  <TextBody
                    body={strings.informationText}
                    type={TEXT_TYPES.LARGE}
                  />
                </div>
              </div>
              <div className="intro__wheel-ctas">
                <button
                  className={`intro__wheel-start`}
                  onClick={() => {
                    handleAnalyticsData("Home Lower Wheel");
                    navigate("/quiz/1" + searchParamsToString());
                  }}
                  role="link"
                >
                  {strings.ctaStart}
                </button>
                <Link
                  className="intro__wheel-360"
                  target="_blank"
                  to={"https://www.salesforce.com/products/"}
                >
                  {strings.ctaCustomer}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="intro__wheel-bluebackground-wrap ">
          {isPhone ? (
            <BlueBackgroundMobile className="intro__wheel-bluebackground" />
          ) : (
            <BlueBackground className="intro__wheel-bluebackground" />
          )}
        </div>
      </div>
    </div>
  );
}

export default IntroWheel;
