const logicPath = require("./logicPath");
module.exports = [
  {
    name: "education-path",
    slug: "education",
    logicPath: logicPath.originalLogic,
    strings: {
      businessType: {
        slug: "business",
        title: "What type of students do you serve?",
        subTitle: "Help us match you to the right products.",
      },
      tracker: {
        solutions: "Solutions for the",
        role: "that serve",
        industry: "for",
        priority: "that need to",
        moreIndicator: "MORE",
        defaultTypes: {
          role: "Role",
          roleActive: "roles ",
          industry: "Industry",
          industryActive: "industry ",
          businessType: "Student Type",
          priority: "Goal",
        },
      },
    },
  },
  {
    name: "public-sector-path",
    slug: "public-sector",
    logicPath: logicPath.originalLogic,
    skippedQuestion: 3,
    skippedKey: "businessType",
    strings: {},
  },
  {
    name: "nonprofit-path",
    slug: "nonprofit",
    logicPath: logicPath.originalLogic,
    strings: {
      tracker: {
        solutions: "Solutions for the",
        role: "in",
        industry: "for",
        priority: "that need to",
        moreIndicator: "MORE",
        defaultTypes: {
          role: "Role",
          roleActive: "roles ",
          industry: "Industry",
          industryActive: "industry ",
          businessType: "Organization Type",
          priority: "Goal",
        },
      },
    },
  },
];
