module.exports = {
  title: "IT",
  slug: "it-public-sector",
  astro: "./astros/astro_it.png",
  emailTemplate: "IT",
  generic: [],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Decrease in IT Costs",
        multi: 25,
        single: 85,
      },
      {
        title: "Increase in Developer Productivity",
        multi: 29,
        single: 58,
      },
      {
        title: "Faster Application Deployment",
        multi: 29,
        single: 79,
      },
      {
        title: "Increased Agility and Flexibility of IT Systems and Platforms",
        multi: 29,
        single: 89,
      },
    ],
  },
};
