const industriesPath = require("./industriesPath");

module.exports = {
  title: "Other",
  slug: "other",
  astro: "./astros/astro_education_executive_misc.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Customer Innovators",
      headline: "Learn the New Rules of Customer Engagement",
      images: {
        landscape: "./generic/other-landscape.jpg",
        square: "./generic/other-square.jpg",
      },
      body: "Read the latest edition of the 'State of the Connected Customer' report. See what 8,000 consumers and business buyers across the globe think customer engagement means for businesses today.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/blog/2019/06/customer-engagement-trends.html",
    },
  ],
};
