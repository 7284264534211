module.exports = {
  title: "K-12",
  titlePlural: "K-12",
  slug: "k-12",
  astro: "./astros/astro_education_executive_misc.png",
  generic: [
    {
      eyebrow: "For K-12 Education",
      headline: "Supporting Student Success in a Changing World",
      images: {
        landscape: "./generic/education-landscape.jpg",
        square: "./generic/education-square.jpg",
      },
      body: "Looking to foster an environment that supports the whole student? Download this school leader’s guide to creating impactful family and community partnerships.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/sfdo/edu/student-success-changing-world/",
    },
    {
      eyebrow: "For Schools and Districts of All Sizes",
      headline: "The Trailblazer’s Guide to Education Cloud for K-12",
      images: {
        landscape: "./generic/k-12-landscape.jpg",
        square: "./generic/k-12-square.jpg",
      },
      body: "Learn how schools and districts are using Education Cloud for K-12 to drive student and school success.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/sfdo/edu/salesforce-k12-education-ebook/",
    },
  ],
};
