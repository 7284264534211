module.exports = {
  title: "Executive",
  slug: "executive-public-sector",
  astro: "./astros/astro_education_executive_misc.png",
  emailTemplate: "Executive",
  generic: [],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Business User Productivity",
        multi: 29,
        single: 85,
      },
      {
        title: "Increase in Win Rate",
        multi: 27,
        single: 89,
      },
      {
        title: "Increase in Customer Retention",
        multi: 30,
        single: 85,
      },
      {
        title: "Increase in Forecast Accuracy",
        multi: 28,
        single: 89,
      },
    ],
  },
};
