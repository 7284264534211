export const BREAKPOINTS = {
  MED: 768,
  LG: 1025,
  XL: 1200,
};

export const HEADER = {
  DISTANCE_TO_SCROLL: 25,
};

export const TIMING = {
  TIMING_05: 5,
  TIMING_50: 50,
  TIMING_100: 100,
  TIMING_200: 200,
  TIMING_250: 250,
  TIMING_500: 500,
  TIMING_700: 700,
  TIMING_5000: 5000,
};

export const ANIM_NAMES = {
  FADEIN: "fadeIn",
  FADEOUT: "fadeOut",
};

export const VIDYARD_TYPES = {
  INLINE: "inline",
  LIGHTBOX: "lightbox",
};

export const ANIM_CURVES = {
  EASE: "ease",
  EASE_OUT: "ease-out",
};

export const RATIOS = {
  PORTRAIT: "portrait",
  SQUARE: "square",
  LANDSCAPE: "landscape",
};

export const LOADING = {
  HEIGHT: 200,
  OFFSET: 100,
};

export const CLASSES = {
  ACTIVE: "-active",
  INACTIVE: "-inactive",
  ANIMATING: "-animating",
  COMPLETE: "-complete",
  REVERSE: "-reverse",
  PAUSE: "-pause",
  LEFT: "-left",
  RIGHT: "-right",
  MOVE: "-move",
  DELAY: "-delay",
  CENTER_MOBILE: "-center-mobile",
};

export const PILL = {
  TYPES: {
    LONG: "LONG",
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
  },
  CLASSES: {
    LONG: "--long",
    SMALL: "--small",
    MEDIUM: "--medium",
    LARGE: "--large",
  },
};

export const TEXT_TYPES = {
  DEFAULT: "DEFAULT",
  LARGE: "LARGE",
  LEFT: "LEFT",
  BODY: "BODY",
};

export const CIRCLE_TYPES = {
  LEFT: "left",
  RIGHT: "right",
  LARGE: "large",
  XL: "xl",
};

export const PAGES = {
  0: "intro",
  1: "industries",
  2: "roles",
  3: "type",
  4: "priorities",
  5: "results",
  TIMELINE: "timeline",
};

export const QUESTION_CLASSNAMES = {
  industry: "industries",
  role: "roles",
  bussinesType: "type",
  priority: "priorities",
};

export const INDUSTRY_ATTRIBUTES = {
  role: "roles",
  bussinesType: "businessType",
};

export const GRID = {
  COLS: 24,
};

export const WHEEL = {
  ALL: "all",
  NONE: "none",
  MODES: {
    EXPLODE: "mode--explode",
  },
};

export const SWIPE = {
  LEFT: "left",
  RIGHT: "right",
  UP: "up",
  DOWN: "down",
};

export const KEY_CODES = {
  SPACE: 32,
  ENTER: 13,
};

export const ONSCREEN = {
  STD_MOBILE: "-10%",
  STD_DESKTOP: "-20%",
};

export const ANALYTICS = {
  CATEGORY: {
    CONTACTSALES: "Contact Sales Button Click",
    GETSTARTED: "Get Started Button",
    QUIZ: "Quiz Questions",
    LEARNMORE: "Learn More Button Clicks",
    SHARESOLUTION: "Share Solution Button Click",
  },
  ACTION: {
    HEROTOUCH: "Hero Get In Touch Triggered",
    GETSTARTED: "Clicked Get Started Button",
    STICKYNAV: "Sticky Nav Contact Us Triggered",
    CHALLENGE: "Challenge Response",
    NEXTSTEP: "Next Step CTA Triggered",
    LEARNMORE: "Clicked Learn More",
    SHARESOLUTION: "Click Share Solution",
  },
  LABEL: {
    CTHREE: "C360 PAGE",
  },
};

export const SESSIONS = {
  CHOICE_KEY: "choice",
  MODE_KEY: "mode",
  EVENTS_VALUE: "events",
};

export const VIEWMORE = {
  LABEL: "View more priorities",
};

export const URL_PARAMETERS = {
  REF: {
    key: "ref",
    values: ["email", "df"],
  },
  OFFER: {
    key: "o",
    values: ["formula1"],
  },
  MODE: {
    key: "mode",
    values: ["events"],
  },
  INDUSTRY: {
    key: "i",
    values: [],
  },
};

export const FORMID = {
  ID: "7013y00000000000000",
};

export const EVENTS = {
  INACTIVITY_TIME_MS: 30000,
  SOLUTION_FINDER_IFRAME_TITLE: "Customer360 SolutionFinder",
  BUTTON_START_OVER: "START OVER",
  BUTTON_SEND_RESULTS: "SEND MY RESULTS",
  MODAL_INSTRUCTIONS:
    "Provide your contact information below to receive your survey results by email.",
  MODAL_AGREEMENT_TEXT:
    "Salesforce values your privacy. To learn more, check our Privacy Statement.",
  MODAL_BUTTON_SUBMIT: "SUBMIT",
  API_ENDPOINT_SEND_DATA:
    "https://api.yourcustomer360solution.com/api/form/senddata",
  DOMAIN_RESULTS_EMAIL: "https://salesforcecustomer360.herokuapp.com",
};
