import React, { useEffect, useState, useRef } from "react";
import { useOnScreen } from "use-hooks";
import { CLASSES, ONSCREEN } from "../../utils/constants";

const FadeComponent = ({ children, isPhone }) => {
  const ref = useRef();
  const onScreen = useOnScreen(
    ref,
    isPhone ? ONSCREEN.STD_MOBILE : ONSCREEN.STD_DESKTOP
  ); //Offset pixel amount for module
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (onScreen && !isActive) {
      setIsActive(true);
    }
  }, [onScreen, isActive]);

  return (
    <div
      ref={ref}
      className={`fade-component ${isActive ? CLASSES.ACTIVE : ""}`}
    >
      {children}
    </div>
  );
};

export default FadeComponent;
