const financialServices = require("./industries/financialServices");
const healthCare = require("./industries/healthCare");
const retail = require("./industries/retail");
const consumerGoods = require("./industries/consumerGoods");
const manufacturing = require("./industries/manufacturing");
const communications = require("./industries/communications");
const media = require("./industries/media");
const transportationHospitality = require("./industries/transportationHospitality");
const education = require("./industries/education");
const professionalServices = require("./industries/professionalServices");
const highTech = require("./industries/highTech");
const other = require("./industries/other");
const nonprofit = require("./industries/nonprofit");
const publicSector = require("./industries/publicSector");
const energy = require("./industries/energy");

module.exports = [
  financialServices,
  healthCare,
  retail,
  consumerGoods,
  manufacturing,
  communications,
  media,
  publicSector,
  energy,
  transportationHospitality,
  education,
  nonprofit,
  professionalServices,
  highTech,
  other,
];
