import React from "react";
import { ReactComponent as Dots } from "../../assets/icons/dots.svg";
import { ReactComponent as Clouds } from "../../assets/images/parallax-clouds.svg";
import astroPeep from "../../assets/images/astro-popin.gif";
import { CLASSES } from "../../utils/constants";
import { getPrefersReducedMotion } from "../../utils/functions";
import { checkChoice } from "../../utils/functions";
import ParticleSystem from "../../utils/ParticleSystem";
import useDevicesBreakpoints from "../hooks/useDevicesBreakpoints";

function RecsInterstitial({ ...props }) {
  const { solution, concludeInterstitial, loadingText } = props;
  const { isMobile } = useDevicesBreakpoints();

  // Check if we have session storage for this choice to determine whether this is a direct link or user has gone through the questionnaire
  // If the params from the url match the params in session storage, we know they've gone through the questionnaire
  const curChoice = `${solution}`;
  const choiceMatch = checkChoice(curChoice);
  // If user PRM, don't animate. Else if history conditions are right, animate. Else, don't
  const doPerformInterstitial = getPrefersReducedMotion()
    ? false
    : choiceMatch
    ? true
    : false;
  if (!doPerformInterstitial) concludeInterstitial();

  const [interstitialActive, setInterstitialActive] = React.useState(
    doPerformInterstitial
  );

  // Instantiate confetti
  const confettiLeft = new ParticleSystem("left", isMobile);
  const confettiRight = new ParticleSystem("right", isMobile);

  const throwConfetti = (e) => {
    // Because these events fire for loader and wrap and stopPropagation does not seem to work on the react synthetic event
    if (e.currentTarget === e.target) {
      confettiLeft.init();
      confettiRight.init();
      concludeInterstitial();
      setInterstitialActive(false);
    }
  };

  return (
    interstitialActive && (
      <div
        onAnimationEnd={throwConfetti}
        className={`recs_interstitial__wrap sf-row sf-row sf-row-xs--center sf-row-xs--middle ${
          interstitialActive ? CLASSES.ACTIVE : ""
        }`}
      >
        <div className="recs_interstitial__astro-wrap sf-row sf-row-xs--middle">
          <img
            className="recs_interstitial__astro-img"
            alt=""
            src={astroPeep}
          />
        </div>
        <div className="recs_interstitial__loader sf-row sf-row-xs--middle">
          <div className="recs_interstitial__ring sf-row sf-row-xs--center sf-row-xs--middle">
            <Dots className="recs_interstitial__dots" />
            <div className="recs_interstitial__sky-wrap">
              <Clouds className="recs_interstitial__clouds" />
            </div>
          </div>
          <h3 className="recs_interstitial__loading-text">{loadingText}</h3>
        </div>
      </div>
    )
  );
}

export default RecsInterstitial;
