module.exports = {
  title: "IT",
  slug: "it",
  astro: "./astros/astro_it.png",
  emailTemplate: "IT",
  generic: [
    {
      eyebrow: "For IT Leaders",
      headline: "Top 4 Innovation Trends for Media Marketers",
      images: {
        landscape: "./generic/it-landscape.jpg",
        square: "./generic/it-square.jpg",
      },
      body: "Media and Communications marketers across the globe weighed in on the trends shaping their industry. Here’s a look at the top four — and the numbers behind them.",
      cta: "Get the Report",
      ctaLink: "https://www.salesforce.com/form/platform/idc-roi-study-2020/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Decrease in IT Costs",
        multi: 25,
        single: 85,
      },
      {
        title: "Increase in Developer Productivity",
        multi: 29,
        single: 58,
      },
      {
        title: "Faster Application Deployment",
        multi: 29,
        single: 79,
      },
      {
        title: "Increased Agility and Flexibility of IT Systems and Platforms",
        multi: 29,
        single: 89,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/it2.png",
      headline:
        "Learn why Salesforce is a Leader for Enterprise Low-Code Application Platforms.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/platform/gartner-lcap-report/?leadcreated=true&redirect=true&sfuuid=3bb5f5c0-c151-4d93-a4ca-47b6a9d57741&d=cta-body-promo-335&nc=7013y000002m6IfAAI",
    },
    {
      image: "./offers/it3.png",
      headline: "Uncover the trends shaping the business and IT landscape.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/state-of-it/",
    },
  ],
};
