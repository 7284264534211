const industriesPath = require("./industriesPath");

module.exports = {
  title: "Consumer Goods",
  slug: "consumer-goods",
  astro: "./astros/astro_retail.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Consumer Goods Leaders",
      headline: "Bring Your Brand, Channel Partners, and Consumers Together",
      images: {
        landscape: "./generic/consumer-landscape.jpg",
        square: "./generic/consumer-square.jpg",
      },
      body: "Connect interactions across the value chain to deliver brand experiences that consumers love.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/consumer-goods/overview/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Time saved on store visits",
        multi: 12,
        single: 29,
      },
      {
        title: "Decrease in IT cost",
        multi: 15,
        single: 62,
      },
      {
        title: "Increase in revenue",
        multi: 10,
        single: 28,
      },
      {
        title: "New item compliance",
        multi: 16,
        single: 50,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/consumergoods2.jpeg",
      headline:
        "Discover the trends and challenges shaping the consumer goods industry.",
      cta: "Get the report",
      link: "https://www.salesforce.com/resources/research-reports/consumer-goods-insights-report/?d=cta-body-promo-1930",
    },
    {
      image: "./offers/consumergoods3.png",
      headline:
        "Learn how you can reduce costs, win the hearts of consumers, and drive profitable growth now.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/consumer-goods-demo/?d=cta-body-promo-1923",
    },
  ],
};
