module.exports = {
  title: "Marketing",
  slug: "marketing",
  astro: "./astros/astro_smb_marketing.png",
  emailTemplate: "Marketing",
  generic: [
    {
      eyebrow: "For Marketers",
      headline:
        "Explore 5 interactive customer journeys that drive loyalty and ROI",
      images: {
        landscape: "./generic/marketing-landscape.jpg",
        square: "./generic/marketing-square.jpg",
      },
      body: "Learn how to connect every moment to engaging customer journeys. Inside this in-depth guide, you’ll get five sample customer journeys for growth, onboarding, engagement, retention, and support.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/marketing/interactive-customer-journey-map/",
    },
  ],
  roi: {
    title: "Grow Your Business and Create Better Customer Experiences",
    body: "Our products are designed to work together, unite departments, and help you get results faster. See how Salesforce customers report their average improvement using our products across key categories.",
    stats: [
      {
        title: "Increase in Marketing ROI",
        multi: 29,
        single: 85,
      },
      {
        title: "Increase in Customer Engagement",
        multi: 32,
        single: 64,
      },
      {
        title: "Decrease in Costs to Acquire New Customers",
        multi: 28,
        single: 62,
      },
      {
        title: "Increase in Customer Lifetime Value",
        multi: 27,
        single: 89,
      },
    ],
  },
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/marketing2.webp",
      headline:
        "Explore 5 interactive customer journeys that drive loyalty and ROI.",
      cta: "Start the tour",
      link: "https://www.salesforce.com/form/marketing/interactive-customer-journey-map/?d=pb",
    },
    {
      image: "./offers/marketing3.webp",
      headline: "Power every moment with Data + AI + CRM.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/demo/crm-marketing-demo/?d=pb",
    },
  ],
};
