import { useState, useRef, useEffect } from "react";
import { SWIPE, SESSIONS, BREAKPOINTS } from "./constants.js";
const { floor, random } = Math;

export const setBgImage = (image) => {
  const src = require(`../assets/images/${image}`);
  return { backgroundImage: `url(${src})` };
};

export const getPrefersReducedMotion = () => {
  const QUERY = "(prefers-reduced-motion: no-preference)";
  const mediaQueryList = window.matchMedia(QUERY);
  const prefersReducedMotion = !mediaQueryList.matches;
  return prefersReducedMotion;
};

export const randomizeAndLimit = (data, limit) => {
  data.sort(() => 0.5 - Math.random()); //randomize
  return data.slice(0, limit); //limit
};

export const sortByRank = (data) => {
  return data.sort((a, b) => (a.rank > b.rank ? 1 : -1)); //rank - lowest number is highest rank
};

export const rankAndLimit = (data, limit) => {
  return sortByRank(data.slice(0, limit)); //limit
};

// this function resets focus at the top of the page for each question
// the timeout prevents firefox from autofocusing the first question input
// and since it's required, prevents browsers from displaying an error message
export const useResetFocus = () => {
  useEffect(() => {
    setTimeout(() => {
      document.documentElement.focus();
    }, 0);
  }, []);
};

export const useSwipe = () => {
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  let startX = 0;
  let startY = 0;
  const SWIPE_THRESHOLD = 15; //min distance to constitute a swipe

  useEffect(() => {
    const handleTouchStart = (e) => {
      startX = e.changedTouches[0].screenX;
      startY = e.changedTouches[0].screenY;
    };

    const handleTouchEnd = (e) => {
      const diffX = e.changedTouches[0].screenX - startX;
      const diffY = e.changedTouches[0].screenY - startY;
      const ratioX = Math.abs(diffX / diffY);
      const ratioY = Math.abs(diffY / diffX);
      const absDiff = Math.abs(ratioX > ratioY ? diffX : diffY);

      // Ignore small movements.
      if (absDiff < SWIPE_THRESHOLD) {
        return;
      }

      if (ratioX > ratioY) {
        if (diffX >= 0) {
          setValue(SWIPE.RIGHT);
        } else {
          setValue(SWIPE.LEFT);
        }
      } else {
        if (diffY >= 0) {
          setValue(SWIPE.DOWN);
        } else {
          setValue(SWIPE.UP);
        }
      }
    };

    const node = ref.current;
    if (node) {
      node.addEventListener("touchstart", handleTouchStart);
      node.addEventListener("touchend", handleTouchEnd);

      return () => {
        node.removeEventListener("touchstart", handleTouchStart);
        node.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, []);

  return [ref, value];
};

export class Timer {
  constructor(callback, delay, timerId) {
    this.callback = callback;
    this.delay = delay;
    this.timerId = timerId;
    this.start = null;
    this.remaining = delay;
    this.resume();
  }

  pause() {
    window.clearTimeout(this.timerId.current);
    this.remaining -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();
    window.clearTimeout(this.timerId.current);
    this.timerId.current = window.setTimeout(this.callback, this.remaining);
  }
}

export function randomizeNumber(a, b) {
  return () => {
    return a + (b - a) * random();
  };
}

export function randomizeVector(x, y) {
  const randX = randomizeNumber(...x);
  const randY = randomizeNumber(...y);
  return { x: randX(), y: randY() };
}

export function randomizeArray(a) {
  const rand = randomizeNumber(0, a.length - 1);
  return () => {
    const i = floor(rand());
    return a[i];
  };
}

export function setChoice(val = "") {
  sessionStorage.setItem(SESSIONS.CHOICE_KEY, val);
}

export function saveInSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

export function checkChoice(val = "") {
  const storageItem = sessionStorage.getItem(SESSIONS.CHOICE_KEY);
  if (storageItem && storageItem === val) {
    return true;
  } else {
    return false;
  }
}

export function removeChoice() {
  const storageItem = sessionStorage.getItem(SESSIONS.CHOICE_KEY);
  storageItem && sessionStorage.removeItem(SESSIONS.CHOICE_KEY);
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const getComponentsHeights = (innerWidth) => {
  let heights;
  if (innerWidth >= BREAKPOINTS.XL) {
    heights = { sfHeader: 110, quizTracker: 120 };
  } else if (innerWidth >= BREAKPOINTS.LG) {
    heights = { sfHeader: 110, quizTracker: 84 };
  } else {
    heights = { sfHeader: 53, quizTracker: 84 };
  }
  return heights;
};
