import React from "react";
import { AnswerContext } from "../../context/answer-context";
import TextLockup from "../Global/TextLockup";
import { TEXT_TYPES, ANALYTICS } from "../../utils/constants";
import TagManager from "react-gtm-module";

const handleAnalyticsData = (title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventTracker",
      eventCat: ANALYTICS.CATEGORY.CONTACTSALES,
      eventAct: ANALYTICS.ACTION.NEXTSTEP,
      eventLbl: title,
    },
  });
};

function Contact({ ...props }) {
  const { strings } = props;
  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <div className="contact sf-col-xs-20 sf-col-md-10 sf-col-lg-12">
          <div className="contact__bg">
            <div className="contact__copy">
              <TextLockup
                {...strings}
                useGrid={false}
                type={TEXT_TYPES.LARGE}
                analytics={() =>
                  handleAnalyticsData(state.priorityChoice.title)
                }
                isContactPageHeading={true}
              />
            </div>
          </div>
        </div>
      )}
    </AnswerContext.Consumer>
  );
}

export default Contact;
