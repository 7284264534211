import React, { useEffect } from "react";

function GlobalNav() {
  useEffect(() => {
    loadGlobalNavScript();
    function loadGlobalNavScript() {
      const script = document.createElement("script");
      script.type = "module";
      script.src =
        "https://a.sfdcstatic.com/digital/xsf/components/v1-stable/navs-fonts.js";
      document.head.appendChild(script);
    }
  }, []);

  return (
    <header>
      <hgf-c360nav
        search="true"
        search-organization-id="org62salesforce"
        search-access-token="xx24e65ef6-c6f0-4a55-b6b9-e7fd84967788"
        search-placeholder="Search Trailhead"
        search-site="th"
        search-site-url="https://trailhead.salesforce.com/search/"
        origin="https://wp.salesforce.com/en-us/wp-json"
      ></hgf-c360nav>
    </header>
  );
}

export default GlobalNav;
