const industriesPath = require("./industriesPath");

module.exports = {
  title: "Communications",
  slug: "communications",
  astro: "./astros/astro_comms_media_transportation_hospitality.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Communications Leaders",
      headline: "Build Loyalty With Your Communications Customers",
      images: {
        landscape: "./generic/communications-landscape.jpg",
        square: "./generic/communications-square.jpg",
      },
      body: "Win customers for life with seamless experiences across all channels. Get a single, digital platform that enables agility and rapid innovation that drives results.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/solutions/industries/communications/overview/",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/communications2.png",
      headline:
        "Deliver the connected future faster with the #1 CRM for Communications.",
      cta: "Watch now",
      link: "https://www.salesforce.com/form/industries/communications/salesforce-for-communications-demo/",
    },
    {
      image: "./offers/communications3.png",
      headline:
        "Boost sales efficiency and grow revenue faster with Salesforce.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/communications/accelerate-communications-sales-solution/",
    },
  ],
};
