import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AnswerContext,
  STEP_CHOICE_MAP,
  CATEGORY_MAP,
  STATE_KEYS,
} from "../context/answer-context";
import { useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import {
  ANALYTICS,
  LOADING,
  PAGES,
  PILL,
  QUESTION_CLASSNAMES,
  INDUSTRY_ATTRIBUTES,
  URL_PARAMETERS,
} from "../utils/constants";
import Footer from "./Global/Footer";
import Industries from "./Industries/Industries";
import Roles from "./Roles/Roles";
import Tracker from "./Global/Tracker";
import Priorities from "./Priorities/Priorities";
import BusinessType from "./Type/BusinessType";
import TagManager from "react-gtm-module";
import smoothscroll from "smoothscroll-polyfill";
import { useNavigate } from "react-router-dom";
import { controlInactivyTimeout } from "./Events/Events";
import { GlobalContext } from "../context/answer-context";

const appData = require("../assets/data");
const customPaths = require("../assets/data/customPaths");

// Object to dictate linear rendering of components
const components = {
  1: Industries,
  2: Roles,
  3: BusinessType,
  4: Priorities,
};

// Dynamically generate component based on which step we're on
const Step = (props) => {
  const { stepNum } = props;
  const SpecificStep = components[stepNum];
  return <SpecificStep {...props} />;
};

function Quiz() {
  const { searchParams, searchParamsToString, hasModeEventsInParams } =
    useContext(GlobalContext);
  const { businessType, roleType, industryType } = appData;
  const trackerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const routerState = location.state?.data;

  useEffect(() => {
    // Preselect industry
    const indUrlParam = searchParams.get(URL_PARAMETERS.INDUSTRY.key);
    const industry = getIndustries.find((ind) => ind.slug === indUrlParam);
    if (industry) {
      setStateValue(STATE_KEYS.industryType, industry);
      setStateValue(STATE_KEYS.stepNum, 2);
    }
  }, []);

  // Smoothscroll support for Safari.
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  // Function to update app state via context
  const setStateValue = (stateKey, value) => {
    setState((prevState) => ({ ...prevState, [stateKey]: value }));
  };

  // Function to reset state back to initial
  const clearState = () => setState({ ...initialState });

  // Function to step forward through components
  const incrementStepValue = () => {
    let incrementedCount = state.stepNum + 1;
    if (incrementedCount === state.skippedQuestion) incrementedCount++;
    state.stepNum < state.maxSteps &&
      setState((prevState) => ({
        ...prevState,
        stepNum: incrementedCount,
        formID: `${STEP_CHOICE_MAP[incrementedCount]}Form`,
      }));
    const curStep = state.stepNum;
    const curChoiceVal = STEP_CHOICE_MAP[curStep];

    if (curStep > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: "eventTracker",
          eventCat: ANALYTICS.CATEGORY.QUIZ,
          eventAct: `${CATEGORY_MAP[curChoiceVal]} Response`,
          eventLbl: `${state[curChoiceVal] && state[curChoiceVal].title}`,
        },
      });
    }
  };

  // Function to step backward through components
  const decrementStepValue = () => {
    let curStep = state.stepNum;
    let targetStep = curStep - 1;
    if (state.stepNum - 1 === state.skippedQuestion) targetStep--;
    while (curStep > targetStep) {
      let prevStep = curStep - 1;
      const curChoiceVal = STEP_CHOICE_MAP[curStep];
      const prevChoiceVal = STEP_CHOICE_MAP[prevStep];
      curStep !== 0 &&
        setState((prevState) => ({
          ...prevState,
          stepNum: prevStep,
          [`${curChoiceVal}`]: null,
          [`${prevChoiceVal}`]: null,
          formID: null,
        })); //update step and clear any selections for current or previous step
      curStep--;
    }
    window.scrollTo(0, 0);
  };

  // Keep a copy of initial state, so that we can reset it if needed
  const initialState = {
    stepNum: routerState ? routerState.stepNum : 1,
    industryType: routerState ? routerState.industryType : null,
    roleType: routerState ? routerState.roleType : null,
    businessType: routerState ? routerState.businessType : null,
    priorityChoice: routerState
      ? {
          ...routerState.priorityChoice,
          title: routerState.priorityChoice.priority,
        }
      : null,
    maxSteps: Object.keys(components).length,
    skippedQuestion: routerState ? routerState.skippedQuestion : null,
    recommendedURL: null,
    formID: routerState ? routerState.formID : STEP_CHOICE_MAP[1],
    formURL: null,
    refParameter: null,
    setState: setStateValue,
    clearState: clearState,
    // isEventsMode: isEventsMode,
    selectAnotherGoal: routerState ? routerState.selectAnotherGoal : null,
  };

  // React.useEffect(() => {
  //   if (isEventsMode) {
  //     controlInactivyTimeout();
  //   }
  // }, [isEventsMode]);

  // App state, accessible via context
  const [state, setState] = useState(initialState);
  // console.log("STATE", state);

  // useEffect( () => {
  //   setState({
  //     ...state,
  //     ...routerState?.data
  //   })
  // },[routerState])

  // Update strings if industry has alternate flow
  const strings = { ...appData.strings };
  const industryAltPath = customPaths.find(
    (item) => item.slug === (state.industryType && state.industryType.slug)
  );
  if (industryAltPath) {
    Object.keys(industryAltPath.strings).forEach((key) => {
      strings[key] = industryAltPath.strings[key];
    });
  }

  // Get Industries data for first question
  const getIndustries = industryType.map((d) => ({
    slug: d.slug,
    title: d.title,
  }));

  // Get Priorities data for third question
  const getAllPriorities = () => {
    if (state.industryType && state.roleType && state.businessType) {
      const logicName = industryAltPath ? industryAltPath.logicPath.slug : "";
      return filterByLogic(
        appData.priorities,
        state.industryType.slug,
        state.roleType.slug,
        state.businessType.slug,
        logicName
      );
    }
  };

  const filterByLogic = (priorities, ind, role, bt, logicName) => {
    switch (logicName) {
      case "original-logic":
        return priorities.filter(
          (p) =>
            p.industryX === ind || p.roleX === role || p.businessTypeX === bt
        );
      case "AND-logic":
        return priorities.filter(
          (p) =>
            p.industryX === ind && p.roleX === role && p.businessTypeX === bt
        );
      // case "case3":
      //   return priorities.filter( (p) =>
      //   (p.industryX === ind ||
      //   p.roleX === role) &&
      //   p.businessTypeX === bt );
      // case "case4":
      //   return priorities.filter( (p) =>
      //   (p.industryX === ind &&
      //   p.roleX === role) ||
      //   p.businessTypeX === bt );
      default:
        return priorities.filter(
          (p) =>
            p.industryX === ind || p.roleX === role || p.businessTypeX === bt
        );
    }
  };

  // Get Roles data for second question
  const getRoles = roleType.map((r) => ({
    slug: r.slug,
    title: r.title,
    desc: r.description ? r.description : "",
  }));

  // Generate props for each component tied to Step Number
  const genericProps = { ...state, incrementStepValue, decrementStepValue };
  const generateProps = () => {
    switch (state.stepNum) {
      case 0:
        return {
          ...genericProps,
          strings: {
            ...strings["intro"],
            ...strings["tracker"],
          },
        };
      case 1:
        return {
          ...genericProps,
          strings: {
            ...strings["industries"],
            ...strings["tracker"],
          },
          answers: getIndustries,
          stateKey: STATE_KEYS.industryType,
          pillType: PILL.TYPES.LARGE,
          className: QUESTION_CLASSNAMES.industry,
        };
      case 2:
        return {
          ...genericProps,
          strings: {
            ...strings["roles"],
            ...strings["tracker"],
          },
          questionName: INDUSTRY_ATTRIBUTES.role,
          answers: getRoles,
          includeCustomAnswersByIndustry: true,
          stateKey: STATE_KEYS.roleType,
          pillType: PILL.TYPES.LARGE,
          className: QUESTION_CLASSNAMES.role,
        };
      case 3:
        return {
          ...genericProps,
          strings: {
            ...strings["businessType"],
            ...strings["tracker"],
          },
          questionName: INDUSTRY_ATTRIBUTES.bussinesType,
          answers: businessType,
          includeCustomAnswersByIndustry: true,
          stateKey: STATE_KEYS.businessType,
          pillType: PILL.TYPES.LONG,
          className: QUESTION_CLASSNAMES.bussinesType,
        };
      case 4:
        return {
          ...genericProps,
          strings: {
            ...strings["priorities"],
            ...strings["tracker"],
          },
          answers: getAllPriorities(),
          stateKey: STATE_KEYS.priorityChoice,
          pillType: PILL.TYPES.LONG,
          className: QUESTION_CLASSNAMES.priority,
          isPrioritiesQuestion: true,
        };
      default:
        return;
    }
  };

  useEffect(() => {
    if (state.priorityChoice) {
      const resultPageUrl = `/result/${state.roleType.slug}/${state.industryType.slug}/${state.priorityChoice.cat}/${state.businessType.slug}/${state.priorityChoice.slug}`;
      const externalSolution = appData.priorities.find(
        (item) => item.slug === state.priorityChoice.slug
      )?.externalRedirect;
      setStateValue(
        STATE_KEYS.recommendedURL,
        externalSolution || resultPageUrl
      );
    }
  }, [state.priorityChoice]);

  useEffect(() => {
    if (state.stepNum > 0) {
      window.scrollTo(0, 0);
    }
  }, [state.stepNum]);

  useEffect(() => {
    const { stepNum } = state;

    if (stepNum > 0) {
      navigate(`/quiz/${stepNum}/${searchParamsToString()}`, { replace: true });
    } else {
      //if quiz need to route to home url
      if (navigate.location.pathname.includes("quiz")) {
        navigate("/" + searchParamsToString(), { replace: true });
      }
    }
  }, [state.stepNum]);

  return (
    <AnswerContext.Provider value={state}>
      <div className={`app__container ${PAGES[state.stepNum]}__app_container`}>
        <main
          className={`${state.stepNum > 0 ? "quiz-container" : ""}`}
          id="mainContent"
          tabIndex="-1"
        >
          <LazyLoad height={LOADING.HEIGHT} offset={LOADING.OFFSET} once>
            <Step {...generateProps()} />
          </LazyLoad>
          <div
            className={`${state.stepNum > 0 ? "tracker-footer-container" : ""}`}
            ref={trackerRef}
            style={hasModeEventsInParams() ? { bottom: "0px" } : {}}
          >
            {state.stepNum > 0 && (
              <Tracker
                strings={strings["tracker"]}
                incrementStepValue={incrementStepValue}
                decrementStepValue={decrementStepValue}
              />
            )}
            {!hasModeEventsInParams() && <Footer display />}
          </div>
        </main>
      </div>
    </AnswerContext.Provider>
  );
}
export default Quiz;
