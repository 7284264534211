const industriesPath = require("./industriesPath");

module.exports = {
  title: "Professional Services",
  slug: "professional-services",
  astro: "./astros/astro_education_executive_misc.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "Professional Services Leaders",
      headline:
        "Learn More About Pwc’s People-First Approach to Digital Transformation",
      images: {
        landscape: "./generic/professional-services-landscape.jpg",
        square: "./generic/professional-services-square.jpg",
      },
      body: "See how PwC used Salesforce to embrace new ways of working and putting its people at the center of its transformation.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/success-cloud/pwc-digital-transformation-whitepaper/",
    },
  ],
};
