const industriesPath = require("./industriesPath");

module.exports = {
  title: "Energy",
  slug: "energy",
  astro: "./astros/astro_energy.png",
  roles: industriesPath.roles.general,
  businessType: industriesPath.businessType.general,
  generic: [
    {
      eyebrow: "For Utility Leaders",
      headline: "Improving CX in the Utilities Industry",
      images: {
        landscape: "./generic/energy-landscape.jpg",
        square: "./generic/energy-square.jpg",
      },
      body: "Learn how your data can help you be more agile to meet customers expectations.",
      cta: "Learn More",
      ctaLink:
        "https://www.salesforce.com/form/industries/energy/improve-your-utilities-cx-experience/?d=cta-header-1%3fd=cta-header-1",
    },
  ],
  offers: [
    {
      image: "./offers/data-ai-crm.png",
      headline: "Data + AI + CRM. That’s the genius of Customer 360.",
      cta: "View demo playlist",
      link: "https://www.salesforce.com/form/demo/product-demos/?d=pb1",
    },
    {
      image: "./offers/energy2.png",
      headline: "Charting a Path for Utility Digital Transformation.",
      cta: "Get the report",
      link: "https://www.salesforce.com/form/industries/energy/transform-your-industry-utility-digital-transformation/?d=cta-body-promo-2064",
    },
    {
      image: "./offers/energy3.png",
      headline:
        "Accelerate time to value and transform the utility customer experience.",
      cta: "Watch demo",
      link: "https://www.salesforce.com/form/industries/energy/energy-and-utilities-cloud-demo/?d=cta-header-2060",
    },
  ],
};
