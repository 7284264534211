import React from "react";
import Image from "../Global/Image";
import FadeComponent from "../Global/FadeComponent";

function Offers({ ...props }) {
  const { data, isPhone } = props;
  return (
    <FadeComponent isPhone={isPhone}>
      <div className="sf-row sf-row-md--center">
        <div className="sf-col-md-20 sf-row offers sf-row-xs--center">
          {data.map((offer, i) => (
            <a
              className="offers-tile sf-col-xs-20 sf-col-ms-9 sf-col-md-6 sf-row--center"
              key={`offertile-${i}`}
              target="_blank"
              href={offer.link}
            >
              <Image
                classes=""
                desktopSrc={offer.image}
                mobileSrc={offer.image}
                alt=""
                noLazy={true}
              />
              <div className="offers-box__container">
                <h3 className="offers-title">{offer.headline}</h3>
                <a
                  href={offer.link}
                  className="offers-link"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  {offer.cta}
                </a>
              </div>
            </a>
          ))}
        </div>
      </div>
    </FadeComponent>
  );
}

export default Offers;
