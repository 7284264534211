import React, { useContext } from "react";
import LazyLoad from "react-lazyload";
import { LOADING, PAGES } from "../utils/constants";
import Footer from "./Global/Footer";
import Intro from "./Intro/Intro";
import { GlobalContext } from "../context/answer-context";

function Landing() {
  const { hasModeEventsInParams } = useContext(GlobalContext);
  return (
    <div className={`app__container ${PAGES[0]}__app_container`}>
      <main id="mainContent" tabIndex="-1">
        <LazyLoad height={LOADING.HEIGHT} offset={LOADING.OFFSET} once>
          <Intro />
        </LazyLoad>
        {!hasModeEventsInParams() && <Footer display />}
      </main>
    </div>
  );
}
export default Landing;
