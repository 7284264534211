import React, { useEffect, useState, useRef, useContext } from "react";
import products from "../../assets/products";
import { useOnScreen } from "use-hooks";
import { CLASSES } from "../../utils/constants";
import Image from "../Global/Image";
import { usePrevious } from "./../../utils/functions";
import { GlobalContext } from "../../context/answer-context";

function Products({ ...props }) {
  const { grid } = props;
  const CELLPHONE_SPIN_DEGREES = 180;
  const PRODUCT_INTERVAL_TIMING = 5000;
  const ref = useRef();
  const cellphoneRef = useRef();

  const [isDocumentVisible, setIsDocumentVisible] = useState(true);
  const isCarouselOnScreen = useOnScreen(ref); //when on screen, animate in

  // Products math
  const productList = grid.map((item) => {
    const productData = products.find((prod) => prod.name === item);
    if (productData) return productData;
  });

  const [currentProduct, setCurrentProduct] = useState(0);
  const prevCurrentProduct = usePrevious(currentProduct);
  const [automaticCarrusel, setAutomaticCarrusel] = useState(true);
  const [carouselInterval, setCarouselInterval] = useState(null);
  const [isFrontScreenActive, setIsFrontScreenActive] = useState(false);
  const [phoneRotation, setPhoneRotation] = useState(0);
  const prevPhoneRotation = usePrevious(phoneRotation);
  const [phoneTranslateZ, setPhoneTranslateZ] = useState(0);

  useEffect(() => {
    document.addEventListener("visibilitychange", setDocumentVisibilityState);
    return () => {
      document.removeEventListener(
        "visibilitychange",
        setDocumentVisibilityState
      );
    };
  }, []);

  const setDocumentVisibilityState = () => {
    if (document.hidden) {
      setIsDocumentVisible(false);
    } else {
      setIsDocumentVisible(true);
    }
  };

  useEffect(() => {
    if (isCarouselOnScreen && isDocumentVisible) {
      let index = currentProduct;
      if (index === productList.length - 1) index = 0;
      startCarousel(index);
    } else {
      stopCarousel();
    }
  }, [isCarouselOnScreen, isDocumentVisible]);

  const startCarousel = (index = 0, automatic = true) => {
    // Set carousel mode to automatic
    setCurrentProduct(index);
    setAutomaticCarrusel(automatic);
    const intervalId = setInterval(() => {
      index = index + 1;
      if (index === productList.length) {
        index = 0;
      }
      setCurrentProduct(index);
    }, PRODUCT_INTERVAL_TIMING);
    setCarouselInterval(intervalId);
  };

  const stopCarousel = () => clearInterval(carouselInterval);

  useEffect(() => {
    if (!automaticCarrusel) {
      stopCarousel();
    }
  }, [automaticCarrusel]);

  useEffect(() => {
    toggleScreen();
    spinCellphone(currentProduct);
  }, [currentProduct]);

  // Toggle between front and back cellphone screen
  const toggleScreen = () => setIsFrontScreenActive(!isFrontScreenActive);

  const spinCellphone = (currentProduct) => {
    // Add or substract spin degrees depending if going forwards or backwards whitin products carrusel.
    let newRotation = prevPhoneRotation || 0;
    if (currentProduct > prevCurrentProduct) {
      newRotation += CELLPHONE_SPIN_DEGREES;
    } else if (currentProduct < prevCurrentProduct) {
      newRotation -= CELLPHONE_SPIN_DEGREES;
    }
    setPhoneRotation(newRotation);
  };

  useEffect(() => {
    if (isFrontScreenActive) {
      setPhoneTranslateZ(0);
    } else {
      return window.innerWidth >= 1024
        ? setPhoneTranslateZ(20)
        : setPhoneTranslateZ(10);
    }
  }, [isFrontScreenActive]);

  const selectProduct = (index) => {
    setAutomaticCarrusel(false);
    setCurrentProduct(index);
  };

  const nextProduct = () => {
    let index = currentProduct + 1;
    if (index === productList.length) index = 0;
    stopCarousel();
    startCarousel(index);
  };

  const prevProduct = () => {
    let index = currentProduct - 1;
    if (index < 0) index = productList.length - 1;
    stopCarousel();
    startCarousel(index);
  };

  const isProductActiveInCarousel = (product) =>
    product.slug === productList[currentProduct].slug;

  return (
    <>
      <div className="products sf-row sf-row-xs--center" ref={ref}>
        <div className="products__presentation container">
          <div
            className="products__presentation background"
            style={{ backgroundColor: productList[currentProduct].color }}
          />
          <div className="products__presentation scene">
            {/* <div style={{transform: `translateZ(0px)`}}> */}
            <div
              className={`products__presentation cellphone`}
              style={{
                transform: `rotateY(${phoneRotation}deg) translateZ(${phoneTranslateZ}px)`,
              }}
              ref={cellphoneRef}
            >
              <div className="products__presentation cellphone__face cellphone__front">
                <div className="products__presentation cellphone__screen__container">
                  {productList.map((product) => (
                    <Image
                      key={`front-screen-${product.slug}`}
                      desktopSrc={product.cellphoneScreen}
                      mobileSrc={product.cellphoneScreen}
                      classes={`element cellphone__screen ${
                        isProductActiveInCarousel(product)
                          ? CLASSES.ACTIVE
                          : CLASSES.INACTIVE
                      }`}
                      alt=""
                      noLazy={true}
                    />
                  ))}
                </div>
              </div>
              <div className="products__presentation cellphone__face cellphone__back">
                <div className="products__presentation cellphone__screen__container">
                  {productList.map((product, index) => (
                    <Image
                      key={index}
                      desktopSrc={product.cellphoneScreen}
                      mobileSrc={product.cellphoneScreen}
                      classes={`element cellphone__screen ${
                        isProductActiveInCarousel(product)
                          ? CLASSES.ACTIVE
                          : CLASSES.INACTIVE
                      }`}
                      alt=""
                      noLazy={true}
                    />
                  ))}
                </div>
              </div>
              <div className="products__presentation cellphone__face cellphone__right"></div>
              <div className="products__presentation cellphone__face cellphone__left"></div>
              <div className="products__presentation cellphone__face cellphone__top"></div>
              <div className="products__presentation cellphone__face cellphone__bottom"></div>
              <div className="products-face cellphone__corner top__left"></div>
              <div className="products-face cellphone__corner top__right"></div>
              <div className="products-face cellphone__corner bottom__left"></div>
              <div className="products-face cellphone__corner bottom__right"></div>
            </div>
            {/* </div> */}
            <div className="products_presentation popups">
              <div className="products__presentation elements">
                {productList.map((product) =>
                  product.popupElements.map((item, index) => (
                    <Image
                      key={`${item.side}-popup-${product.slug}-${index}`}
                      desktopSrc={item.src}
                      mobileSrc={item.src}
                      classes={`products__presentation popup_element box -${
                        item.side
                      } ${
                        isProductActiveInCarousel(product)
                          ? CLASSES.ACTIVE
                          : CLASSES.INACTIVE
                      }`}
                      alt=""
                      noLazy={true}
                    />
                  ))
                )}
                <Image
                  desktopSrc="./products/gifs/bee.gif"
                  mobileSrc="./products/gifs/bee.gif"
                  classes={`products__presentation gif box-right`}
                  alt=""
                  noLazy={true}
                />
              </div>
            </div>
          </div>
          <div className="products__foliage">
            <Image
              desktopSrc="./grass1.png"
              mobileSrc="./grass1.png"
              classes={`products__presentation popup_element grass1`}
              alt=""
              noLazy={true}
            />
            <Image
              desktopSrc="./grass2.png"
              mobileSrc="./grass2.png"
              classes={`products__presentation popup_element grass2`}
              alt=""
              noLazy={true}
            />
            <Image
              desktopSrc="./grass3.png"
              mobileSrc="./grass3.png"
              classes={`products__presentation popup_element grass3`}
              alt=""
              noLazy={true}
            />
          </div>
          <div className="products__presentation box-left">
            <div className="products__presentation box-left--card">
              <div className="products__icon-wrapper">
                {productList.map((product, index) => (
                  <div
                    key={index}
                    className={`products__icon-wrapper--fadein__container ${
                      isProductActiveInCarousel(product)
                        ? CLASSES.ACTIVE
                        : CLASSES.INACTIVE
                    }`}
                  >
                    <img
                      src={product.icon}
                      alt=""
                      className={`products__icon products__icon--${product.slug}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            {productList.map((product, index) => (
              <div
                key={index}
                className={`products__presentation box-left--fadein__container ${
                  isProductActiveInCarousel(product)
                    ? CLASSES.ACTIVE
                    : CLASSES.INACTIVE
                }`}
                tabIndex={isProductActiveInCarousel(product) ? "0" : "-1"}
                aria-hidden={
                  isProductActiveInCarousel(product) ? "false" : "true"
                }
              >
                <h3 className="products__presentation title">{product.name}</h3>
                <p className="products__description">
                  {product.slug === productList[0].slug
                    ? product.description
                    : product.followingDescription}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="products sf-row sf-row-xs--center">
        <div className="products__carrusel legend">
          <button
            className="products__carrusel chevron__arrow chevron__arrow--left"
            onClick={prevProduct}
            aria-label="Previous Product"
          >
            {`<`}
          </button>
          {productList.map((item, index) => (
            <button
              key={index}
              className={`products__carrusel dot ${
                isProductActiveInCarousel(item) ? "active" : ""
              }`}
              // style={{ backgroundColor: item.color }}
              onClick={() => selectProduct(index)}
            >
              <span className="products__carrusel dot--item">
                Product {index + 1}
              </span>
              {isProductActiveInCarousel(item) ? (
                <span className="products__carrusel dot--current">
                  (Current Product)
                </span>
              ) : null}
            </button>
          ))}
          <button
            className="products__carrusel chevron__arrow chevron__arrow--right"
            onClick={nextProduct}
            aria-label="Next Product"
          >
            {`>`}
          </button>
        </div>
      </div>
    </>
  );
}

export default Products;
