import React from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./Global/PageNotFound";
import ValidateResults from "./Recommendations/ValidateResults";
import Quiz from "./Quiz";
import Landing from "./Landing";
import GlobalNav from "./Global/GlobalNav";
import Redirect from "./Email/Redirect";
import EventsControl from "../components/Events/EventsControl";
import useEvents from "./Events/useEvents";
import { useSearchParams, useLocation } from "react-router-dom";
import { GlobalContext } from "../context/answer-context";
import { URL_PARAMETERS } from "../utils/constants";
import TagManager from "react-gtm-module";
import { controlInactivyTimeout } from "./Events/Events";
const appData = require("../assets/data");

function App() {
  const isEventsActive = useEvents();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const state = {
    isEventsActive: isEventsActive,
    searchParams: searchParams,
    searchParamsToString: () => {
      return searchParams.toString() ? "?" + searchParams.toString() : "";
    },
    hasModeEventsInParams: () => {
      return searchParams.get(URL_PARAMETERS.MODE.key) === "events";
    },
  };

  React.useEffect(() => {
    const tagOptions = { gtmId: appData.strings.analytics };
    TagManager.initialize(tagOptions);
  }, []);

  // For iframe integration with wondersauce gpj
  React.useEffect(() => {
    if (state.hasModeEventsInParams()) controlInactivyTimeout();
  }, [state.hasModeEventsInParams()]);

  return (
    isEventsActive !== null && (
      <GlobalContext.Provider value={state}>
        <div className="app">
          {!state.hasModeEventsInParams() &&
            !location.pathname.startsWith("/events") && <GlobalNav />}
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/quiz" element={<Quiz />} />
            <Route exact path="/quiz/:idx" element={<Quiz />} />
            <Route
              exact
              path={`/result/:rol/:ind/:cat/:biz/:prio`}
              element={<ValidateResults />}
            />
            <Route
              exact
              path={`/result/:rol/:ind/:cat/:biz/:prio/:ref`}
              element={<Redirect />}
            />
            {state.isEventsActive && (
              <Route exact path={`/events`} element={<EventsControl />} />
            )}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </GlobalContext.Provider>
    )
  );
}
export default App;
