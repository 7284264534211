module.exports = {
  originalLogic: {
    slug: "original-logic",
    description: "industry || role || businessType",
  },
  ANDLogic: {
    slug: "AND-logic",
    description: "industry && role && businessType",
  },
};
