import React, { useContext } from "react";
import Image from "../Global/Image";
import TextLockup from "../Global/TextLockup";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import { TEXT_TYPES } from "../../utils/constants";
import FadeComponent from "../Global/FadeComponent";
import { STEP_CHOICE_MAP } from "../../context/answer-context";
import { GlobalContext } from "../../context/answer-context";
const customPaths = require("../../assets/data/customPaths");
function AltPriorities({ ...props }) {
  const { strings, isPhone, data } = props;
  const { industryParam, roleParam, businessParam, solution } = data;
  const { searchParamsToString } = useContext(GlobalContext);

  const getSkipped = () => {
    const industryAltFlow = customPaths.find(
      (item) => item.slug === industryParam.slug
    );
    return industryAltFlow && industryAltFlow.skippedQuestion;
  };

  return (
    <div className="alt-priorities__wrap">
      <Image
        desktopSrc="./recs-alt-bg.png"
        mobileSrc="./recs-alt-bg.png"
        classes="alt-priorities__bg"
      />
      <Image
        classes="alt-priorities__clouds"
        desktopSrc="./parallax-clouds.svg"
        mobileSrc="./parallax-clouds.svg"
        alt=""
      />
      <FadeComponent isPhone={isPhone}>
        <TextLockup
          {...strings.lockup}
          type={TEXT_TYPES.LARGE}
          centerMobile={true}
          classes="alt-priorities__lockup"
        />
        <div className="alt-priorities__row sf-row sf-row-xs--middle sf-row-xs--center">
          <div className="sf-col-xs-22 sf-col-md-22 sf-col-lg-22">
            <ul className="alt-priorities__list sf-row sf-row-xs--center sf-row-md--between sf-row-lg--between">
              <li className="alt-priorities__item sf-col-xs-20 sf-col-md-10 sf-col-lg-10 sf-row sf-row-xs--between sf-row-xs--middle">
                <div className="alt-priorities__cards sf-row sf-row-xs--between">
                  <div className="alt-priorities__content">
                    <h3 className="alt-priorities__title">
                      {strings.selectGoal}
                    </h3>
                    <p className="alt-priorities__desc">
                      {strings.businessNeeds}
                    </p>
                  </div>
                  <Link
                    className="alt-priorities__arrow-btn"
                    to={"/quiz/4" + searchParamsToString()}
                    state={{
                      data: {
                        stepNum: 4,
                        industryType: industryParam,
                        roleType: roleParam,
                        businessType: businessParam,
                        priorityChoice: solution,
                        selectAnotherGoal: true,
                        skippedQuestion: getSkipped(),
                        formID: STEP_CHOICE_MAP[4],
                      },
                    }}
                    aria-label={strings.selectGoal}
                  >
                    <span className="alt-priorities__arrow-span"></span>
                    <Arrow />
                  </Link>
                </div>
              </li>
              <li className="alt-priorities__item sf-col-xs-20 sf-col-md-10 sf-col-lg-10 sf-row sf-row-xs--between sf-row-xs--middle">
                <div className="alt-priorities__cards sf-row sf-row-xs--between">
                  <div className="alt-priorities__content">
                    <h3 className="alt-priorities__title">
                      {strings.startOver}
                    </h3>
                    <p className="alt-priorities__desc">
                      {strings.moreSolutions}
                    </p>
                  </div>
                  <Link
                    className="alt-priorities__arrow-btn"
                    to={"/quiz/1" + searchParamsToString()}
                    aria-label={strings.startOver}
                  >
                    <span className="alt-priorities__arrow-span"></span>
                    <Arrow />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="alt-priorities__rounded-bottom">
          <Image
            desktopSrc="./rounded-bottom-desktop.svg"
            mobileSrc="./rounded-bottom-mobile.svg"
            classes="alt-priorities__rounded-bottom-img"
            noLazy
          />
        </div>
      </FadeComponent>
    </div>
  );
}

export default AltPriorities;
