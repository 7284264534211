import React from "react";
import {
  AnswerContext,
  CATEGORY_MAP,
  STATE_KEYS,
  NON_OPTIONS_TYPE,
} from "../../context/answer-context";
import PillButton from "./PillButton";
import { useResetFocus, setChoice } from "../../utils/functions.js";
import { KEY_CODES } from "../../utils/constants";
const appData = require("../../assets/data");
const customPaths = require("../../assets/data/customPaths");

function PillGroup({ ...props }) {
  const {
    data,
    stateKey,
    pillType,
    legend,
    flexType = "center", //from grid flex options - start, center, end, around, between
  } = props;

  useResetFocus();
  const onChange = (e, val, state) => {
    state.setState(stateKey, {
      slug: val.slug,
      title: val.title,
      titlePlural: val.titlePlural || "",
    });

    // Get current industry from questionarie
    const industrySlug =
      stateKey === STATE_KEYS.industryType ? val.slug : state.industryType.slug;
    const industryAltFlow = customPaths.find(
      (item) => item.slug === industrySlug
    );

    if (
      industryAltFlow &&
      industryAltFlow.skippedQuestion &&
      industryAltFlow.skippedQuestion === ++state.stepNum
    ) {
      state.setState(
        industryAltFlow.skippedKey,
        NON_OPTIONS_TYPE[industryAltFlow.skippedKey]
      );
    }
    if (industryAltFlow && industryAltFlow.skippedQuestion) {
      state.setState(
        STATE_KEYS.skippedQuestion,
        industryAltFlow.skippedQuestion
      );
    } else {
      state.setState(STATE_KEYS.skippedQuestion, null);
    }

    // Check if you have gone through all questions to show interstitial
    if (state.roleType && state.industryType && state.businessType) {
      state.setState(stateKey, {
        slug: val.slug,
        title: val.title || val.priority,
        titlePlural: val.titlePlural || "",
        cat: CATEGORY_MAP[val.category],
      });
      state.setState(STATE_KEYS.selectAnotherGoal, null);
      //store our final choices in session storage so we know that the user has gone through the questionnaire
      const choiceString = `${val.slug}`;
      setChoice(choiceString);
    }
  };

  const onKeyPress = (e) => {
    if (e.charCode === KEY_CODES.ENTER) {
      e.preventDefault();
    }
  };
  const PillType = PillButton[pillType];

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => (
        <form id={state.formID} action={state.recommendedURL}>
          <fieldset className="pill__group-fieldset">
            <legend className="pill__group-legend">{`${appData.strings.choose}${legend}:`}</legend>
            <div
              className={`sf-row sf-row-xs--${flexType} pill__group`}
              role="radiogroup"
            >
              {data.map((val, i) => (
                <PillType
                  name={stateKey}
                  type={pillType}
                  key={val.title || val.priority}
                  title={val.title || val.priority}
                  selected={
                    state.priorityChoice &&
                    state.priorityChoice.title === val.priority
                      ? true
                      : false
                  }
                  desc={val.desc}
                  pillNum={i}
                  onChange={(e) => onChange(e, val, state)}
                  onKeyPress={(e) => onKeyPress(e)}
                ></PillType>
              ))}
            </div>
          </fieldset>
        </form>
      )}
    </AnswerContext.Consumer>
  );
}

export default PillGroup;
