import { randomizeNumber, randomizeVector } from "./functions";
import Particle from "./Particle";
import Circle from "../assets/icons/confetti/circle.svg";
import Circle1 from "../assets/icons/confetti/circle-1.svg";
import Pentagon from "../assets/icons/confetti/pentagon.svg";
import Pentagon1 from "../assets/icons/confetti/pentagon-1.svg";
import Square from "../assets/icons/confetti/square.svg";
import Square1 from "../assets/icons/confetti/square-1.svg";
import Star from "../assets/icons/confetti/star.svg";
import Star1 from "../assets/icons/confetti/star-1.svg";
import Triangle from "../assets/icons/confetti/triangle.svg";
import Triangle1 from "../assets/icons/confetti/triangle-1.svg";
const { floor } = Math;

class ParticleSystem {
  constructor(position, isMobile) {
    this.position = position;
    this.isMobile = isMobile;
    this.keyCodes = [];
    this.particles = [];
    this.particleData = [];
    this.body = document.body;
    this.numParticles = 100;
    this.canvas = null;
    this.canvasWidth = window.innerWidth;
    this.canvasHeight = window.innerHeight;
    this.canvasID = `canvas${position}`;
    this.onFrame = this.onFrame.bind(this);
    this.lastTimestamp = null;
    this.allImgs = [
      Circle,
      Circle1,
      Square,
      Square1,
      Triangle,
      Triangle1,
      Pentagon,
      Pentagon1,
      Star,
      Star1,
    ];
  }

  /**
   * @desc Method to start the particle system
   */
  init() {
    this.lastTimestamp = performance.now();
    this.createCanvas()
      .then(this.emit())
      .then(this.onFrame(this.lastTimestamp));
  }

  /**
   * @desc Create a canvas 100% of screen width and height
   * Only do this if no canvas already exists
   */
  createCanvas() {
    return new Promise((resolve) => {
      this.canvas = document.createElement("canvas");
      this.canvas.id = this.canvasID;
      this.canvas.width = this.canvasWidth;
      this.canvas.height = this.canvasHeight;

      Object.assign(this.canvas.style, {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 10000,
      });

      const canvasCheck = document.getElementById(this.canvasID);
      canvasCheck === null && this.body.appendChild(this.canvas);
      resolve();
    });
  }

  /**
   * @desc Update animation every frame
   * @param {number} timestamp Return from performance.now() to calculate our timer offset
   */
  onFrame(timestamp) {
    return new Promise(() => {
      if (this.canvas) {
        this.emit();
        const delta = (timestamp - this.lastTimestamp) / 1000;
        const ctx = this.canvas.getContext("2d");
        ctx.save();
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // Update every particle in our array
        for (const k in this.particles) {
          const p = this.particles[k];
          p.tick(delta);

          if (p.isDead) {
            delete this.particles[k];
            continue;
          }

          const angle = this.particleData[k].angle;
          // Translate to center of particle, rotate, update position, then restore transform
          ctx.translate(p.position.x, p.position.y);
          ctx.rotate(angle / 35);
          ctx.transform(1, angle / 900, angle / 900, 1, 0, 0);
          ctx.beginPath();
          ctx.globalAlpha = 0.7;
          ctx.drawImage(
            this.particleData[k].src,
            0,
            0,
            this.particleData[k].size,
            this.particleData[k].size
          );
          ctx.setTransform(1, 0, 0, 1, 0, 0);
          k % 2 === 0
            ? (this.particleData[k].angle += 1)
            : (this.particleData[k].angle -= 1);
        }
        ctx.restore();

        this.lastTimestamp = timestamp;
        requestAnimationFrame(this.onFrame);
        // Delete the canvas when all particles have been deleted from array
        /* eslint-disable-next-line */
        if (this.particles.every(() => {})) {
          const canvasCheck = document.getElementById(this.canvasID);
          canvasCheck !== null && this.canvas.remove();
          this.particles = [];
          this.particleData = [];
          this.canvas = null;
        }
        return Promise.resolve();
      }
    });
  }

  /**
   * @desc Set initial position of particles
   */
  initialPosition() {
    return {
      x: this.position === "left" ? 0 : this.canvasWidth,
      y: 0,
    };
  }

  /**
   * @desc Set initial accelerationg of particles
   */
  initialAcceleration() {
    return { x: 0, y: 0 };
  }

  /**
   * @desc Set initial velocity of particles
   */
  initialVelocity() {
    const randomX = this.position === "left" ? [0, 250] : [-250, 0];
    return randomizeVector(randomX, [-700, -1]);
  }

  /**
   * @desc Create new Particle instances
   * Save image, angle, and size data per particle
   */
  emit() {
    return new Promise(() => {
      while (this.particles.length < this.numParticles) {
        for (let i = 0; i < this.numParticles; i++) {
          // Random size, image source and angle
          const randImg = randomizeNumber(0, this.allImgs.length);
          const index = floor(randImg());
          const baseImg = new Image();
          baseImg.src = this.allImgs[index];
          const randSize = this.isMobile
            ? randomizeNumber(5, 15)
            : randomizeNumber(15, 20);
          const size = floor(randSize());

          this.particles[i] = new Particle(
            this.initialPosition(),
            this.initialVelocity(),
            this.initialAcceleration(),
            this.canvasWidth,
            this.canvasHeight,
            (this.itemSize = size)
          );

          this.particleData[i] = {
            src: baseImg,
            size: size,
            angle: size,
          };
        }
      }
      return Promise.resolve();
    });
  }
}

export default ParticleSystem;
