import React from "react";

function BenefitCard({ ...props }) {
  const { title, desc, num, len } = props;
  const isLast = num === len - 1;
  return (
    <div
      className={`card card--${num} card--total-${len} sf-col-md-20 ${
        isLast ? "card--last" : ""
      }`}
    >
      <h3 className="card__title">{title}</h3>
      <p className="card__desc">{desc}</p>
    </div>
  );
}

export default BenefitCard;
