import React from "react";
import { EVENTS as constants } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyStatement from "./PrivacyStatement";

const TOAST_SUCCESS =
  "Thanks. If you do not receive an email, please check your spam filters.";

const TOAST_ERROR =
  "Oops, something went wrong. Please submit your request again.";

const EventsForm = (props) => {
  const {
    MODAL_INSTRUCTIONS,
    MODAL_BUTTON_SUBMIT,
    API_ENDPOINT_SEND_DATA,
    DOMAIN_RESULTS_EMAIL,
  } = constants;
  const formModal = React.useRef();
  const { showModal, url, onClose } = props;
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [showAgreementTerms, setShowAgreementTerms] = React.useState(false);
  const [isFormLoading, setIsFormLoading] = React.useState(false);

  React.useEffect(() => {
    if (!showModal) {
      cleanFormInputs();
      hideAgreementTerms();
    }
  }, [showModal]);

  const cleanFormInputs = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  const hideAgreementTerms = () => setShowAgreementTerms(false);

  const closeModalWhenClickOutside = () => {
    window.addEventListener("click", (event) => {
      if (event.target == formModal.current) {
        closeModal();
      }
    });
    return () => {
      formModal.removeEventListener("click", closeModal);
    };
  };

  React.useEffect(closeModalWhenClickOutside, []);

  const closeModal = () => onClose();

  const sendCustomerInfo = async (e) => {
    e.preventDefault();
    try {
      setIsFormLoading(true);
      let res = await fetchWithTimeout(API_ENDPOINT_SEND_DATA, {
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          name: `${firstName} ${lastName}`,
          email: email,
          link: `${DOMAIN_RESULTS_EMAIL}${url}`,
        }),
      });
      let resJson = await res.json();
      // console.log("resp",resJson.status,resJson)
      if (res.status === 200) {
        toast(TOAST_SUCCESS, {
          className: "events-toast__success-background",
          progressClassName: "events-toast__success-progress__bar",
        });
        closeModal();
      }
    } catch (err) {
      toast(TOAST_ERROR, {
        className: "events-toast__warn-background",
        progressClassName: "events-toast__warn-progress__bar",
      });
      console.log(err);
    } finally {
      setIsFormLoading(false);
    }
  };

  async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 10000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  }

  const toggleAgreementTerms = () => setShowAgreementTerms(!showAgreementTerms);

  return (
    <>
      <div
        ref={formModal}
        className={`events__modal${showModal ? " active" : ""}`}
      >
        <div className="modal__content">
          <div className="row__container header">
            <button className="button__close" onClick={closeModal}>
              X
            </button>
          </div>
          <form className="form__email" onSubmit={sendCustomerInfo}>
            <p className="instructions__text">{MODAL_INSTRUCTIONS}</p>
            <div className="row__container">
              <input
                type="text"
                required
                value={firstName}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                className="input__form"
              />
              <input
                type="text"
                required
                value={lastName}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                className="input__form last__name"
              />
            </div>
            <div className="row__container">
              <input
                type="email"
                required
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") e.preventDefault();
                }}
                className="input__form"
              />
            </div>
            <p className="agreement__text">
              Salesforce values your privacy. To learn more, check our&nbsp;
              <span className="hyperlink" onClick={toggleAgreementTerms}>
                Privacy Statement
              </span>
            </p>
            {showAgreementTerms ? <PrivacyStatement /> : null}
            <div className="row__container">
              <button
                className="intro__button-cta button__form"
                type="submit"
                disabled={isFormLoading ? true : false}
              >
                {isFormLoading ? (
                  <div className="events__loader" />
                ) : (
                  MODAL_BUTTON_SUBMIT
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default EventsForm;
