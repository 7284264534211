import React from "react";
import { AnswerContext } from "../../context/answer-context";
import { CLASSES } from "../../utils/constants";
import Logo from "../../assets/icons/sf-logo.svg";
import { ReactComponent as PrivacyOptions } from "../../assets/icons/privacyoptions.svg";
const appStrings = require("../../assets/data/strings");

function Footer({ ...props }) {
  const { clearState, display } = props;
  const { footer: strings } = appStrings;

  return (
    <AnswerContext.Consumer>
      {({ ...state }) => {
        return (
          <footer
            className={`footer ${
              !state.ctaVisible && !display ? CLASSES.INACTIVE : ""
            }`}
            id="footerContent"
            tabIndex="-1"
          >
            <div className="sf-row sf-row-xs--center sf-row-md--between sf-row-lg--between footer__inner">
              <div className="sf-col-xs-23 sf-col-md-15 sf-col-lg-13 footer__copy">
                <div className="sf-row sf-row-md--between sf-row-lg--start">
                  <div className="sf-col-xs-20 sf-col-md-7 sf-col-lg-5 sf-col-lg-offset-right-1">
                    {strings.copyright}
                    <a
                      href={strings.rightsReserved.url}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noopener"
                      className="footer__link"
                      style={{
                        borderBottom: "1px solid #0176d3",
                      }}
                    >
                      {strings.rightsReserved.text}
                    </a>
                    {strings.trademarks}
                  </div>
                  <div className="sf-col-xs-20 sf-col-md-7 sf-col-lg-6 footer__address">
                    {strings.address}
                    <div>{strings.phone}</div>
                  </div>
                </div>
              </div>
              <div className="sf-col-xs-23 sf-col-md-4 sf-col-lg-2 footer__logo">
                <a
                  href="https://www.salesforce.com"
                  onClick={() => clearState()}
                >
                  <img
                    src={Logo}
                    alt={strings.logoAlt}
                    className="footer__logo-img"
                  />
                </a>
              </div>
              <div className="sf-col-xs-23 sf-col-md-22 sf-col-lg-20 footer__links-container">
                <ul className="footer__links">
                  {strings.links.map((link) => {
                    const { text, url, id, classes } = link;
                    return (
                      <li className="footer__link-item" key={text}>
                        {id === "ot-sdk-btn" ? (
                          <button
                            id={id}
                            className={`footer__link${
                              classes ? ` ${classes}` : ""
                            } cookie`}
                            tabIndex="0"
                          >
                            {link.text}
                          </button>
                        ) : (
                          <a
                            id={id}
                            role="button"
                            className={`footer__link`}
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            tabIndex="0"
                          >
                            {link.id === "privacy-choices" ? (
                              <PrivacyOptions className="privacy_choices" />
                            ) : null}
                            {link.text}
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </footer>
        );
      }}
    </AnswerContext.Consumer>
  );
}

export default Footer;
