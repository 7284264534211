import React, { useState } from "react";
import { ANALYTICS, TIMING } from "../../utils/constants";
import Image from "../Global/Image";
import TagManager from "react-gtm-module";

function Share({ ...props }) {
  const { strings, title, isPhone } = props;
  const [localState, setLocalState] = useState(false);
  const onCopyUrl = (title) => {
    navigator.clipboard.writeText(document.location.href).then(
      function () {
        setLocalState({ success: true });

        TagManager.dataLayer({
          dataLayer: {
            event: "eventTracker",
            eventCat: ANALYTICS.CATEGORY.SHARESOLUTION,
            eventAct: ANALYTICS.ACTION.SHARESOLUTION,
            eventLbl: title,
          },
        });

        setTimeout(() => {
          setLocalState({ success: false });
        }, TIMING.TIMING_5000);
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <div className="share sf-col-xs-20 sf-col-md-10 sf-col-lg-8">
      <div className="share__text-content">
        <h3 className="share__heading">{title}</h3>
        <p className="share__body">{strings.body}</p>
      </div>
      <Image
        desktopSrc="./wheel_person_share.png"
        mobileSrc="./wheel_person_share.png"
        classes="share__img"
      />
      <div className="share__copy-input" role="form">
        <button
          className={`share__button ${
            localState.success ? "share__button--success" : ""
          }`}
          onClick={() => {
            onCopyUrl(title);
          }}
          aria-label={`${strings.copy} - ${strings.copyLabel}`}
        >
          {strings.copy}
          {!isPhone && (
            <span className="share__button share__button--hover">
              {strings.copyLink}
            </span>
          )}
        </button>
        {localState.success && (
          <div role="alert" className="share__success">
            {strings.copySuccess}
          </div>
        )}
      </div>
    </div>
  );
}

export default Share;
